import { isEmpty } from 'lodash';
import { SavedFilter } from 'src/components/Filter/Filter';
import { FilterCategory } from 'src/components/Preferences/FilterModal/FilterModal';

const preferencesTag = 'Feline-PREFERENCES';
const savedFiltersTag = 'Feline-SAVED_FILTERS';
const recentUrlParamsTag = 'Feline-RECENT_URL_PARAMS';
const savedOriginalUserLocationTag = 'Feline-ORIGINAL_USER_LOCATION';
const majorVersion = 'V0';
const minorVersion = '.07';
export const preferencesKey: string =
  preferencesTag + '-' + majorVersion + minorVersion;

export const savedFiltersKey: string =
  savedFiltersTag + '-' + majorVersion + minorVersion;

export const recentUrlParamsKey: string =
  recentUrlParamsTag + '-' + majorVersion + minorVersion;

export const savedOriginalUserLocationKey: string =
  savedOriginalUserLocationTag + '-' + majorVersion + minorVersion;

let currentPreferences: any;
let currentFilters: SavedFilter[] = [];

export type Persona = 'owner' | 'marketplace';

export type Preferences =
  | {
      persona?: Persona;
      owners: string[]; // required
      i18nFamily: string; // required
      marketplace: string; // required
      locale: string; // required
      preferredLanguage: string;
      currentFilter: number;
    }
  | undefined;

export const writeToLocal = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const readFromLocal = (key: string) => {
  const data: any = localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

//TODO V1, also write to Preferences Service
export const savePreferences = (preferences: any) => {
  currentPreferences = preferences;
  writeToLocal(preferencesKey, preferences);
};

const validatePreferences = (preferences: Preferences) => {
  if (preferences?.persona === 'owner') {
    return !isEmpty(preferences?.owners) && !isEmpty(preferences?.i18nFamily);
  } else if (preferences?.persona === 'marketplace') {
    return !isEmpty(preferences?.marketplace) && !isEmpty(preferences?.locale);
  }

  return true;
};

//TODO V1, check Preferences Service if none exist in localStorage
export const loadPreferences = (): Preferences => {
  if (isEmpty(currentPreferences)) {
    currentPreferences = readFromLocal(preferencesKey);
  }
  if (!validatePreferences(currentPreferences)) return;
  return currentPreferences;
};

export const updateCurrentFilter = (index: number): void => {
  currentPreferences.currentFilter = index;
  savePreferences(currentPreferences);
};

export const loadSavedFilters = (): SavedFilter[] => {
  if (isEmpty(currentFilters)) {
    currentFilters = readFromLocal(savedFiltersKey);
  }
  if (!currentFilters) currentFilters = [];
  return currentFilters;
};

export const saveDefaultFilter = (filter: SavedFilter): void => {
  currentFilters[0] = filter;
  writeToLocal(savedFiltersKey, currentFilters);
};

export const saveFilter = (filter: SavedFilter): number => {
  currentFilters.push(filter);
  writeToLocal(savedFiltersKey, currentFilters);
  return currentFilters.length - 1;
};

export const validateNewFilterName = (name: string): boolean => {
  if (!name || name.length <= 0) return false;

  loadSavedFilters();

  for (const filter of currentFilters) {
    if (filter.name === name) return false;
  }
  return true;
};

export const getFilterByName = (name: string) => {
  return currentFilters.findIndex((filter: SavedFilter) => {
    return filter.name === name;
  });
};

export const deleteFilterByName = (name: string): boolean => {
  const toRemoveIndex = currentFilters.findIndex((filter: SavedFilter) => {
    return filter.name === name;
  });

  if (toRemoveIndex == -1) {
    return false;
  }

  currentFilters.splice(toRemoveIndex, 1);
  writeToLocal(savedFiltersKey, currentFilters);

  if (toRemoveIndex == currentPreferences.currentFilter) {
    currentPreferences.currentFilter = 0;
    writeToLocal(preferencesKey, currentPreferences);
  }

  if (currentPreferences.currentFilter >= currentFilters.length) {
    currentPreferences.currentFilter -= 1;
    writeToLocal(preferencesKey, currentPreferences);
  }

  return true;
};

export const renameFilter = (oldName: string, newName: string) => {
  const toRenameIndex = currentFilters.findIndex((filter: SavedFilter) => {
    return filter.name === oldName;
  });

  if (toRenameIndex != -1) {
    currentFilters[toRenameIndex].name = newName;
    writeToLocal(savedFiltersKey, currentFilters);
  }
};

export const createDefaultFilter = () => {
  let i18nFamily = {};
  let owners = {};

  if (currentPreferences?.i18nFamily) {
    i18nFamily = [JSON.parse(currentPreferences?.i18nFamily)];
  }
  if (currentPreferences?.owners) {
    owners = currentPreferences.owners.map((encodedOwner: string) =>
      JSON.parse(encodedOwner)
    );
  }

  const defaultFilter: SavedFilter = {
    name: 'Default',
    filter: {
      i18nFamily: i18nFamily,
      owners: owners,
    } as FilterCategory,
  };

  saveFilter(defaultFilter);
};

const ensureSavedFilters = () => {
  loadPreferences();
  loadSavedFilters();

  if (currentFilters.length == 0) createDefaultFilter();
};

export const getCurrentFilter = (): FilterCategory => {
  ensureSavedFilters();

  const index = currentPreferences.currentFilter;
  return currentFilters[index].filter;
};

export const getDefaultFilter = (): FilterCategory => {
  ensureSavedFilters();
  return currentFilters[0].filter;
};
