export function removeFalsyAttrs(obj: any): any {
  const newObj = {} as any;
  Object.keys(obj).forEach((key) => {
    if (
      (typeof obj[key] === 'boolean' && obj[key]) ||
      (obj[key] && obj[key] !== '[]' && obj[key].length !== 0)
    ) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export const idsToJsonString = (
  ids?: string | string[]
): string | undefined => {
  if (!ids) return;
  const result = Array.isArray(ids)
    ? ids
        .filter((v: string, i: number) => v.length > 0 && ids.indexOf(v) === i)
        .map(Number)
        .filter((num: number) => !isNaN(num))
    : [Number(ids)].filter((num: number) => !isNaN(num));

  return JSON.stringify(result);
};

export const stringIdsToJsonString = (
  ids?: string | string[]
): string | undefined => {
  if (!ids) return;
  const result = Array.isArray(ids)
    ? ids.filter((v: string, i: number) => ids.indexOf(v) === i)
    : [ids];
  return JSON.stringify(result);
};
