import React, { useEffect, useState } from 'react';
import ErrorHandler from 'src/components/common/ui/ErrorHandler/ErrorHandler';
import FilterPane from 'src/components/Preferences/FilterModal/FilterPane';
import './FilterModal.scss';
import {
  KatAccordion,
  KatAccordionItem,
  KatButton,
  KatCheckbox,
  KatModal,
  KatSpinner,
} from '@amzn/katal-react';
import initialMetricsPublisher from 'src/metrics';
import { ApolloError } from 'apollo-client';
import { I18nFamily } from 'src/generated/graphql';
import OwnerTypeaheadList from 'src/components/common/ui/TypeaheadList/OwnerTypeaheadList';
import LocalizedPackageTypeaheadList from 'src/components/common/ui/TypeaheadList/LocalizedPackageTypeaheadList';
import { Mode } from 'src/components/common/ui/TypeaheadList/TypeaheadList';
import AccordionHeading from './AccordionHeading';
import { getTranslationStatuses } from 'src/components/StringDetail/TranslationProgress/TranslationStatusEnum';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import PackageTypeTypeaheadList from 'src/components/common/ui/TypeaheadList/PackageTypeTypeaheadList';
import { useQueryResult } from 'src/components/common/provider/QueryResultProvider';

export type Criteria = {
  id: string | number;
  name: string;
};

export type FilterCategory = {
  i18nFamily?: Criteria[];
  owners?: Criteria[];
  localizedPackages?: Criteria[];
  packageTypes?: Criteria[];
  statuses?: Criteria[];
  sourceTexts?: Criteria[];
  psmsIds?: Criteria[];
  stringIds?: Criteria[];
  locales?: Criteria[];
  translations?: Criteria[];
};

type Props = {
  id: string;
  applied: FilterCategory;
  onApply: (newFilter: FilterCategory) => void;
  includedStatuses?: boolean;
};

const metricPublisher: any =
  initialMetricsPublisher.newChildActionPublisherForMethod('StringList-Filter');

const FilterModal: React.FC<Props> = (props: Props) => {
  /* React Hooks */
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [i18nFamily, setI18nFamily] = useState(props.applied.i18nFamily);
  const [owners, setOwners] = useState(props.applied.owners);
  const [localizedPackages, setLocalizedPackages] = useState(
    props.applied.localizedPackages
  );
  const [packageTypes, setPackageTypes] = useState(props.applied.packageTypes);
  const [statuses, setStatuses] = useState(props.applied.statuses);
  const { t } = useTranslation();

  useEffect(() => {
    setI18nFamily(props.applied.i18nFamily);
    setOwners(props.applied.owners);
    setLocalizedPackages(props.applied.localizedPackages);
    setPackageTypes(props.applied.packageTypes);
    setStatuses(props.applied.statuses);
  }, [props.applied]);

  /* Graphql Hooks */
  const {
    data: i18nData,
    loading: i18nLoading,
    error: i18nError,
    fetch: fetchI18nFamilies,
  } = useQueryResult();
  if (!i18nData && !i18nLoading && !i18nError) {
    fetchI18nFamilies();
  }

  /* Handlers */
  const addFilterHandler = () => setShowFilterModal(!showFilterModal);

  const applyFilterHandler = () => {
    setShowFilterModal(false);

    const filter: FilterCategory = {};
    if (i18nFamily) {
      filter.i18nFamily = i18nFamily;
    }
    if (owners) {
      filter.owners = owners;
    }
    if (localizedPackages) {
      filter.localizedPackages = localizedPackages;
    }
    if (packageTypes) {
      filter.packageTypes = packageTypes;
    }
    if (statuses) {
      filter.statuses = statuses;
    }
    props.onApply(filter);
  };

  const cancelFilterHandler = () => {
    //Revert any user change
    setI18nFamily(props.applied.i18nFamily);
    setOwners(props.applied.owners);
    setLocalizedPackages(props.applied.localizedPackages);
    setPackageTypes(props.applied.packageTypes);
    setStatuses(props.applied.statuses);
    setShowFilterModal(false);
  };

  /* Callbacks */
  const onI18nChange = (e: KatCheckbox.ChangeEvent) => {
    const { checked } = e.detail;
    const id = Number((e.target as KatCheckbox.Props).value);
    const name = (e.target as KatCheckbox.Props).label as string;
    if (checked) {
      setI18nFamily([...(i18nFamily || []), { id, name }]);
    } else {
      setI18nFamily(i18nFamily?.filter((item) => item.id !== id));
    }
  };
  const onOwnerChange = (e: KatCheckbox.ChangeEvent) => {
    const { checked } = e.detail;
    const id = Number((e.target as KatCheckbox.Props).value);
    const name = (e.target as KatCheckbox.Props).label as string;
    if (checked) {
      setOwners([...(owners || []), { id, name }]);
    } else {
      setOwners(owners?.filter((item) => item.id !== id));
    }
  };
  const onLocalizedPackageChange = (e: KatCheckbox.ChangeEvent) => {
    const { checked } = e.detail;
    const id = Number((e.target as KatCheckbox.Props).value);
    const name = (e.target as KatCheckbox.Props).label as string;
    if (checked) {
      setLocalizedPackages([...(localizedPackages || []), { id, name }]);
    } else {
      setLocalizedPackages(localizedPackages?.filter((item) => item.id !== id));
    }
  };
  const onPackageTypesChange = (e: KatCheckbox.ChangeEvent) => {
    const { checked } = e.detail;
    const id = String((e.target as KatCheckbox.Props).value);
    const name = (e.target as KatCheckbox.Props).label as string;
    if (checked) {
      setPackageTypes([...(packageTypes || []), { id, name }]);
    } else {
      setPackageTypes(packageTypes?.filter((item) => item.id !== id));
    }
  };
  const onStatusChange = (e: KatCheckbox.ChangeEvent) => {
    const { checked } = e.detail;
    const id = Number((e.target as KatCheckbox.Props).value);
    const name = (e.target as KatCheckbox.Props).label as string;
    if (checked) {
      setStatuses([...(statuses || []), { id, name }]);
    } else {
      setStatuses(statuses?.filter((item) => item.id !== id));
    }
  };

  /* JSX */
  const filterModalBody = () => {
    if (i18nError) {
      metricPublisher.publishCounterMonitor('error-count', 1);
      metricPublisher.publishStringTruncate(
        'error-log',
        `${i18nError?.message}`
      );
      return (
        <ErrorHandler
          id={props.id + '-error'}
          publisher={metricPublisher}
          header={t('error-flashbar-header-message-filter-options')}
          error={i18nError as ApolloError}
        />
      );
    } else {
      return (
        <section id={props.id + '-container'}>
          <KatAccordion>
            <KatAccordionItem>
              <AccordionHeading
                id={props.id + 'i18n-heading'}
                title={t('string-field-i18n-family')}
                contents={i18nFamily}
              />
              {!i18nData || i18nLoading ? (
                <KatSpinner />
              ) : (
                <FilterPane
                  id={props.id + '-pane-i18n'}
                  label={t('string-field-i18n-family')}
                  useSearch={false}
                  data={i18nData?.i18nFamilies as I18nFamily[]}
                  selected={i18nFamily}
                  onChange={onI18nChange}
                />
              )}
            </KatAccordionItem>
            <KatAccordionItem>
              <AccordionHeading
                id={props.id + 'owner-heading'}
                title={t('string-field-owner')}
                contents={owners}
              />
              <OwnerTypeaheadList
                mode={Mode.Full}
                i18nFamilies={i18nFamily?.map((item) => item.id)}
                typeaheadProps={{
                  id: props.id + '-pane-owner',
                  useSearch: true,
                  label: t('string-field-owner'),
                  placeholder: t('add-filter-modal-search-for-owner'),
                  selected: owners,
                  onChange: onOwnerChange,
                }}
              />
            </KatAccordionItem>
            <KatAccordionItem>
              <AccordionHeading
                id={props.id + 'package-heading'}
                title={t('string-field-package')}
                contents={localizedPackages}
              />
              <LocalizedPackageTypeaheadList
                mode={Mode.Full}
                i18nFamilies={i18nFamily?.map((item) => item.id)}
                owners={owners?.map((item) => item.id)}
                typeaheadProps={{
                  id: props.id + '-pane-package',
                  useSearch: true,
                  label: t('string-field-package-name'),
                  placeholder: t('add-filter-modal-search-for-package'),
                  selected: localizedPackages,
                  onChange: onLocalizedPackageChange,
                }}
              />
            </KatAccordionItem>
            <KatAccordionItem>
              <AccordionHeading
                id={props.id + 'package-heading'}
                title={t('string-field-client-name')}
                contents={packageTypes}
              />
              <PackageTypeTypeaheadList
                mode={Mode.Full}
                i18nFamilies={i18nFamily?.map((item) => item.id)}
                owners={owners?.map((item) => item.id)}
                typeaheadProps={{
                  id: props.id + '-pane-packageType',
                  useSearch: true,
                  label: t('string-field-client-name'),
                  placeholder: t('add-filter-modal-search-for-client-name'),
                  selected: packageTypes,
                  onChange: onPackageTypesChange,
                }}
              />
            </KatAccordionItem>
            {props.includedStatuses && (
              <KatAccordionItem>
                <AccordionHeading
                  id={props.id + 'status-heading'}
                  title={t('column-status')}
                  contents={statuses}
                />
                <FilterPane
                  id={props.id + '-pane-status'}
                  label={t('column-status')}
                  useSearch={false}
                  data={getTranslationStatuses()}
                  selected={statuses}
                  onChange={onStatusChange}
                />
              </KatAccordionItem>
            )}
          </KatAccordion>
        </section>
      );
    }
  };

  return (
    <div id={props.id}>
      <KatButton
        id={props.id + '-add'}
        variant="primary"
        label={`+ ${t('string-list-page-add-filters')}`}
        onClick={addFilterHandler}
      />
      <KatModal visible={showFilterModal} onClose={cancelFilterHandler}>
        <span slot="title">{t('add-filter-modal-title')}</span>
        {filterModalBody()}
        <div slot="footer">
          <KatButton
            id={props.id + '-cancel'}
            variant="secondary"
            label={t('add-filter-modal-button-cancel')}
            onClick={cancelFilterHandler}
            className="cancel-button"
          />
          <KatButton
            id={props.id + '-apply'}
            label={t('add-filter-modal-button-apply')}
            onClick={applyFilterHandler}
          />
        </div>
      </KatModal>
    </div>
  );
};
export default React.memo(FilterModal);
