import React, { useCallback, useEffect, useState } from 'react';
import { Localization } from 'src/generated/graphql';
import xliff from 'src/components/xliff';
import {
  extractUnits,
  getPreview,
  Preview,
  Unit,
  UnitKey,
  updateXliffTargetWith,
} from 'src/components/common/util/XLIFFHelpers';
import styles from './MFEditor.module.scss';
import TagifiedTextarea from 'src/components/MessageFormatEditor/MFTextarea';
import InputState from 'src/components/MessageFormatEditor/MFTextarea/UserInputValidationStatusEnum';
import { isRTL } from 'src/i18n/locales';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import { KatLabel, KatTextarea } from '@amzn/katal-react';
import { getPreviewTextAreaClassName } from 'src/components/common/util/RTLLanguageHelpers';

type Props = {
  id: string;
  localization: Localization;
  onInput?: (
    newXliffTranslation: string,
    unitID: string,
    inputState: InputState
  ) => void;
};

const MFEditor: React.FC<Props> = (props: Props) => {
  /* React Hooks */
  const { t, i18n } = useTranslation();
  const [translationXliffObj, setTranslationXliffObj] = useState<any>();
  const [originalTransPreview, setOriginalTransPreview] = useState<Preview[]>(
    []
  );
  useEffect(() => {
    async function parseXliff() {
      const xliffObj = await xliff.xliff2js(props?.localization?.translation);

      if (originalTransPreview.length === 0) {
        const { previews } = await getPreview(
          props?.localization?.translation || '',
          'xliff',
          UnitKey.TARGET
        );
        setOriginalTransPreview(previews);
      }
      setTranslationXliffObj(xliffObj);
    }
    parseXliff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.localization]);

  const units = translationXliffObj ? extractUnits(translationXliffObj) : [];
  const stringLocaleDir = isRTL(props?.localization?.locale || '')
    ? 'rtl'
    : 'ltr';
  const languageDir = isRTL(i18n.language) ? 'rtl' : 'ltr';

  /* Gql Hooks */

  /* Handlers */
  const updateL10n = async (unitId: string, inputState: InputState) => {
    props.onInput?.(
      await xliff.js2xliff(translationXliffObj),
      unitId,
      inputState
    );
  };
  /* Callbacks */
  const handleInputChange = useCallback(
    (unitId: string) => {
      return (newInput: string, inputState: InputState) => {
        updateXliffTargetWith(units, newInput, unitId);
        //Update the translation xliff string in localization obj
        updateL10n(unitId, inputState);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [units]
  );
  /* JSX */
  const getTagifiedTextarea = (
    unitId: string,
    unit: Unit,
    originalText: string
  ) => {
    //Empty <target> and undefined <target> should be treated differently
    //target undefined means the MF rule is N/A for this locale
    if (unit.target === undefined) {
      return (
        <span className={styles.notApplicable}>
          {t('tagify-translation-not-applicable')}
        </span>
      );
    }
    return (
      <TagifiedTextarea
        id={unitId + 'textarea'}
        input={unit.getVal('target')}
        currentTranslation={originalText}
        onChange={handleInputChange(unitId)}
        dir={stringLocaleDir}
      />
    );
  };
  return (
    <div className={styles.editorContainer} id={props.id + '-editor'}>
      {units.map(([unitId, unit]: [string, Unit], index) => (
        <div key={unitId} className={styles.unitContainer}>
          {originalTransPreview[index].metadataText !== '' && (
            <div dir={languageDir} className="preview-container">
              <h4>{originalTransPreview[index].metadataText}</h4>
            </div>
          )}
          {originalTransPreview[index].content !== '' ? (
            <>
              <div dir={languageDir} className="preview-container">
                <KatTextarea
                  className={getPreviewTextAreaClassName(
                    i18n.language,
                    props?.localization?.locale || ''
                  )}
                  disabled
                  value={originalTransPreview[index].content}
                  label={t('edit-pane-current-translation-label')}
                />
                <KatLabel
                  text={t('edit-pane-input-label')}
                  className="translationLabel"
                />
              </div>
              {getTagifiedTextarea(
                unitId,
                unit,
                originalTransPreview[index].content
              )}
            </>
          ) : (
            <div dir={languageDir} className="preview-container">
              <KatLabel
                text={t('tagify-translation-not-applicable')}
                className="translationLabel"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MFEditor;
