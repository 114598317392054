export const onInfinityScroll = (
  event: any,
  callback: (() => void) | undefined
) => {
  const element = event.currentTarget;
  const padding = 75;

  if (
    element.scrollHeight - element.scrollTop <=
    element.clientHeight + padding
  ) {
    if (callback) callback();
  }
};
