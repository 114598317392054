import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'src/components/auth/AuthContext';
import { AuthenticationState } from 'src/components/auth/FederatedAuth';
import { SuperuserContext } from 'src/components/auth/SuperuserContext';
import { KatIcon } from '@amzn/katal-react';
import adminAvatar from 'src/images/admin-avatar.png';
import style from './UserAvatar.module.scss';
import { toggleDarkTheme } from 'src/components/common/util/SuperUserHelpers';

type Props = {
  id: string;
};

const UserAvatar: React.FC<Props> = (props: Props) => {
  /* React Hooks */
  const { authState, userId } = useContext(AuthContext);
  const { isSuperuser, isSudoMode, setIsSudoMode } =
    useContext(SuperuserContext);

  //Toggle between dark/light theme
  useEffect(() => {
    toggleDarkTheme(isSudoMode);
  }, [isSudoMode]);

  /* Handlers */
  const getAvatarUrl = () => {
    return isSudoMode
      ? adminAvatar
      : `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userId}`;
  };
  const toggleSudoMode = () => {
    if (!isSuperuser) {
      return;
    }
    setIsSudoMode(!isSudoMode);
  };

  const getAvatar = () => {
    if (authState === AuthenticationState.LOGGED_IN) {
      return (
        <img
          src={getAvatarUrl()}
          alt="Avatar"
          className={`${style.avatar} exclude-dark`}
          id={props.id + '-icon'}
          onClick={toggleSudoMode}
        />
      );
    }
    return (
      <KatIcon name="account_circle" size="large" id={props.id + '-icon'} />
    );
  };

  /* JSX */
  return <>{getAvatar()}</>;
};

export default UserAvatar;
