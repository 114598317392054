import { KatDataTable } from '@amzn/katal-react';
import {
  cellFormatterConstructor,
  headerFormatterConstructor,
} from 'src/components/common/util/KatTableColumnHelpers';
import { getLanguageDirectionBasedOnLocale } from 'src/components/common/util/RTLLanguageHelpers';
import { I18nextArbShim } from 'src/i18next-arb-shim/useTranslation';

const headerFormatter = headerFormatterConstructor(
  'lui-landing-releases-header'
);
const cellFormatter = cellFormatterConstructor('lui-landing-releases-item');

export default function (
  i18n: I18nextArbShim
): KatDataTable.ColumnConfiguration[] {
  const langDirection = getLanguageDirectionBasedOnLocale(i18n.language);
  return [
    {
      label: '',
      property: 'featureCategory',
      headerFormatter: headerFormatter(
        '',
        'featureCategory',
        'releases-table-header'
      ),
      formatter: cellFormatter(
        'featureCategory',
        'feature-cat-cell',
        langDirection
      ),
    },
    {
      label: i18n.t('landing-releases-table-header-current'),
      property: 'current',
      headerFormatter: headerFormatter(
        i18n.t('landing-releases-table-header-current'),
        'current',
        'releases-table-header'
      ),
      formatter: cellFormatter('current', 'releases-table-cell', langDirection),
    },
    {
      label: i18n.t('landing-releases-table-header-future'),
      property: 'future',
      headerFormatter: headerFormatter(
        i18n.t('landing-releases-table-header-future'),
        'future',
        'releases-table-header'
      ),
      formatter: cellFormatter('future', 'releases-table-cell', langDirection),
    },
  ];
}
