import React, { useState } from 'react';
import { KatDropdown } from '@amzn/katal-react';
import { useLocalizationContext } from '@amzn/react-arb-tools';
import * as KatalMetrics from '@amzn/katal-metrics';
import { Settings } from 'luxon';

import styles from './LanguagePicker.module.scss';
import { sortAndTransformLocales } from 'src/i18n/locales';

import initialMetricsPublisher from 'src/metrics';
import {
  loadPreferences,
  Preferences,
  savePreferences,
} from 'src/api/preferencesClient';
import { DEFAULT_LC_BUILDER, DEFAULT_LOCALE } from 'src/i18n';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';

const LanguagePicker: React.FC = () => {
  let preferences: Preferences = loadPreferences();
  const [selectedLanguage, setSelectedLanguage] = useState(
    preferences?.preferredLanguage || DEFAULT_LOCALE
  );
  const { t } = useTranslation();
  const { setLocalizationContext } = useLocalizationContext();

  const handleLanguageChange = (e: CustomEvent<{ value: string }>) => {
    const locale = e.detail.value;

    const localeChangeMetricsPublisher =
      initialMetricsPublisher.newChildActionPublisherForMethod('LocaleChange');
    const localeChangeHandlerMetric = new KatalMetrics.Metric.TimedAttempt(
      'Translation'
    ).withMonitor();
    localeChangeMetricsPublisher.publish(
      new KatalMetrics.Metric.String('locale', locale || 'NONE')
    );

    setSelectedLanguage(locale);
    // Get latest preferences
    preferences = loadPreferences();
    // Save language to preferences in local storage
    savePreferences({ ...preferences, preferredLanguage: locale });

    // TODO: Handle error case
    setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(locale).build());

    localeChangeHandlerMetric.setSuccess();
    localeChangeMetricsPublisher.publish(localeChangeHandlerMetric);

    // We must manually translate the title of the page, unless this is
    // a Mons app, in which case the title is handled by Seller Central.
    const titleEl = document.querySelector('#app-title') as HTMLElement;
    if (titleEl) {
      titleEl.innerText = t('lui-page-title-text');
    }
    // Reset the locale for timestamp format specific to locale
    Settings.defaultLocale = locale;
  };

  return (
    <KatDropdown
      className={styles.languagePickerDropdown}
      value={selectedLanguage}
      options={sortAndTransformLocales()}
      onChange={handleLanguageChange}
    />
  );
};

export default LanguagePicker;
