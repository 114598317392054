import React, { createContext, useContext } from 'react';
import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import {
  AllI18nFamiliesQuery,
  useAllI18nFamiliesLazyQuery,
} from 'src/generated/graphql';

// https://www.apollographql.com/docs/technotes/TN0034-react-context/

interface QueryContextValue<TData> {
  data?: TData;
  error?: ApolloError;
  loading: boolean;
  fetch: any;
}

const QueryResultContext = createContext<QueryContextValue<any>>({
  loading: true,
  fetch: () => Promise.resolve({} as ApolloQueryResult<any>),
});

interface QueryResultProviderProps<
  TVariables extends OperationVariables | undefined = object
> {
  variables?: TVariables;
  children: React.ReactNode;
}

export const I18nFamilyQueryResultProvider = ({
  children,
}: QueryResultProviderProps) => {
  const [fetch, { data, error, loading }] = useAllI18nFamiliesLazyQuery({
    errorPolicy: 'all',
  });
  const value: QueryContextValue<AllI18nFamiliesQuery> = {
    data,
    error,
    loading,
    fetch,
  };
  return (
    <QueryResultContext.Provider value={value}>
      {children}
    </QueryResultContext.Provider>
  );
};

export const useQueryResult = () => {
  const context = useContext(QueryResultContext);
  if (!context) {
    throw new Error('useQueryResult must be used within a QueryResultProvider');
  }
  return context;
};
