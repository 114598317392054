import { Stage } from 'src/components/common/util/StageHelpers';

export interface AuthConfig {
  AppWebDomain: string;
  ClientId: string;
  RedirectUriSignIn: string;
  RedirectUriSignOut: string;
  TokenScopesArray: Array<string>;
  UserPoolId: string;
}

const tokenScopesArray = [
  'phone',
  'email',
  'profile',
  'openid',
  'aws.cognito.signin.user.admin',
];

export function getAuthConfigByStage(stage: Stage): AuthConfig {
  if (stage === Stage.Test) {
    stage = Stage.Beta;
  }
  const callbackUrl = window.location.origin + '/';
  const config: AuthConfig = {
    AppWebDomain:
      'cog-feline-static-' + stage + '.auth.us-west-2.amazoncognito.com',
    RedirectUriSignIn: callbackUrl,
    RedirectUriSignOut: callbackUrl,
    TokenScopesArray: tokenScopesArray,
    UserPoolId: '',
    ClientId: '',
  };

  switch (stage) {
    case Stage.Gamma:
      config.UserPoolId = 'us-west-2_NhPezWgpn';
      config.ClientId = '47fjq7cfuq6elmb2rbu1t0oumv';
      break;

    case Stage.Prod:
      config.UserPoolId = 'us-west-2_wGaPjHgQn';
      config.ClientId = '5f6fvt093c5naaqgmvemcj66gr';
      break;

    default:
      config.UserPoolId = 'us-west-2_9S2jAyyww';
      config.ClientId = '452jh6qpqhk5c4salmj3fojheo';
      break;
  }
  return config;
}
