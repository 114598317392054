import React, { useState, useEffect } from 'react';
import {
  KatIcon,
  KatAlert,
  KatButton,
  KatModal,
  KatTable,
  KatTableHead,
  KatTableRow,
  KatTableCell,
  KatTableBody,
  KatDropdownButton,
} from '@amzn/katal-react';

import { FilterCategory } from '../FilterModal/FilterModal';
import {
  renameFilter,
  updateCurrentFilter,
  loadPreferences,
  getFilterByName,
} from 'src/api/preferencesClient';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import '../InitialModal/InitialModal.scss';
import NewFilterModal from 'src/components/Preferences/SavedFilterModal/NewFilterModal';

import './SelectedFilterIcon.scss';
import { SavedFilter } from 'src/components/Filter/Filter';

type Props = {
  id: string;
  display: boolean;
  currentFilter: FilterCategory;
  savedFilters: SavedFilter[];
  setFilter: (newFilter: FilterCategory) => void;
  deleteFilter: (name: string) => void;
  onCancel: () => void;
};

const SavedFilterModal: React.FC<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState(props.display);
  const [showRenameFilterModal, setShowRenameFilterModal] = useState(false);
  const [oldName, setOldName] = useState('');
  const [deleteError, setDeleteError] = useState(false);

  const { t } = useTranslation();

  const dropdownOptions = [
    {
      label: t('saved-filters-modal-load-action'),
      action: 'load',
    },
    {
      label: t('saved-filters-modal-rename-action'),
      action: 'rename',
    },
    {
      label: t('saved-filters-modal-delete-action'),
      action: 'delete',
    },
  ];

  const loadFilterAction = (name: string) => {
    const index = getFilterByName(name);
    if (index != -1) {
      const newFilter = props.savedFilters[index];
      props.setFilter(newFilter.filter);
      updateCurrentFilter(index);

      props.onCancel();
    }
  };

  const renameFilterAction = (name: string) => {
    setOldName(name);
    setShowRenameFilterModal(true);
  };

  const deleteFilterAction = (name: string) => {
    if (props.savedFilters.length <= 1) {
      setDeleteError(true);
      return;
    }

    setDeleteError(false);
    props.deleteFilter(name);
  };

  const filterActionHandler = (
    e: KatDropdownButton.ActionEvent,
    name: string
  ) => {
    const action: string = e.detail.action;
    switch (action) {
      case 'load':
        loadFilterAction(name);
        break;
      case 'rename':
        renameFilterAction(name);
        break;
      case 'delete':
        deleteFilterAction(name);
        break;
    }
  };

  const savedFiltersToRows = (savedFilters: SavedFilter[]) => {
    let currentFilterIndex = 0;
    const preferences = loadPreferences();
    if (preferences) currentFilterIndex = preferences.currentFilter;

    if (!savedFilters) return;
    return savedFilters.map((filter, index: number) => {
      return (
        <KatTableRow key={index}>
          <KatTableCell>
            {index == currentFilterIndex ? (
              <KatIcon
                id={'selected-icon'}
                name={'checkmark-circle'}
                size={'small'}
              ></KatIcon>
            ) : (
              ''
            )}
            {filter.name}
          </KatTableCell>
          <KatTableCell>
            {index == 0 ? (
              <KatButton
                label={t('saved-filters-modal-load-action')}
                variant={'secondary'}
                onClick={() => loadFilterAction('Default')}
              />
            ) : (
              <KatDropdownButton
                variant={'secondary'}
                size={'base'}
                options={dropdownOptions}
                onAction={(e: KatDropdownButton.ActionEvent) =>
                  filterActionHandler(e, filter.name)
                }
              />
            )}
          </KatTableCell>
        </KatTableRow>
      );
    });
  };

  useEffect(() => {
    setShowModal(props.display);
  }, [props.display]);

  useEffect(() => {
    savedFiltersToRows(props.savedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.savedFilters)]);

  const onFilterRename = (newName: string) => {
    renameFilter(oldName, newName);
  };

  const renderDeleteError = () => {
    if (deleteError)
      return (
        <KatAlert
          header={t('saved-filters-modal-delete-error-title')}
          description={t('saved-filters-modal-delete-error-body')}
          variant={'warning'}
        ></KatAlert>
      );
  };

  return (
    <div id={props.id}>
      <KatModal
        visible={showModal}
        id={props.id + '-modal'}
        onClose={props.onCancel}
        scroll-all
      >
        <span slot="title">{t('saved-filters-modal-title')}</span>
        <div id={props.id + '-modal-body'}>
          <KatTable id={`${props.id}-table`}>
            <KatTableHead>
              <KatTableRow>
                <KatTableCell>
                  {t('saved-filters-modal-name-column')}
                </KatTableCell>
                <KatTableCell>
                  {t('saved-filters-modal-actions-column')}
                </KatTableCell>
              </KatTableRow>
            </KatTableHead>
            <KatTableBody>
              {savedFiltersToRows(props.savedFilters)}
            </KatTableBody>
          </KatTable>
          {renderDeleteError()}
        </div>

        <NewFilterModal
          display={showRenameFilterModal}
          id="lui-rename-filter-modal"
          onCancel={() => setShowRenameFilterModal(false)}
          onSaveCurrentFilter={onFilterRename}
        />
      </KatModal>
    </div>
  );
};

export default SavedFilterModal;
