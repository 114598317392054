import React, { useState, useEffect } from 'react';
import { KatButton, KatModal, KatInput } from '@amzn/katal-react';

import { validateNewFilterName } from 'src/api/preferencesClient';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';

type Props = {
  id: string;
  display: boolean;
  oldName?: string;
  onSaveCurrentFilter: (newName: string, oldName?: string) => void;
  onCancel: () => void;
};

const NewFilterModal: React.FC<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState(props.display);
  const [inputError, setInputError] = useState(false);
  const { t } = useTranslation();

  const inputRef = React.createRef<any>();

  useEffect(() => {
    setShowModal(props.display);
  }, [props.display]);

  const handleNewFilterSave = () => {
    const name: string = inputRef.current.value;
    if (!validateNewFilterName(name)) {
      setInputError(true);
      return;
    }
    setInputError(false);

    if (!props.oldName) props.onSaveCurrentFilter(inputRef.current.value);
    else props.onSaveCurrentFilter(inputRef.current.value, props.oldName);
    props.onCancel();
  };

  const inputRenderControl = () => {
    if (inputError)
      return (
        <KatInput
          ref={inputRef}
          type={'text'}
          state={'error'}
          state-emphasis={t('new-filter-modal-error-state')}
          state-label={t('new-filter-modal-error-message')}
        ></KatInput>
      );
    else return <KatInput ref={inputRef} type={'text'}></KatInput>;
  };

  return (
    <div>
      <KatModal
        visible={showModal}
        id={props.id + '-modal'}
        onClose={props.onCancel}
        scroll-all
      >
        <span slot="title">{t('saved-filters-modal-title')}</span>
        <div id={props.id + '-modal-body'}>
          Name
          {inputRenderControl()}
        </div>
        <div slot="footer" className="kat-group-horizontal">
          <KatButton
            label={t('saved-filters-modal-save-button')}
            onClick={handleNewFilterSave}
          />
        </div>
      </KatModal>
    </div>
  );
};

export default NewFilterModal;
