import React from 'react';
import styles from './LUIToaster.module.scss';
export type LUIToasterMessageTuple = {
  id: string | number;
  label: string;
  detail: string;
};
type Props = {
  id: string;
  message: string;
  lists?: LUIToasterMessageTuple[];
};

const LUIToaster: React.FC<Props> = (props: Props) => {
  /* JSX */
  return (
    <div id={props.id} className={styles.toaster}>
      <h4>{props.message}</h4>
      {props.lists?.map((msg) => (
        <div key={msg.id} className={styles.toasterRow}>
          <span>{msg.label}</span>
          <span>{msg.detail}</span>
        </div>
      ))}
    </div>
  );
};

export default LUIToaster;
