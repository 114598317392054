import { escape, unescape } from 'lodash';
import xmlConverter from 'xml-js';
import xliffConverter from 'src/components/xliff';
import { extractValue } from 'src/components/xliff/xml-js/xmlToObject';
import ElementTypes from 'src/components/xliff/inline-elements/ElementTypes2';
interface MapProps {
  [key: string]: RegExp;
}
const tagPatternMap: MapProps = {
  ph: /<ph(?:\s+[\w-]*=(["'])((?:\\\1|(?:(?!\1)).)*)(\1))*\/?>/gm, //Matches <ph...>
  pcStart: /<pc(?:\s+[\w-]*=(["'])((?:\\\1|(?:(?!\1)).)*)(\1))*\/?>/gm, //Matches <pc...>
  pcEnd: /<\/pc[^>]*>/g, //Matches </pc...>
};

export const XLIFF_FORMAT = 'xliff';
export const PREVIEW_SEPARATOR = '|';

const dispPattern = /(?<=disp\=\")[^"]*/i; //Matches the value of disp="..."
const dispStartPattern = /(?<=dispStart\=\")[^"]*/i; //Matches the value of dispStart="..."
const dispEndPattern = /(?<=dispEnd\=\")[^"]*/i; //Matches the value of dispEnd="..."

export type XLIFFTag = {
  value: string; // must be unique, required by Tagify (id)
  type: string; // pcStart|pcEnd|ph
  display: string; //rendered text
  originalVal?: string;
  readonly?: boolean;
  editable: boolean;
  title?: string; // tooltip
};

export type TaggedXLIFF = {
  input: string;
  output: string;
  tags: XLIFFTag[];
};

export const generateTags = (str: string) => {
  const result: TaggedXLIFF = {
    input: str,
    output: str,
    tags: [],
  };

  const dispEndValQueue: (string | undefined)[] = []; //This is working because we don't have nested pc tag as of today
  return Object.keys(tagPatternMap).reduce((acc, patternKey) => {
    const pattern = tagPatternMap[patternKey];
    acc.output = acc.output.replace(pattern, (match) => {
      //extract the disp value
      let dispVal,
        readonly = true;
      switch (patternKey) {
        case 'ph':
          dispVal = match.match(dispPattern)?.[0];
          readonly = false;
          break;
        case 'pcStart':
          dispVal = match.match(dispStartPattern)?.[0];
          const dispEndVal = match.match(dispEndPattern)?.[0]; // save dispEnd value for later use
          dispEndValQueue.push(dispEndVal);
          break;
        case 'pcEnd':
          dispVal = dispEndValQueue.shift();
      }
      if (!dispVal) {
        dispVal = escape(match); // Fallback to display xliff
      }

      //create tag object
      const tag: XLIFFTag = {
        value: (acc.tags.length + 1).toString(), //use length as an incremental id
        type: patternKey,
        display: escape(unescape(dispVal)),
        ...(readonly && { readonly: readonly }),
        editable: false,
        title: escape(match),
      };
      acc.tags.push(tag);
      return `[[${JSON.stringify(tag)}]]`; // replace match with tag object
    });

    return acc;
  }, result);
};

export const removeTags = (str: string) => {
  return str
    .split('[[')
    .map((seg1) => {
      const seg2 = seg1.split(']]');
      const tagObjStr = seg2[0];
      let value;
      try {
        const tag = JSON.parse(tagObjStr);
        value = tag.title;
        seg2[0] = unescape(value);
        seg2[1] = escape(seg2[1]); //escape the other non-tag half in case there is unsafe chars
      } catch (err) {
        seg2[0] = escape(tagObjStr); //If not a tag, use the original value; escape is not needed if the data we get from po are already protected with inline xliff tags
      }

      return seg2.join('');
    })
    .join('');
};

export const isTagRemovable = (type: string) => {
  return type === 'ph';
};

export type Unit = {
  source: string;
  target: string;
  sourceXmlObj: any;
  targetXmlObj: any;
  getVal: (name: string) => string;
};

export const extractUnits = (xliffObj: any): [string, Unit][] => {
  try {
    const groups = Object.values(xliffObj?.resources)[0] as any[];
    const groupUnitsObj = Object.values(groups)[0]?.groupUnits;
    return Object.entries(groupUnitsObj);
  } catch (err) {
    console.error('Data/Parsing Error: Cannot retrieve units');
    return [];
  }
};

export const extractMetadataAsStr = (unit: any) => {
  //non-MF string
  if (!unit?.metadata || unit?.metadata?.groups?.length === 0) {
    return '';
  }
  const group = unit.metadata.groups.find(
    (group: any) =>
      group.additionalAttributes?.category === 'linguistic variables'
  );
  if (!group) {
    console.error(
      'Cannot find MF variables, check unit level metadata in xliff'
    );
    return '';
  }
  const mfVars = group.entries?.map((entry: any) => {
    const varName = entry.type.split(':')?.[1];
    return `${varName}:${entry.value}`;
  });
  return mfVars.join('    ');
};

function parseOriginalContent(xliffStr: string) {
  const pcEndValQ: (string | undefined)[] = [];
  return Object.keys(tagPatternMap).reduce((acc, patternKey) => {
    const pattern = tagPatternMap[patternKey];
    acc = acc.replace(pattern, (match) => {
      //extract the disp value
      let dispVal;
      switch (patternKey) {
        case 'ph':
          dispVal = match.match(dispPattern)?.[0];
          break;
        case 'pcStart':
          dispVal = match.match(dispStartPattern)?.[0];
          const dispEndVal = match.match(dispEndPattern)?.[0]; // save dispEnd value for later use
          pcEndValQ.push(dispEndVal);
          break;
        case 'pcEnd':
          dispVal = pcEndValQ.shift();
      }
      if (!dispVal) {
        dispVal = match; // No inline xliff tag found, display as is
      }

      return dispVal;
    });
    return acc;
  }, xliffStr);
}

export type Preview = {
  metadataText: string;
  content: string;
};
export enum UnitKey {
  SOURCE = 'source',
  TARGET = 'target',
}
export const getPreview = async (
  inputStr: string,
  format: string | null | undefined,
  type: UnitKey // source or target
) => {
  const defaultResult = {
    onelinePreview: inputStr,
    isMF: false,
    previews: [{ metadataText: '', content: inputStr || '' }],
  };
  if (format !== XLIFF_FORMAT || !inputStr) return defaultResult;
  try {
    const xliffObj = await xliffConverter.xliff2js(inputStr);
    const units = extractUnits(xliffObj);
    const isMF = units.length > 1; // MF string has multiple units
    const previews: Preview[] = units.map(([id, unit]) => {
      const metadataText = extractMetadataAsStr(unit);
      const content = unit[type]
        ? unescape(parseOriginalContent(unit.getVal(type)))
        : '';
      return { metadataText, content };
    });
    const onelinePreview = previews
      .map(({ content }) => content)
      .filter((content) => content !== '')
      .join(PREVIEW_SEPARATOR);
    return { onelinePreview, isMF, previews };
  } catch (err) {
    console.error('Data/Parsing Error: Cannot retrieve source');
    return defaultResult; // return original xliff
  }
};

export const updateXliffTargetWith = (
  units: [string, Unit][],
  newVal: string,
  unitId: string
) => {
  //Regenerate the target object
  const xmlTargetObj = xmlConverter.xml2js(
    `<target>${newVal.replaceAll(
      /&(?!(?:apos|quot|[gl]t|amp);|#)/g,
      '&amp;'
    )}</target>`,
    {
      captureSpacesBetweenElements: true,
    }
  )?.elements?.[0];

  const xliffTargetObj = extractValue(xmlTargetObj.elements, ElementTypes);
  //update xliffObj
  const unit = units.find(([id, _unit]) => id === unitId)?.[1];
  if (unit) {
    unit.target = xliffTargetObj;
    unit.targetXmlObj = xmlTargetObj;
  }
};
