import React from 'react';
import {
  LocalizationContextProvider,
  MbmProvider,
} from '@amzn/react-arb-tools';
import DefaultBundleProvider from 'src/i18next-arb-shim/DefaultBundleProvider';
import { i18n, localizationContextProvider } from 'src/i18n';

interface Props {
  children?: React.ReactNode;
}

/**
 * Uses the mbm that's created in src/i18n and makes it available via MbmProvider
 * downstream. Eases the migration to ARB JS by providing a DefaultBundleProvider
 * that downstream components can rely on.
 * @param children
 * @constructor
 */
const ArbI18nextWrapper: React.FC<Props> = ({ children }: Props) => {
  return (
    <LocalizationContextProvider
      localizationContext={localizationContextProvider()}
    >
      <MbmProvider mbm={i18n.mbm}>
        <DefaultBundleProvider>{children}</DefaultBundleProvider>
      </MbmProvider>
    </LocalizationContextProvider>
  );
};

export default ArbI18nextWrapper;
