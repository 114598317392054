import { map, sortBy } from 'lodash';

type Locale = {
  name: string;
  polyfillModuleName: string;
  regionAlias: string;
  value: string;
};

/*
 * name: Language name
 * value: Locale tag according to the retail family here: https://w.amazon.com/bin/view/INTech/Panther/I18nFamily#Hretail used
 *        to select the right translation file
 *        (DO NOT CHANGE THIS TAG AS IT WILL BREAK LANGUAGE PICKER)
 * regionAlias: Regional value of the tag used to show the right displayname in the picker
 * polyfillModuleName: Name of the polyfill module in @formatjs (DO NOT CHANGE THIS TAG AS IT WILL BREAK LANGUAGE PICKER)
 * */
const locales: Locale[] = [
  {
    name: 'Arabic',
    value: 'ar-AE',
    regionAlias: 'ar-AE',
    polyfillModuleName: 'ar-AE',
  },
  {
    name: 'Bengali',
    value: 'bn-IN',
    regionAlias: 'bn-IN',
    polyfillModuleName: 'bn-IN',
  },
  {
    name: 'Czech',
    value: 'cs-CZ',
    regionAlias: 'cs-CZ',
    polyfillModuleName: 'cs',
  },
  {
    name: 'Danish',
    value: 'da-DK',
    regionAlias: 'da-DK',
    polyfillModuleName: 'da',
  },
  {
    name: 'German',
    value: 'de-DE',
    regionAlias: 'de-DE',
    polyfillModuleName: 'de',
  },
  {
    name: 'English',
    value: 'en-AE',
    regionAlias: 'en-AE',
    polyfillModuleName: 'en-AE',
  },
  {
    name: 'English',
    value: 'en-AU',
    regionAlias: 'en-AU',
    polyfillModuleName: 'en-AU',
  },
  {
    name: 'English',
    value: 'en-CA',
    regionAlias: 'en-CA',
    polyfillModuleName: 'en-CA',
  },
  {
    name: 'English',
    value: 'en-GB',
    regionAlias: 'en-GB',
    polyfillModuleName: 'en-GB',
  },
  {
    name: 'English',
    value: 'en-IN',
    regionAlias: 'en-IN',
    polyfillModuleName: 'en-IN',
  },
  {
    name: 'English',
    value: 'en-NG',
    regionAlias: 'en-NG',
    polyfillModuleName: 'en-NG',
  },
  {
    name: 'English',
    value: 'en-SG',
    regionAlias: 'en-SG',
    polyfillModuleName: 'en-SG',
  },
  {
    name: 'English',
    value: 'en-US',
    regionAlias: 'en-US',
    polyfillModuleName: 'en-US',
  },
  {
    name: 'English',
    value: 'en-ZA',
    regionAlias: 'en-ZA',
    polyfillModuleName: 'en-ZA',
  },
  {
    name: 'Spanish',
    value: 'es-ES',
    regionAlias: 'es-ES',
    polyfillModuleName: 'es',
  },
  {
    name: 'Spanish',
    value: 'es-MX',
    regionAlias: 'es-MX',
    polyfillModuleName: 'es-MX',
  },
  {
    name: 'Spanish',
    value: 'es-US',
    regionAlias: 'es-US',
    polyfillModuleName: 'es-US',
  },
  {
    name: 'French',
    value: 'fr-BE',
    regionAlias: 'fr-BE',
    polyfillModuleName: 'fr-BE',
  },
  {
    name: 'French',
    value: 'fr-CA',
    regionAlias: 'fr-CA',
    polyfillModuleName: 'fr-CA',
  },
  {
    name: 'French',
    value: 'fr-FR',
    regionAlias: 'fr-FR',
    polyfillModuleName: 'fr',
  },
  {
    name: 'Hebrew',
    value: 'he-IL',
    regionAlias: 'he-IL',
    polyfillModuleName: 'he',
  },
  {
    name: 'Hindi',
    value: 'hi-IN',
    regionAlias: 'hi-IN',
    polyfillModuleName: 'hi',
  },
  {
    name: 'Italian',
    value: 'it-IT',
    regionAlias: 'it',
    polyfillModuleName: 'it',
  },
  {
    name: 'Japanese',
    value: 'ja-JP',
    regionAlias: 'ja-JP',
    polyfillModuleName: 'ja',
  },
  {
    name: 'Kannada',
    value: 'kn-IN',
    regionAlias: 'kn-IN',
    polyfillModuleName: 'kn',
  },
  {
    name: 'Korean',
    value: 'ko-KR',
    regionAlias: 'ko-KR',
    polyfillModuleName: 'ko',
  },
  {
    name: 'Malayalam',
    value: 'ml-IN',
    regionAlias: 'ml-IN',
    polyfillModuleName: 'ml',
  },
  {
    name: 'Marathi',
    value: 'mr-IN',
    regionAlias: 'mr-IN',
    polyfillModuleName: 'mr',
  },
  {
    name: 'Dutch',
    value: 'nl-BE',
    regionAlias: 'nl-BE',
    polyfillModuleName: 'nl-BE',
  },
  {
    name: 'Dutch',
    value: 'nl-NL',
    regionAlias: 'nl-NL',
    polyfillModuleName: 'nl',
  },
  {
    name: 'Polish',
    value: 'pl-PL',
    regionAlias: 'pl-PL',
    polyfillModuleName: 'pl',
  },
  {
    name: 'Portuguese',
    value: 'pt-BR',
    regionAlias: 'pt-BR',
    polyfillModuleName: 'pt',
  },
  {
    name: 'Portuguese',
    value: 'pt-PT',
    regionAlias: 'pt-PT',
    polyfillModuleName: 'pt-PT',
  },
  {
    name: 'Swedish',
    value: 'sv-SE',
    regionAlias: 'sv-SE',
    polyfillModuleName: 'sv',
  },
  {
    name: 'Tamil',
    value: 'ta-IN',
    regionAlias: 'ta-IN',
    polyfillModuleName: 'ta',
  },
  {
    name: 'Telugu',
    value: 'te-IN',
    regionAlias: 'te-IN',
    polyfillModuleName: 'te',
  },
  {
    name: 'Turkish',
    value: 'tr-TR',
    regionAlias: 'tr-TR',
    polyfillModuleName: 'tr',
  },
  {
    name: 'Chinese',
    value: 'zh-CN',
    regionAlias: 'zh-Hans',
    polyfillModuleName: 'zh-Hans',
  },
  {
    name: 'Chinese',
    value: 'zh-TW',
    regionAlias: 'zh-Hant',
    polyfillModuleName: 'zh-Hant',
  },
];

// Transform locales to display their names in their respective languages and sort them
const sortAndTransformLocales = () => {
  const localesWithDisplayNames = map(locales, (locale) => {
    return {
      ...locale,
      name: new Intl.DisplayNames([locale.regionAlias], {
        type: 'language',
      }).of(locale.regionAlias),
    };
  });
  return sortBy(localesWithDisplayNames, ['value']);
};

const isRTL = (locale: string) => {
  const RTLLanguages = ['ar-AE', 'ar-SA', 'he-IL'];
  return RTLLanguages.includes(locale);
};

export { sortAndTransformLocales, isRTL, locales };
