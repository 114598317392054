import { isRTL } from 'src/i18n/locales';

// Return dir based on what locale is passed in
export const getLanguageDirectionBasedOnLocale = (locale: string) => {
  if (isRTL(locale)) {
    return 'rtl';
  }
  return 'ltr';
};

export const getPreviewTextAreaClassName = (
  language: string,
  locale: string
) => {
  if (isRTL(language) && isRTL(locale)) return 'kat-textarea lanR-locR';
  else if (!isRTL(language) && isRTL(locale)) return 'kat-textarea lanL-locR';
  else if (isRTL(language) && !isRTL(locale)) return 'kat-textarea lanR-locL';
  else return 'kat-textarea lanL-locL';
};
