import mocker from 'mocker-data-generator';
import { Translation, TranslationsDocument } from 'src/generated/graphql';
import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';

const translation: Translation = {
  phraseId: 123456,
  phraseName: 'translation_mock',
  sourceText: 'This is a mocked translation.',
  localization: {
    locale: 'en_CA',
    translation: 'This is a mocked translation, eh?',
    lastUpdated: 1602803146,
    lastUpdatedBy: 'STRIDER',
  },
};

const fakedTranslation = {
  id: {
    faker: 'datatype.number',
  },
  name: {
    faker: 'lorem.word',
  },
  sourceText: {
    faker: 'lorem.text',
  },
  format: {
    values: [null, 'xliff'],
  },
  localization: {
    locale: {
      faker: 'random.locale',
    },
    translation: {
      faker: 'lorem.text',
    },
    lastUpdated: {
      faker: 'datatype.number',
    },
    lastUpdatedBy: {
      faker: 'lorem.word',
    },
  },
};

const fakedPageInfo = {
  offset: {
    static: 1,
  },
  limit: {
    static: 20,
  },
  count: {
    static: 20,
  },
  totalCount: {
    faker: 'datatype.number({"min": 20, "max": 100})',
  },
};

const translations = {
  pageInfo: {
    hasOne: 'fakedPageInfo',
  },
  translationList: {
    hasMany: 'fakedTranslation',
    amount: 20,
    unique: true,
  },
};

export const translationsMock = (async () => {
  const { fakedTranslations } = await mocker()
    .seed('fakedTranslation', [translation])
    .schema('fakedPageInfo', fakedPageInfo, 1)
    .schema('fakedTranslation', fakedTranslation, 19)
    .schema('fakedTranslations', translations, 1)
    .build();

  return {
    request: {
      query: TranslationsDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        translations: fakedTranslations[0],
      },
    },
  };
})();
