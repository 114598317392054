import React, {
  useEffect,
  SyntheticEvent,
  PropsWithChildren,
  RefObject,
  Ref,
} from 'react';
import { isUndefined } from 'lodash';
import style from './TypeaheadDropdown.module.scss';
import classNames from 'classnames';
import { Option, Options } from './TypeaheadDropdown';

type Props = {
  children?: React.ReactNode;
  options: Options;
  isExpanded: boolean;
  focusedIndex?: number;
  setFocusedIndex: (index?: number) => void;
  onSelectOption: (option: Option) => void;
  close: () => void;
};

const Container = React.forwardRef(
  (props: PropsWithChildren<Props>, ref: Ref<HTMLDivElement>) => {
    /**
     * Close the dropdown if clicked outside the container
     */
    useEffect(() => {
      const refObject = ref as RefObject<HTMLDivElement>;
      const closeOnOutsideClick = (event: MouseEvent) => {
        if (
          !refObject.current ||
          !refObject.current.contains(event.target as HTMLElement)
        ) {
          props.close();
          return;
        }
      };
      window.addEventListener('click', closeOnOutsideClick);

      return () => {
        window.removeEventListener('click', closeOnOutsideClick);
      };
    }, [props, props.close, ref]);

    /**
     * Allow basic keyboard manipulation.
     * - Arrow up/down - move selection
     * - Enter - select
     * - Esc - close dropdown
     * @param event
     */
    const onKeydown = (event: SyntheticEvent) => {
      const keyboardEvent = event as unknown as KeyboardEvent;
      switch (keyboardEvent.key) {
        case 'ArrowDown':
          if (isUndefined(props.focusedIndex)) {
            props.setFocusedIndex(0);
          } else {
            props.setFocusedIndex(
              (props.focusedIndex + 1) % props.options.length
            );
          }
          break;
        case 'ArrowUp':
          const lastIndex = props.options.length - 1;
          if (isUndefined(props.focusedIndex)) {
            props.setFocusedIndex(lastIndex);
          } else {
            const previousIndex = props.focusedIndex - 1;
            props.setFocusedIndex(
              previousIndex >= 0 ? previousIndex : lastIndex
            );
          }
          break;
        case 'Enter':
          if (props.focusedIndex !== undefined) {
            props.onSelectOption(props.options[props.focusedIndex]);
          }
          break;
        case 'Escape':
          event.stopPropagation();
          event.preventDefault();
          props.close();
          break;
      }
    };

    const containerClassName = classNames(style.typeaheadDropdownContainer, {
      [style.expanded]: props.isExpanded,
    });

    return (
      <div ref={ref} className={containerClassName} onKeyDown={onKeydown}>
        {props.children}
      </div>
    );
  }
);

export default Container;
