import { GetPackageTypesDocument } from 'src/generated/graphql';
import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';

export const packageTypesMock = (async () => {
  return {
    request: {
      query: GetPackageTypesDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        packageTypes: [
          { packageType: 'packageType-0' },
          { packageType: 'packageType-1' },
          { packageType: 'packageType-2' },
          { packageType: 'packageType-3' },
          { packageType: 'packageType-4' },
        ],
      },
    },
  };
})();
