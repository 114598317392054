import React from 'react';
import { KatDropdown, KatRow, KatSpinner } from '@amzn/katal-react';
import initialMetricsPublisher from 'src/metrics';
import { Option } from 'src/components/common/ui/TypeaheadDropdown/TypeaheadDropdown';
import './OwnerPersonaFields.scss';
import OwnerTypeaheadList from 'src/components/common/ui/TypeaheadList/OwnerTypeaheadList';
import { Mode } from 'src/components/common/ui/TypeaheadList/TypeaheadList';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import { useQueryResult } from 'src/components/common/provider/QueryResultProvider';

type Props = {
  id: string;
  onOwnerChange: (selected: Option[]) => void;
  onI18nFamilyChange: (e: CustomEvent) => void;
  ownerRequired: boolean;
  i18nRequired: boolean;
  refetchQueryToggle?: boolean;
};

const metricPublisher: any =
  initialMetricsPublisher.newChildActionPublisherForMethod(
    'PreferencesModal-OwnerPersonaFields'
  );

const OwnerPersonaFields: React.FC<Props> = (props: Props) => {
  /* React Hooks*/
  const { t } = useTranslation();

  /* Gql Hooks*/
  const {
    data: i18nData,
    loading: i18nLoading,
    error: i18nError,
    fetch: fetchI18nFamilies,
  } = useQueryResult();
  if (!i18nData && !i18nLoading && !i18nError) {
    fetchI18nFamilies();
  }

  /* Handlers */
  const getDropdownErrorProps = (
    errorLabel: string,
    constraintLabel: string,
    isInvalidInput: boolean
  ) => {
    const errorProps = {} as any;
    if (i18nError) {
      errorProps.disabled = true;
      errorProps.state = 'error';
      errorProps.stateEmphasis = t('error-flashbar-header-default');
      errorProps.stateLabel = `${t(errorLabel)}. ${t('error-flashbar-desc')}`;
      return errorProps;
    } else if (isInvalidInput) {
      errorProps.state = 'error';
      errorProps.stateEmphasis = t('error-flashbar-header-default');
      errorProps.stateLabel = t(constraintLabel);
    } else {
      //No error
    }
    return errorProps;
  };

  const selectionBody = (props: Props) => {
    if (i18nLoading) {
      return (
        <KatRow className="pref-modal-spinner">
          <KatSpinner id={props.id + '-spinner'} />
        </KatRow>
      );
    }
    if (i18nError) {
      metricPublisher.publishCounterMonitor('error-count', 1);
      metricPublisher.publishStringTruncate(
        'error-log',
        `${i18nError?.message}`
      );
    }
    const i18nErrorProps = getDropdownErrorProps(
      'error-flashbar-header-message-i18family',
      'choose-18n-family-required',
      props.i18nRequired
    );
    const ownerErrorProps = getDropdownErrorProps(
      'error-flashbar-header-message-teams',
      'choose-bindle-team-error-label',
      props.ownerRequired
    );
    const i18nFamilies = i18nData?.i18nFamilies?.map((ele: any) => {
      return {
        name: ele.name,
        value: JSON.stringify({ id: ele.id, name: ele.name }),
        key: ele.id,
      };
    });

    const i18nFamilyDropdownProps = {
      ...i18nErrorProps,
      options: i18nFamilies,
      label: t('choose-i18n-family-dropdown-label'),
      placeholder: t('placeholder-text-choose'),
      tooltipText: t('choose-i18n-family-tooltip'),
      onChange: props.onI18nFamilyChange,
    };

    const ownerDropdownProps = {
      ...ownerErrorProps,
      label: t('choose-bindle-team-dropdown-label'),
      placeholder: t('placeholder-text-choose'),
      tooltipText: t('choose-bindle-team-tooltip'),
      onSelectionChange: props.onOwnerChange,
    };
    return (
      <div className="pref-modal-dropdown-group">
        <div id={props.id + 'i18nContainer'}>
          <KatDropdown {...i18nFamilyDropdownProps} />
        </div>
        <div id={props.id + 'ownerContainer'}>
          <OwnerTypeaheadList
            mode={Mode.Compact}
            typeaheadProps={ownerDropdownProps}
          />
        </div>
      </div>
    );
  };

  return <>{selectionBody(props)}</>;
};

OwnerPersonaFields.displayName = 'OwnerPersonaFields';
export default OwnerPersonaFields;
