import React from 'react';
import ReactDOM from 'react-dom';
import { isRTL } from 'src/i18n/locales';
import TextExpander from '../ui/TextExpander';
import TextOverflowHover from '../ui/TextOverflowHover';
import { UnitKey } from 'src/components/common/util/XLIFFHelpers';

/**
 * Create a KatDataTable header formatter method. If no `idPrefix` is provided
 * headers will have no `id`.
 * @param idPrefix
 */
export function headerFormatterConstructor(idPrefix?: string) {
  return (label: string, property: string, className: string) => {
    // @see https://katal.corp.amazon.com/#/component/developer/KatalDataTable
    return (previouslyRenderedElement: any) => {
      return {
        text: label,
        element: previouslyRenderedElement,
        attributes: {
          class: className,
          id: idPrefix ? `${idPrefix}-${property}` : null,
        },
      };
    };
  };
}

/**
 * Create a KatDataTable column formatter method. If no `idPrefix` is provided
 * rows will have no `id`.
 * @param idPrefix
 */
export function cellFormatterConstructor(idPrefix?: string) {
  return (
    property: string,
    className: string,
    dir = 'ltr',
    formatText = (text: any) => text
  ) => {
    // @see https://katal.corp.amazon.com/#/component/developer/KatalDataTable
    return (rowIndex: any, rowData: any, previouslyRenderedElement: any) => {
      return {
        text: formatText(rowData[property]),
        attributes: {
          class: className,
          id: idPrefix ? `${idPrefix}-${rowIndex}-${property}` : null,
          dir,
        },
      };
    };
  };
}

/**
 * This is a hack since Katal does not provide an API to
 * render React components inside of data table cells
 * @see https://sage.amazon.com/posts/961337
 */
export const renderTextExpander = (
  rowData: { [index: string]: any },
  element: any
) => {
  if (!element) {
    element = document.createElement('span');
  }

  const { name, text, format, locale, type } = rowData;
  const jsx = (
    <TextExpander
      name={name}
      text={text}
      format={format}
      locale={locale}
      type={type}
    />
  );
  ReactDOM.render(jsx, element);

  return element;
};

/**
 * This is a hack since Katal does not provide an API to
 * render React components inside of data table cells
 * @see https://sage.amazon.com/posts/961337
 */
export const renderTextOverflowHover = (text: string, element: any) => {
  if (!element) {
    element = document.createElement('span');
  }

  const jsx = <TextOverflowHover text={text} />;
  ReactDOM.render(jsx, element);

  return element;
};

/**
 * Create a KatDataTable column formatter method. If no `idPrefix` is provided
 * rows will have no `id`.
 * @param idPrefix
 */
export function rtlAppliedCellFormatterConstructor(idPrefix?: string) {
  return (property: string, className: string, localeProperty: string) => {
    // @see https://katal.corp.amazon.com/#/component/developer/KatalDataTable
    return (rowIndex: any, rowData: any, previouslyRenderedElement: any) => {
      let dir = 'auto';
      if (isRTL(rowData[localeProperty])) {
        dir = 'rtl';
      }

      const data = {
        format: rowData.format,
        name: rowData.name,
        text: rowData[property],
        locale: rowData[localeProperty],
        type: UnitKey.TARGET,
      };

      return {
        element: renderTextExpander(data, previouslyRenderedElement),
        attributes: {
          class: className,
          id: idPrefix ? `${idPrefix}-${rowIndex}-${property}` : null,
          dir,
        },
      };
    };
  };
}

export function textExpanderCellFormatterConstructor(idPrefix?: string) {
  return (property: string, className: string, dir = 'ltr') => {
    // @see https://katal.corp.amazon.com/#/component/developer/KatalDataTable
    return (rowIndex: any, rowData: { [index: string]: any }, element: any) => {
      const data = {
        format: rowData.format,
        name: rowData.name,
        text: rowData[property],
        locale: rowData.sourceLocale,
        type: UnitKey.SOURCE,
      };

      return {
        element: renderTextExpander(data, element),
        attributes: {
          class: className,
          id: idPrefix ? `${idPrefix}-${rowIndex}-${property}` : null,
          dir,
        },
      };
    };
  };
}

export function textOverflowHoverCellFormatterConstructor(idPrefix?: string) {
  return (property: string, className: string, dir = 'ltr') => {
    // @see https://katal.corp.amazon.com/#/component/developer/KatalDataTable
    return (rowIndex: any, rowData: { [index: string]: any }, element: any) => {
      const text = rowData[property];
      return {
        text,
        element: renderTextOverflowHover(text, element),
        attributes: {
          class: className,
          id: idPrefix ? `${idPrefix}-${rowIndex}-${property}` : null,
          dir,
        },
      };
    };
  };
}
