import { Criteria } from 'src/components/Preferences/FilterModal/FilterModal';

enum TranslationStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  StatusUnknown = 'STATUS_UNKNOWN',
}

export default TranslationStatus;

export const getTranslationStatuses = (): Criteria[] => {
  const statuses: Criteria[] = [];
  Object.values(TranslationStatus).forEach((status, index) => {
    statuses.push({
      id: index,
      name: status,
    });
  });

  return statuses;
};
