import React from 'react';
import { KatDropdown, KatRow } from '@amzn/katal-react';
import { Phrase, Variant } from 'src/generated/graphql';
import style from './Banner.module.scss';
import ErrorHandler from 'src/components/common/ui/ErrorHandler/ErrorHandler';
import { find } from 'lodash';
import initialMetricsPublisher from 'src/metrics';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';

const metricPublisher: any =
  initialMetricsPublisher.newChildActionPublisherForMethod(
    'StringDetail-Banner'
  );
type Props = {
  phrase: Phrase;
  variants: Variant[];
  setVariant: (variant: Variant) => void;
};

const Banner: React.FC<Props> = (props: Props) => {
  const variant = props.phrase?.variant;
  const { t } = useTranslation();

  if (!variant) {
    return (
      <ErrorHandler
        publisher={metricPublisher}
        header={t('error-flashbar-header-message-string-detail-banner')}
        error={new ApolloError({ errorMessage: 'Variant is undefined' })}
      />
    );
  }

  const variantOptions = props.variants.map((variant) => ({
    name: variant.name,
    value: variant.id.toString(),
  }));

  const onVariantChange = (event: KatDropdown.ChangeEvent) => {
    if (!props.variants) {
      return;
    }
    const selectedVariant = find(props.variants, {
      id: parseInt(event.detail.value),
    });
    if (selectedVariant) {
      props.setVariant(selectedVariant);
    }
  };

  return (
    <KatRow className={style.katRow}>
      <div>
        <div className={style.titleContainer}>
          <h3>{props.phrase.name}</h3>
        </div>
      </div>
      <div className={style.variantDropdownContainer}>
        <KatDropdown
          options={variantOptions}
          value={variant.id.toString()} //Katal requires string type for value
          size="small"
          disabled={props.variants.length < 2}
          label={t('string-field-i18n-domain')}
          onChange={onVariantChange}
          tooltipText={t('choose-i18n-domain-tooltip-text')}
          tooltipPosition="bottom"
        />
      </div>
    </KatRow>
  );
};

export default Banner;
