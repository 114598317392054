import { TranslateFn } from 'src/i18next-arb-shim/useTranslation';

//Has to export within a function instead of const for i18n to display in runtime.

export default function (t: TranslateFn) {
  return [
    {
      featureCategory: t('landing-releases-table-feature-cat-1'),
      current: t('landing-releases-table-current-cat-1'),
      future: t('landing-releases-table-future-cat-1'),
    },
    {
      featureCategory: t('landing-releases-table-feature-cat-2'),
      current: t('landing-releases-table-current-cat-2'),
      future: t('landing-releases-table-future-cat-2'),
    },
    {
      featureCategory: t('landing-releases-table-feature-cat-3'),
      current: t('landing-releases-table-current-cat-3'),
      future: t('landing-releases-table-future-cat-3'),
    },
    {
      featureCategory: t('landing-releases-table-feature-cat-4'),
      current: '',
      future: t('landing-releases-table-future-cat-4'),
    },
  ];
}
