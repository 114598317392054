export const SEARCH_TRANSLATIONS = 'search_translations';

const FEATURES = [SEARCH_TRANSLATIONS]; // Beta features

const STRIDER_TEAM = [
  'adarrpgh',
  'cbbutenh',
  'epavolik',
  'hummjose',
  'jacronk',
  'ljasin',
  'rameamru',
  'samrana',
  'zcovone',
];
const BETA_USERS: string[] = []; //List of amazon alias that's allowed to access feature
const ALLOWED_USERS = [...STRIDER_TEAM, ...BETA_USERS];

export const getAllowedBetaFeature = (userId?: string | null) => {
  if (userId) {
    return ALLOWED_USERS.includes(userId) ? FEATURES : [];
  } else {
    return [];
  }
};
