export default {
  values: [
    'SUCCEEDED',
    'REJECTED_OUTSTANDING',
    'REJECTED_UNAUTHORIZED',
    'REJECTED_NO_CHANGE',
    'REJECTED_INCOMPLETE',
    'FAILED_LUI',
    'FAILED_PANTHER',
    'FAILED_UNKNOWN',
  ],
};
