/* eslint-disable react-hooks/exhaustive-deps */
import React, { JSX, useEffect, useState } from 'react';
import { KatBox, KatNavigation, KatRow } from '@amzn/katal-react';
import styles from 'src/components/Header/Header.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import logo from 'src/images/LUI-logo.svg';
import LanguagePicker from 'src/components/common/ui/LanguagePicker/LanguagePicker';
import UserAvatar from 'src/components/Header/UserAvatar/UserAvatar';
import SyncBanner from 'src/components/Header/SyncBanner/SyncBanner';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';

type Props = {
  id: string;
  stage: string;
};

const Header: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const title = 'Localization UI'; // replace with i18n.t ?
  let stage = '';
  if (props.stage !== 'prod') {
    stage = ` (${props.stage})`;
  }

  const onNavClick = (path: string) => {
    const urlParams = new URLSearchParams(history.location.search);
    urlParams.set('offset', '0');
    history.push(`${path}?${urlParams.toString()}`);
  };

  /* JSX */
  const getNavigation = (): JSX.Element => {
    const browseStrings =
      location.pathname === '/' ? (
        <a
          id={props.id + '-browse-strings'}
          onClick={() => onNavClick('/')}
          data-active
        >
          {t('lui-nav-browse-strings')}
        </a>
      ) : (
        <a id={props.id + '-browse-strings'} onClick={() => onNavClick('/')}>
          {t('lui-nav-browse-strings')}
        </a>
      );

    const searchTranslations =
      location.pathname === '/translations' ? (
        <a
          id={props.id + '-search-translations'}
          onClick={() => onNavClick('/translations')}
          data-active
        >
          {t('lui-nav-search-translations')}
        </a>
      ) : (
        <a
          id={props.id + '-search-translations'}
          onClick={() => onNavClick('/translations')}
        >
          {t('lui-nav-search-translations')}
        </a>
      );

    return (
      <KatNavigation mode="horizontal">
        {browseStrings}
        {searchTranslations}
      </KatNavigation>
    );
  };

  const [navigation, setNavigation] = useState<JSX.Element>(getNavigation());

  useEffect(() => {
    setNavigation(getNavigation());
  }, [location.pathname]);

  return (
    <header id={props.id}>
      {props.stage !== 'prod' && (
        <div
          className={`stage-indicator stage-indicator-${props.stage.toLowerCase()} exclude-dark`}
        >
          &nbsp;
        </div>
      )}
      <SyncBanner id={props.id + '-sync-banner'} />
      <KatBox className={styles.headerStyle}>
        <KatRow>
          <div className={styles.banner}>
            <img
              id={props.id + '-app-logo'}
              src={logo}
              onClick={() => history.push('/landing')}
            />
            <h2 id={props.id + '-app-name'}>{title}</h2>
            <p id={`${props.id}-${props.stage}`}>
              {props.stage !== 'prod' && <span dir="auto">{stage}</span>}
            </p>
          </div>
          <div className={styles.navigation}>{navigation}</div>
          <div className={styles.pickerUserGroup}>
            <LanguagePicker />
            <UserAvatar id={props.id + '-avatar'} />
          </div>
        </KatRow>
      </KatBox>
    </header>
  );
};

export default Header;
