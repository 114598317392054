import { KatFlashbar, KatRow, KatSpinner } from '@amzn/katal-react';
import React, { useEffect } from 'react';
import initialMetricsPublisher from 'src/metrics';
import TypeaheadList from 'src/components/common/ui/TypeaheadList/TypeaheadList';
import { Mode } from 'src/components/common/ui/TypeaheadList/TypeaheadList';
import {
  LocalizedPackage,
  useGetLocalizedPackagesQuery,
} from 'src/generated/graphql';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';

type Props = {
  queryLimit?: number;
  mode: Mode;
  typeaheadProps: any;
  i18nFamilies?: (string | number)[];
  owners?: (string | number)[];
};

const DEFAULT_LIMIT = 20;
const DEFAULT_SCROLL_INCREASE = 10;

//AppSync has a bug that doesn't take default query params defined in schema, so need to do it here
const localizedPackageQueryParams = {
  nextToken: '',
  limit: DEFAULT_LIMIT,
  name: '',
  i18nFamilies: '[]',
  owners: '[]',
};

let previousDataCount = 0;

const metricPublisher: any =
  initialMetricsPublisher.newChildActionPublisherForMethod(
    'LocalizedPackageTypeaheadList'
  );

const LocalizedPackageTypeaheadList = (props: Props) => {
  const { t } = useTranslation();

  const {
    data: localizedPackageData,
    loading: localizedPackageLoading,
    error: localizedPackageError,
    refetch: refetchLocalizedPackage,
  } = useGetLocalizedPackagesQuery({
    variables: { params: localizedPackageQueryParams },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    localizedPackageQueryParams.i18nFamilies = JSON.stringify(
      props.i18nFamilies
    );
    refetchLocalizedPackage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.i18nFamilies]);

  useEffect(() => {
    localizedPackageQueryParams.owners = JSON.stringify(props.owners);
    refetchLocalizedPackage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.owners]);

  const onTextChange = (text: string) => {
    if (localizedPackageLoading) return;
    localizedPackageQueryParams.limit = DEFAULT_LIMIT;
    localizedPackageQueryParams.name = text;
    refetchLocalizedPackage();
  };

  const onScrollDown = () => {
    if (localizedPackageLoading || !localizedPackageData) return;
    if (localizedPackageData.localizedPackages.length == previousDataCount)
      return;

    previousDataCount = localizedPackageData.localizedPackages.length;

    //no more results to get
    if (
      localizedPackageData &&
      localizedPackageData.localizedPackages.length < DEFAULT_LIMIT
    )
      return;

    localizedPackageQueryParams.limit += DEFAULT_SCROLL_INCREASE;
    refetchLocalizedPackage();
  };

  const selectionBody = () => {
    if (localizedPackageError) {
      metricPublisher.publishCounterMonitor('error-count', 1);
      metricPublisher.publishStringTruncate(
        'error-log',
        `${localizedPackageError?.message}`
      );
    }

    if (localizedPackageData === undefined) {
      return (
        <KatRow className="typeahead-list-spinner">
          <KatSpinner id={'spinner'} />
        </KatRow>
      );
    }

    const localizedPackages = localizedPackageData
      ? localizedPackageData.localizedPackages
      : [];

    if (
      !localizedPackageLoading &&
      localizedPackages.length == 0 &&
      localizedPackageQueryParams.name == ''
    ) {
      return <KatFlashbar header={t('add-filter-modal-error-no-packages')} />;
    }

    return (
      <TypeaheadList
        mode={props.mode}
        typeaheadProps={{
          ...props.typeaheadProps,
          onTextChange: onTextChange,
          onScrollDown: onScrollDown,
          isLoading: localizedPackageLoading,
          isSearching: localizedPackageQueryParams.name !== '',
          placeholder: t('add-filter-modal-search-for-package'),
          data: localizedPackages as LocalizedPackage[],
        }}
      />
    );
  };

  return <>{selectionBody()}</>;
};

export default LocalizedPackageTypeaheadList;
