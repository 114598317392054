import React from 'react';
import { KatBox } from '@amzn/katal-react';
import { Phrase } from 'src/generated/graphql';
import styles from './ContextBox.module.scss';
import { getLanguageDirectionBasedOnLocale } from 'src/components/common/util/RTLLanguageHelpers';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import TextExpander from 'src/components/common/ui/TextExpander';
import { UnitKey } from 'src/components/common/util/XLIFFHelpers';

type Props = {
  phrase: Phrase;
};

const ContextBox: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation();
  const langDirection = getLanguageDirectionBasedOnLocale(i18n.language);
  const { name, sourceText, format, context, sourceLocale } = props.phrase;

  return (
    <KatBox variant="white" className={styles.contextDataContainer}>
      <div>
        <h5 dir={langDirection}>
          {t('string-field-source')} ({props.phrase.sourceLocale})
        </h5>
        <div dir="auto" className={styles.textExpanderContainer}>
          <TextExpander
            name={name}
            text={sourceText}
            format={format}
            locale={sourceLocale}
            type={UnitKey.SOURCE}
          />
        </div>
      </div>
      <div>
        <h5 dir={langDirection}>{t('string-field-note')}</h5>
        <p dir="auto">{context}</p>
      </div>
    </KatBox>
  );
};

export default ContextBox;
