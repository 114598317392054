import React from 'react';
import { AuthenticationState } from 'src/components/auth/FederatedAuth';
type LUIAuthContext = {
  authState: AuthenticationState;
  userId: string | null;
};
export const AuthContext = React.createContext({
  authState: AuthenticationState.LOGIN_REQUIRED,
  userId: null,
} as LUIAuthContext);
