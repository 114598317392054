/* eslint-disable react-hooks/exhaustive-deps */
import {
  KatButton,
  KatDataTable,
  KatFlashbar,
  KatPagination,
  KatRow,
  KatSpinner,
} from '@amzn/katal-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import './TargetSearch.scss';
import Tags, { Tag } from '../common/ui/Tags/Tags';
import FilterModal, {
  Criteria,
  FilterCategory,
} from '../Preferences/FilterModal/FilterModal';
import {
  getCriteria,
  getMissingIds,
  updateFilterFromSearchToken,
  useDocumentTitle,
} from '../common/util/StringListHelpers';
import Search from '../Search/Search';
import {
  EMPTY_TOKEN,
  FilterField,
  targetTextSearchProperties,
} from '../Search/SearchConstants';
import {
  GetLocalesQuery,
  Locale,
  LocaleInput,
  LocalizedPackageInput,
  OwnerInput,
  PackageTypeInput,
  TranslationInput,
  useGetLocalesQuery,
  useGetLocalizedPackagesQuery,
  useGetOwnersQuery,
  useGetPackageTypesQuery,
  useTranslationsLazyQuery,
} from 'src/generated/graphql';
import { FilteringOption } from '@amzn/awsui-components-react/polaris/property-filter/interfaces';
import {
  getDefaultFilter,
  updateCurrentFilter,
} from 'src/api/preferencesClient';
import { removeFalsyAttrs } from '../common/util/JSToolbelt';
import { debounce, isEmpty, map } from 'lodash';
import { getLocalizedTimestamp } from '../common/util/TimestampHelpers';
import columnConfigConstructor from './ColumnConfig';
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGE_SIZE,
  FILTER_REMOVAL_DEBOUNCE_TIMEOUT,
  getOffset,
} from '../StringList/StringList';
import { useHistory } from 'react-router-dom';
import ErrorHandler from '../common/ui/ErrorHandler/ErrorHandler';
import rootMetricPublisher, { METRIC, timerStopwatchMetric } from 'src/metrics';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import { SuperuserContext } from '../auth/SuperuserContext';
import {
  getTranslationInputFromUrlSearchParams,
  getUrlSearchParam,
  setUrlFromTranslationInput,
} from '../common/util/URLHelpers';
import {
  I18nFamilyQueryResultProvider,
  useQueryResult,
} from '../common/provider/QueryResultProvider';
import { getClientNameLabel } from '../common/util/PackageHelpers';
import Pagination from '../common/ui/Pagination/Pagination';

const metricPublisher: KatalMetricsPublisher =
  rootMetricPublisher.newChildActionPublisherForMethod('TargetSearch');

//AppSync has a bug that doesn't take default query params defined in schema, so need to do it here
const ownerQueryParams: OwnerInput = {
  limit: DEFAULT_PAGE_SIZE,
  offset: DEFAULT_OFFSET,
};
const localizedPackageQueryParams: LocalizedPackageInput = {
  limit: DEFAULT_PAGE_SIZE,
};
const packageTypeQueryParams: PackageTypeInput = {
  limit: DEFAULT_PAGE_SIZE,
};

const ownerCache = new Map();
const localizedPackageCache = new Map();
const packageTypeCache = new Map();
const badOwnerIds = new Set<string | number>();
const badLocalizedPackageIds = new Set<string | number>();
const badPackageTypeIds = new Set<string>();

type Props = {
  id: string;
};

const TargetSearch: React.FC<Props> = (props: Props) => {
  /* Metrics */
  const initLatencyMetric = timerStopwatchMetric(METRIC.LOADING_LATENCY);
  const timeSpentMetric = timerStopwatchMetric(METRIC.TIME_SPENT);

  const history = useHistory();
  const { i18n, t } = useTranslation();
  useDocumentTitle(t('target-search-document-title'));
  const urlParams = new URLSearchParams(history?.location.search);
  urlParams.sort();
  history.replace({ search: urlParams.toString() });
  const [caseSensitive, setCaseSensitive] = useState(false);
  const [exactMatch, setExactMatch] = useState(false);

  /* Handlers */
  const setQueryInputFromFilter = (filter: FilterCategory) => {
    const i18nFamilies = JSON.stringify(
      filter.i18nFamily?.map((item: Criteria) => item.id)
    ) as TranslationInput['i18nFamilies'];
    const owners = JSON.stringify(
      filter.owners?.map((item: Criteria) => item.id)
    ) as TranslationInput['owners'];
    const localizedPackages = JSON.stringify(
      filter.localizedPackages?.map((item: Criteria) => item.id)
    ) as TranslationInput['localizedPackages'];
    const packageTypes = JSON.stringify(
      filter.packageTypes?.map((item: Criteria) => item.id)
    ) as TranslationInput['packageTypes'];
    const locales = JSON.stringify(
      filter.locales?.map((item: Criteria) => item.name)
    ) as TranslationInput['locales'];
    const translations = JSON.stringify(
      filter.translations?.map((item: Criteria) => item.name)
    ) as TranslationInput['translations'];

    const updatedQueryInput: TranslationInput = removeFalsyAttrs({
      ...queryInput,
      i18nFamilies,
      owners,
      localizedPackages,
      packageTypes,
      locales,
      translations,
    } as TranslationInput);

    setQueryInput(updatedQueryInput);
  };

  const setFilterFromQueryInput = () => {
    if (!i18nData || i18nError || i18nLoading) return;

    queryInput.caseSensitive && setCaseSensitive(true);
    queryInput.exactMatch && setExactMatch(true);

    const i18nFamily: Criteria[] =
      queryInput.i18nFamilies &&
      i18nData?.i18nFamilies.filter((family: Criteria) =>
        JSON.parse(queryInput.i18nFamilies).includes(family?.id)
      );

    const owners: Criteria[] =
      queryInput.owners && getCriteria(ownerCache, queryInput.owners);

    const localizedPackages: Criteria[] =
      queryInput.localizedPackages &&
      getCriteria(localizedPackageCache, queryInput.localizedPackages);

    const packageTypes: Criteria[] =
      queryInput.packageTypes &&
      getCriteria(packageTypeCache, queryInput.packageTypes);

    const locales: Criteria[] =
      queryInput.locales &&
      JSON.parse(queryInput.locales).map(
        (locale: string) =>
          ({ id: FilterField.TARGET_LOCALE, name: locale } as Criteria)
      );

    const translations: Criteria[] =
      queryInput.translations &&
      JSON.parse(queryInput.translations).map(
        (text: string) =>
          ({ id: FilterField.TRANSLATION, name: text } as Criteria)
      );

    let filterToSet: FilterCategory = removeFalsyAttrs({
      i18nFamily,
      owners,
      localizedPackages,
      packageTypes,
      locales,
      translations,
    });

    // If no filter criteria are set, use the default filter
    if (isEmpty(filterToSet)) {
      filterToSet = getDefaultFilter();
      setQueryInputFromFilter(filterToSet);
    }

    setFilter(filterToSet);
  };

  const { isSudoMode, setIsSudoMode, isSuperuser, isSuperUserLoading } =
    useContext(SuperuserContext);

  const [queryInput, setQueryInput] = useState(
    getTranslationInputFromUrlSearchParams(urlParams)
  );
  const [filter, setFilter] = useState<FilterCategory>({});
  const [searchToken, setSearchToken] = useState(EMPTY_TOKEN);
  const [localeQueryParams, setLocaleQueryParams] = useState<LocaleInput>({});
  const [localeOptions, setLocaleOptions] = useState<FilteringOption[]>([]);

  // Update the filter from the search token
  useEffect(() => {
    if (searchToken === EMPTY_TOKEN) {
      return;
    }
    if (!searchToken.propertyKey) {
      searchToken.propertyKey = FilterField.TRANSLATION;
    }

    const newFilter: FilterCategory = updateFilterFromSearchToken(
      searchToken,
      filter
    );
    setFilter(newFilter);
    setQueryInputFromFilter(newFilter);
    setSearchToken(EMPTY_TOKEN);
  }, [searchToken]);

  // Update the locale query params from the filter
  useEffect(() => {
    setLocaleQueryParams(
      filter.i18nFamily
        ? ({
            i18nFamilies: JSON.stringify(
              filter.i18nFamily.map((item: Criteria) => item.id)
            ),
          } as LocaleInput)
        : {}
    );
  }, [filter]);

  useEffect(() => {
    const sudoInUrl = getUrlSearchParam('sudo', history);
    if (isSuperuser && sudoInUrl === 'true') {
      setIsSudoMode(true);
    }
  }, [isSuperuser]);

  useEffect(() => {
    if (isSuperUserLoading) return;
    const updatedQueryInput: TranslationInput = removeFalsyAttrs({
      ...queryInput,
      sudo: isSudoMode,
    });
    setQueryInput(updatedQueryInput);
  }, [isSudoMode]);

  useEffect(() => {
    const updatedQueryInput: TranslationInput = removeFalsyAttrs({
      ...queryInput,
      caseSensitive,
    });
    setQueryInput(updatedQueryInput);
  }, [caseSensitive]);

  useEffect(() => {
    const updatedQueryInput: TranslationInput = removeFalsyAttrs({
      ...queryInput,
      exactMatch,
    });
    setQueryInput(updatedQueryInput);
  }, [exactMatch]);

  /* Graphql Hooks */
  const {
    data: i18nData,
    loading: i18nLoading,
    error: i18nError,
    fetch: fetchI18nFamilies,
  } = useQueryResult();
  if (!i18nData && !i18nLoading && !i18nError) {
    fetchI18nFamilies();
  }
  const {
    data: ownerData,
    error: ownerError,
    loading: ownerLoading,
    refetch: refetchOwner,
  } = useGetOwnersQuery({
    variables: { params: ownerQueryParams },
  });
  const {
    data: localizedPackageData,
    error: localizedPackageError,
    loading: localizedPackageLoading,
    refetch: refetchLocalizedPackage,
  } = useGetLocalizedPackagesQuery({
    variables: { params: localizedPackageQueryParams },
  });
  const {
    data: packageTypeData,
    error: packageTypeError,
    loading: packageTypeLoading,
    refetch: refetchPackageType,
  } = useGetPackageTypesQuery({
    variables: { params: packageTypeQueryParams },
  });
  useEffect(() => {
    if (
      i18nData &&
      !i18nError &&
      !i18nLoading &&
      ownerData &&
      !ownerError &&
      !ownerLoading &&
      localizedPackageData &&
      !localizedPackageError &&
      !localizedPackageLoading &&
      packageTypeData &&
      !packageTypeError &&
      !packageTypeLoading
    ) {
      setFilterFromQueryInput();
    }
  }, [i18nData, ownerData, localizedPackageData, packageTypeData]);

  const onGetLocalesQueryCompleted = (data: GetLocalesQuery) => {
    const locales = data.locales as Locale[];
    const options: FilteringOption[] = locales.map(
      (locale: Locale) =>
        ({
          propertyKey: FilterField.TARGET_LOCALE,
          value: locale.locale,
        } as FilteringOption)
    );

    setLocaleOptions(options);
  };
  const {
    loading: localeLoading,
    error: localeError,
    refetch: refetchLocales,
  } = useGetLocalesQuery({
    onCompleted: onGetLocalesQueryCompleted,
    variables: {
      params: localeQueryParams,
    },
  });
  useEffect(() => {
    if (localeLoading) {
      setLocaleOptions([]);
    }
  }, [localeLoading]);

  const [fetchTranslations, { data, loading, error }] =
    useTranslationsLazyQuery({
      errorPolicy: 'all',
    });

  useEffect(() => {
    setUrlFromTranslationInput(queryInput, history);
    if (doesMeetMinSearchCriteria(queryInput as TranslationInput)) {
      fetchTranslations({
        variables: {
          params: {
            ...queryInput,
            packageTypes: queryInput.packageTypes
              ? encodeURIComponent(queryInput.packageTypes)
              : undefined,
            locales: queryInput.locales
              ? encodeURIComponent(queryInput.locales)
              : undefined,
            translations: encodeURIComponent(queryInput.translations),
          } as TranslationInput,
        },
      });
    }
  }, [queryInput]);

  useEffect(() => {
    if (
      i18nLoading ||
      localizedPackageLoading ||
      ownerLoading ||
      !ownerData ||
      packageTypeLoading
    )
      return;

    ownerData?.owners.forEach((owner) =>
      ownerCache.set(owner?.id, owner?.name)
    );
    if (queryInput?.owners) {
      const missingOwnerIds = getMissingIds(
        ownerCache,
        queryInput.owners
      ).filter((id: any) => !badOwnerIds.has(id));
      if (missingOwnerIds && missingOwnerIds.length > 0) {
        missingOwnerIds.forEach((id: any) => badOwnerIds.add(id));
        ownerQueryParams.ids = JSON.stringify(missingOwnerIds);
        refetchOwner();
      } else {
        setFilterFromQueryInput();
      }
    }
  }, [
    i18nLoading,
    localizedPackageLoading,
    ownerData,
    ownerLoading,
    packageTypeLoading,
  ]);

  useEffect(() => {
    if (
      i18nLoading ||
      ownerLoading ||
      localizedPackageLoading ||
      !localizedPackageData ||
      packageTypeLoading
    )
      return;

    localizedPackageData?.localizedPackages.forEach((localizedPackage) =>
      localizedPackageCache.set(localizedPackage?.id, localizedPackage?.name)
    );
    if (queryInput?.localizedPackages) {
      const missingLocalizedPackageIds = getMissingIds(
        localizedPackageCache,
        queryInput.localizedPackages
      ).filter((id: any) => !badLocalizedPackageIds.has(id));
      if (missingLocalizedPackageIds && missingLocalizedPackageIds.length > 0) {
        missingLocalizedPackageIds.forEach((id: any) =>
          badLocalizedPackageIds.add(id)
        );
        localizedPackageQueryParams.ids = JSON.stringify(
          missingLocalizedPackageIds
        );
        refetchLocalizedPackage();
      } else {
        setFilterFromQueryInput();
      }
    }
  }, [
    i18nLoading,
    ownerLoading,
    localizedPackageData,
    localizedPackageLoading,
    packageTypeLoading,
  ]);

  useEffect(() => {
    if (
      i18nLoading ||
      ownerLoading ||
      localizedPackageLoading ||
      packageTypeLoading ||
      !packageTypeData
    )
      return;

    packageTypeData?.packageTypes.forEach((packageType) =>
      packageTypeCache.set(
        packageType?.packageType,
        getClientNameLabel(packageType?.packageType, t)
      )
    );
    if (queryInput?.packageTypes) {
      const missingPackageTypeIds = getMissingIds(
        packageTypeCache,
        queryInput.packageTypes
      ).filter((id: any) => !badPackageTypeIds.has(id));
      if (missingPackageTypeIds && missingPackageTypeIds.length > 0) {
        missingPackageTypeIds.forEach((id: any) => badPackageTypeIds.add(id));
        packageTypeQueryParams.packageType = JSON.stringify(
          missingPackageTypeIds
        );
        refetchPackageType();
      } else {
        setFilterFromQueryInput();
      }
    }
  }, [
    i18nLoading,
    ownerLoading,
    localizedPackageData,
    localizedPackageLoading,
    packageTypeLoading,
  ]);

  useEffect(() => {
    metricPublisher.publish(initLatencyMetric);
    return () => metricPublisher.publish(timeSpentMetric);
  }, []);

  /* Handlers */
  const onFilterApply = (newFilter: FilterCategory) => {
    const updatedFilter: FilterCategory = removeFalsyAttrs({
      ...newFilter,
      locales: filter.locales,
      translations: filter.translations,
    });
    setFilter(updatedFilter);
    setQueryInputFromFilter(updatedFilter);
  };

  const debounceFilterUpdate = useMemo(
    () => debounce(setQueryInputFromFilter, FILTER_REMOVAL_DEBOUNCE_TIMEOUT),
    [queryInput]
  );

  const onTagRemove = (e: React.MouseEvent, tag: Tag) => {
    const i18nFamily = filter.i18nFamily?.filter(
      (item: Criteria) => item.id !== tag.id || item.name !== tag.name
    );
    const owners = filter.owners?.filter(
      (item: Criteria) => item.id !== tag.id || item.name !== tag.name
    );
    const localizedPackages = filter.localizedPackages?.filter(
      (item: Criteria) => item.id !== tag.id || item.name !== tag.name
    );
    const packageTypes = filter.packageTypes?.filter(
      (item: Criteria) => item.id !== tag.id || item.name !== tag.name
    );
    const locales = filter.locales?.filter(
      (item: Criteria) => item.id !== tag.id || item.name !== tag.name
    );
    const translations = filter.translations?.filter(
      (item: Criteria) => item.id !== tag.id || item.name !== tag.name
    );
    const newFilter = removeFalsyAttrs({
      i18nFamily,
      owners,
      localizedPackages,
      packageTypes,
      locales,
      translations,
    });
    setFilter(newFilter);
    debounceFilterUpdate(newFilter);
  };

  const onDefaultFiltersButtonClick = () => {
    const newFilter: FilterCategory = removeFalsyAttrs({
      ...getDefaultFilter(),
      locales: filter.locales,
      translations: filter.translations,
    });
    setFilter(newFilter);
    updateCurrentFilter(0);
    setQueryInputFromFilter(newFilter);
  };

  const onPageChange = (e: KatPagination.ChangeEvent) => {
    const selectedPage = (e.target as any).page as number;
    const offset = getOffset(
      selectedPage,
      queryInput.limit ?? DEFAULT_PAGE_SIZE
    );
    setQueryInput(removeFalsyAttrs({ ...queryInput, offset }));
  };

  const rowData = useMemo(() => {
    return map(data?.translations.translationList, (row) => {
      return {
        ...row,
        locale: row.localization.locale,
        translation: row.localization.translation,
        lastUpdated: getLocalizedTimestamp(row.localization.lastUpdated),
        lastUpdatedBy: row.localization.lastUpdatedBy,
      };
    });
  }, [data?.translations.translationList, i18n.language]);

  const doesMeetMinSearchCriteria = (queryInput: TranslationInput): boolean => {
    return (
      !!queryInput.translations &&
      !!queryInput.i18nFamilies &&
      Object.keys(filter).length > 2
    );
  };

  /* JSX */
  const translationsTable = () => {
    if (!queryInput.translations) {
      return (
        <KatFlashbar
          id={props.id + '-flashbar'}
          header={t('target-search-flashbar-header')}
          linkHref="https://w.amazon.com/bin/view/INTech/Panther/LUI/UserGuide/SearchTranslations"
          linkLabel={t('target-search-flashbar-a')}
          linkTarget="_blank"
          variant="info"
        />
      );
    }

    if (!doesMeetMinSearchCriteria(queryInput as TranslationInput)) {
      return (
        <KatFlashbar
          id={props.id + '-flashbar-criteria'}
          header={t('target-search-criteria-flashbar-header')}
          description={t('target-search-criteria-flashbar-description')}
          title={t('target-search-criteria-flashbar-title')}
        />
      );
    }

    if (loading) {
      return <KatSpinner id={props.id + '-spinner'} />;
    }

    if (error) {
      return (
        <ErrorHandler
          id={props.id + '-error'}
          publisher={metricPublisher}
          header={t('error-flashbar-header-message-target-search')}
          error={error}
        />
      );
    }

    return (
      <>
        <KatDataTable
          id={props.id + '-table'}
          columns={columnConfigConstructor(t, history)}
          rowData={rowData}
        />
        {data?.translations.pageInfo && (
          <Pagination
            id={props.id + '-pagination'}
            onChange={onPageChange}
            pageInfo={data.translations.pageInfo}
          />
        )}
      </>
    );
  };

  return (
    <section id={props.id}>
      <KatRow id={props.id + '-header'}>
        <div>
          <h2 id={props.id + '-title'}>{t('target-search-page-title')}</h2>
        </div>
      </KatRow>
      <KatRow id={props.id + '-table-controls'}>
        <div>
          <Search
            id={props.id + '-search'}
            filteringPlaceholder={t('target-search-box-placeholder')}
            filteringProperties={targetTextSearchProperties}
            filteringOptions={localeOptions}
            filteringStatusType={
              localeLoading ? 'loading' : localeError ? 'error' : 'finished'
            }
            onLoadItems={refetchLocales}
            token={searchToken}
            setToken={setSearchToken}
            caseSensitive={caseSensitive}
            setCaseSensitive={setCaseSensitive}
            exactMatch={exactMatch}
            setExactMatch={setExactMatch}
            disableSearchOptions
          />
          <I18nFamilyQueryResultProvider>
            <FilterModal
              id={props.id + '-filter'}
              applied={filter}
              onApply={onFilterApply}
            />
          </I18nFamilyQueryResultProvider>
          <span id={props.id + '-applied'}>
            {t('string-list-page-applied-filters')}
          </span>
          <Tags
            id={props.id + '-tags'}
            filter={filter}
            onChange={onTagRemove}
          />
          <KatButton
            id={props.id + '-default-filters-button'}
            label={t('string-list-page-filter-apply')}
            onClick={onDefaultFiltersButtonClick}
          />
        </div>
      </KatRow>
      {translationsTable()}
    </section>
  );
};

export default TargetSearch;
