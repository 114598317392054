import { createContext } from 'react';
import { MessageBundle } from '@amzn/arb-tools';

/**
 * Context object for storing default bundle, so it's globally accessible
 * without wrapping it in a promise.
 */
const DefaultBundleContext = createContext<MessageBundle | undefined>(
  undefined
);
export default DefaultBundleContext;
