import React from 'react';
import FilterPane from 'src/components/Preferences/FilterModal/FilterPane';

type Props = {
  typeaheadProps: any;
};

const FullTypeaheadList: React.FC<Props> = (props: Props) => {
  return (
    <FilterPane {...props.typeaheadProps} data={props.typeaheadProps.data} />
  );
};

export default FullTypeaheadList;
