import React from 'react';
import { KatBox, KatLink, KatExpander, KatTooltip } from '@amzn/katal-react';
import { Phrase } from 'src/generated/graphql';
import { DateTime } from 'luxon';

import styles from './MetadataBox.module.scss';
import { getBaseBindleUrl } from 'src/components/common/util/BindleHelpers';
import { getLanguageDirectionBasedOnLocale } from 'src/components/common/util/RTLLanguageHelpers';
import { getPackageMetadata } from 'src/components/common/util/PackageHelpers';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';

type Props = {
  phrase: Phrase;
};

const i18FamilyWikiLinkPartial =
  'https://w.amazon.com/bin/view/INTech/Panther/I18nFamily/#H';
const teamLinkPartial = 'https://permissions.amazon.com/a/team/';
const userLinkPartial = 'https://phonetool.amazon.com/users/';
export const PERMISSIONS_LINK_PARTIAL = `${getBaseBindleUrl()}/resource/panther/package/`;

const MetadataBox: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation();
  const langDirection = getLanguageDirectionBasedOnLocale(i18n.language);
  const getLocalizedTimestamp = () => {
    const dateToParse = props.phrase.lastUpdated as number;
    const dateTimeObj = DateTime.fromSeconds(dateToParse);
    return dateTimeObj.toLocaleString({
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      // DateTime objects are created in the system's local zone so this will get user's local timezone
      timeZone: dateTimeObj.zoneName,
    });
  };

  const { link, linkLabel, clientNameLabel } = getPackageMetadata(
    {
      name: `${props.phrase.localizedPackage}`,
      packageType: props.phrase.localizedPackageType,
      sourceSystemId: props.phrase.localizedPackageOriginId,
    },
    t
  );

  const calculateHref = () => {
    if (props.phrase.ownerType === 'USER') {
      return userLinkPartial + props.phrase.owner;
    }
    return teamLinkPartial + props.phrase.owner;
  };
  return (
    <KatBox variant="white">
      <div className={styles.metadataContainer}>
        <div className={styles.metadataItem}>
          <h5 title={t('string-field-updated')} dir={langDirection}>
            {t('string-field-updated')}
          </h5>
          <p dir={langDirection}>{getLocalizedTimestamp()}</p>
        </div>
        <div className={styles.metadataItem}>
          <h5 title={t('string-field-updated-by')} dir={langDirection}>
            {t('string-field-updated-by')}
          </h5>
          <p>{props.phrase.lastUpdatedBy}</p>
        </div>
        <div className={styles.metadataItem}>
          <h5 title={t('string-field-i18n-family')} dir={langDirection}>
            {t('string-field-i18n-family')}
          </h5>
          <p>
            <KatLink
              href={i18FamilyWikiLinkPartial + props.phrase.i18nFamily}
              target="_blank"
            >
              {props.phrase.i18nFamily}
            </KatLink>
          </p>
        </div>
        <div className={styles.metadataItem}>
          <h5 title={t('string-field-owner')} dir={langDirection}>
            {t('string-field-owner')}
          </h5>
          <p>
            <KatLink href={calculateHref()} target="_blank">
              {props.phrase.owner}
            </KatLink>
          </p>
        </div>
        <div>
          <h5 title={t('string-field-package-name')} dir={langDirection}>
            {t('string-field-package-name')}
          </h5>
          <p>
            {props.phrase.localizedPackage}
            {link && (
              <>
                {' ('}
                <KatLink href={link} target="_blank">
                  {linkLabel}
                </KatLink>
                {')'}
              </>
            )}
          </p>
        </div>
        <div className={styles.metadataItem}>
          <h5 title={t('string-field-client-name')} dir={langDirection}>
            {t('string-field-client-name')}
          </h5>
          <p>{clientNameLabel}</p>
        </div>
        <div className={styles.metadataItem}>
          <h5 title={t('string-field-permission')} dir={langDirection}>
            {t('string-field-permission')}
          </h5>
          <p>
            <KatLink
              href={
                PERMISSIONS_LINK_PARTIAL +
                encodeURIComponent(
                  props.phrase.localizedPackageTsAceName ||
                    props.phrase.localizedPackageOriginId ||
                    ''
                )
              }
              target="_blank"
            >
              {props.phrase.localizedPackageTsAceName ||
                props.phrase.localizedPackageOriginId}
            </KatLink>
          </p>
        </div>
      </div>
      <div>
        <KatExpander className={styles.expander}>
          <div className={styles.expanderLabel} slot="label">
            {t('string-field-additional-information')}
          </div>
          <div>
            <div className={styles.expanderItem}>
              <b>{t('string-field-psms-id')}</b>
              <KatTooltip
                label={t('string-field-psms-id-tooltip-text-v01')}
                triggerIcon="help_outline"
                position="bottom"
                size="tiny"
              />
              : {props.phrase.psmsId}
            </div>

            <div className={styles.expanderItem}>
              <b>{t('string-field-anchor')}</b>
              <KatTooltip
                label={t('string-field-anchor-tooltip-text')}
                triggerIcon="help_outline"
                position="bottom"
                size="tiny"
              />
              : {props.phrase.anchorTag}
            </div>
          </div>
        </KatExpander>
      </div>
    </KatBox>
  );
};

export default MetadataBox;
