import create from 'src/images/landing-create.svg';
import translate from 'src/images/landing-translate.svg';
import notify from 'src/images/landing-notifi.svg';
import review from 'src/images/landing-review.svg';
import publish from 'src/images/landing-publish.svg';
import { TranslateFn } from 'src/i18next-arb-shim/useTranslation';

const workflowStepsConstructor = (t: TranslateFn) => {
  return [
    {
      id: 'landing-create',
      img: create,
      stepTitle: t('landing-workflow-create-step-title'),
      stepDetail: t('landing-workflow-create-step-detail'),
    },
    {
      id: 'landing-translate',
      img: translate,
      stepTitle: t('landing-workflow-translate-step-title'),
      stepDetail: t('landing-workflow-translate-step-detail'),
    },
    {
      id: 'landing-review',
      img: review,
      stepTitle: t('landing-workflow-review-step-title'),
      stepDetail: t('landing-workflow-review-step-detail'),
    },
    {
      id: 'landing-notify',
      img: notify,
      stepTitle: t('landing-workflow-notify-step-title'),
      stepDetail: t('landing-workflow-notify-step-detail'),
    },
    {
      id: 'landing-publish',
      img: publish,
      stepTitle: t('landing-workflow-publish-step-title'),
      stepDetail: t('landing-workflow-publish-step-detail'),
    },
  ];
};
export default workflowStepsConstructor;
