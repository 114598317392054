import { KatDataTable } from '@amzn/katal-react';
import {
  cellFormatterConstructor,
  headerFormatterConstructor,
  rtlAppliedCellFormatterConstructor,
  textExpanderCellFormatterConstructor,
  textOverflowHoverCellFormatterConstructor,
} from 'src/components/common/util/KatTableColumnHelpers';
import { TranslateFn } from 'src/i18next-arb-shim/useTranslation';
import { Translation } from 'src/generated/graphql';

const headerFormatter = headerFormatterConstructor('lui-target-search-header');
const cellFormatter = cellFormatterConstructor('lui-target-search-item');
const textExpanderCellFormatter = textExpanderCellFormatterConstructor(
  'lui-target-search-item'
);
const textOverflowHoverCellFormatter =
  textOverflowHoverCellFormatterConstructor('lui-target-search-item');
const rtlAppliedCellFormatter = rtlAppliedCellFormatterConstructor(
  'lui-translation-item'
);

const formatDetailsButton = (
  rowIndex: number,
  rowData: Translation,
  previouslyRenderedElement: any,
  t: TranslateFn,
  history: any
): KatDataTable.FormatterConfig => {
  const element = previouslyRenderedElement
    ? previouslyRenderedElement
    : document.createElement('kat-link');

  // This is to update the row af an element if it got updated by the deletion of another element
  element.id = rowIndex;

  const url = `/strings/${rowData.phraseId}`;

  element.href = url;
  element.label = t('string-list-action-details');
  element.onclick = (event: React.MouseEvent) => {
    if (!url.length) {
      return;
    }
    event.preventDefault();
    history.push(url, {
      from: `/translations${history.location.search}`,
    });
  };

  return { element: element, attributes: { class: 'table-action-col' } };
};

export default (
  t: TranslateFn,
  history: any
): KatDataTable.ColumnConfiguration[] => {
  return [
    {
      label: t('column-id'),
      property: 'phraseName',
      headerFormatter: headerFormatter(
        t('column-id'),
        'phraseName',
        'target-table-id-col'
      ),
      formatter: textOverflowHoverCellFormatter(
        'phraseName',
        'target-table-id-col'
      ),
    } as KatDataTable.ColumnConfiguration,
    {
      label: t('column-source-text'),
      property: 'sourceText',
      headerFormatter: headerFormatter(
        t('column-source-text'),
        'sourceText',
        'target-table-source-text-col'
      ),
      formatter: textExpanderCellFormatter(
        'sourceText',
        'target-table-source-text-col'
      ),
    } as KatDataTable.ColumnConfiguration,
    {
      label: t('column-locale'),
      property: 'locale',
      headerFormatter: headerFormatter(
        t('column-locale'),
        'locale',
        'target-table-locale-col'
      ),
      formatter: cellFormatter('locale', 'target-table-locale-col'),
    } as KatDataTable.ColumnConfiguration,
    {
      label: t('column-translation'),
      property: 'translation',
      headerFormatter: headerFormatter(
        t('column-translation'),
        'translation',
        'target-table-translation-col'
      ),
      formatter: rtlAppliedCellFormatter(
        'translation',
        'target-table-translation-col',
        'locale'
      ),
    } as KatDataTable.ColumnConfiguration,
    {
      label: t('column-last-updated'),
      property: 'lastUpdated',
      headerFormatter: headerFormatter(
        t('column-last-updated'),
        'lastUpdated',
        'target-table-last-updated-col'
      ),
      formatter: cellFormatter(
        'lastUpdated',
        'target-table-last-updated-col',
        'auto'
      ),
    } as KatDataTable.ColumnConfiguration,
    {
      label: t('column-last-updated-by'),
      property: 'lastUpdatedBy',
      headerFormatter: headerFormatter(
        t('column-last-updated-by'),
        'lastUpdatedBy',
        'target-table-last-updated-by-col'
      ),
      formatter: cellFormatter(
        'lastUpdatedBy',
        'target-table-last-updated-by-col',
        'auto'
      ),
    } as KatDataTable.ColumnConfiguration,
    {
      label: t('column-action'),
      property: 'action',
      formatter: (
        rowIndex: number,
        rowData: any,
        previouslyRenderedElement: any
      ) =>
        formatDetailsButton(
          rowIndex,
          rowData,
          previouslyRenderedElement,
          t,
          history
        ),
    } as KatDataTable.ColumnConfiguration,
  ];
};
