import React, { useState, RefObject } from 'react';
import { KatIcon, KatInput } from '@amzn/katal-react';
import FuzzySearchInput from 'src/components/common/ui/FuzzySearchInput';
import style from './TypeaheadDropdown.module.scss';
import { Options, BasicInputProps } from './TypeaheadDropdown';

const fuseOptions = {
  keys: ['name', 'value'],
};

type Props = {
  containerRef: RefObject<HTMLDivElement>;
  options: Options;
  selected: Options;
  isExpanded: boolean;
  onClearSelection: () => void;
  onTextChange?: (text: string) => void;
  onSearchResultUpdate: (filteredOptions: any[]) => void;
  onFocus: () => void;
  close: () => void;
} & BasicInputProps;

const Header = (props: Props) => {
  // Store the last searched term use it to fill the input value when expanded
  const [searchTerm, setSearchTerm] = useState<string>();

  /**
   * If focused on some element that is not contained by the container close the dropdown
   * @param event
   */
  const onBlur = (event: KatInput.BlurEvent) => {
    const relatedTarget = (event as unknown as FocusEvent)
      .relatedTarget as HTMLElement;
    if (
      !props.containerRef.current ||
      !props.containerRef.current.contains(relatedTarget)
    ) {
      props.close();
    }
  };

  /**
   * Clear selection and stored search term
   */
  const onClearSelection = () => {
    setSearchTerm(undefined);
    props.onClearSelection();
  };

  /**
   * If any selections are made show them in the placeholder when dropdown is clsoed
   */
  const placeholder =
    props.selected.length === 0
      ? props.placeholder
        ? props.placeholder
        : 'Filter by ...'
      : props.selected.map((option) => option.name).join(', ');

  return (
    <div className={style.dropdownHeader}>
      <FuzzySearchInput
        {...props}
        placeholder={placeholder}
        items={props.options}
        onFocus={props.onFocus}
        onBlur={onBlur}
        onTextChange={props.onTextChange}
        onInput={(event) =>
          setSearchTerm((event.target as KatInput.Props).value)
        }
        fuseOptions={fuseOptions}
        value={props.isExpanded ? searchTerm : undefined}
        onSearchResultUpdate={props.onSearchResultUpdate}
      />

      {props.selected.length > 0 && (
        <KatIcon
          className={style.clear}
          name="close"
          size="small"
          onClick={onClearSelection}
        />
      )}
    </div>
  );
};
export default Header;
