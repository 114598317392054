const re = /{[^}]*}/; //Matches {} pair
export const isMessageFormatString = (str: string | undefined | null) => {
  if (str) {
    return re.test(str);
  }
  return false;
};

// get number of bracket pairs
export const getNumberOfBracketPairs = (input: string) => {
  const matches = input.match(/\{[^\{\}]*\}/g || []);
  return matches ? matches.length : 0;
};

// check if a user has added any new placeholders
export const checkForAddedPlaceholders = (
  input: string,
  currentTranslation: string
) => {
  return (
    getNumberOfBracketPairs(input) - getNumberOfBracketPairs(currentTranslation)
  );
};
