import React from 'react';
import style from 'src/components/Footer/Footer.module.scss';
import { KatBox, KatLink, KatRow } from '@amzn/katal-react';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';

type Props = {
  id: string;
};

const Footer: React.FC<Props> = (props: Props) => {
  /* React Hooks */
  const { t } = useTranslation();

  /* Gql Hooks */

  /* Handlers */

  /* Callbacks */

  /* JSX */

  return (
    <footer id={props.id}>
      <KatBox className={style.footerStyle}>
        <KatRow className={style.footerLayout}>
          <div id={`$(props.id)-support`} className={style.footerSection}>
            <h4>{t('lui-footer-support')}</h4>
            <KatRow className={style.linkRow}>
              <KatLink
                href={window.location.origin + '/landing'}
                target="_self"
              >
                <span>{t('landing-page-start-btn-label')}</span>
                <span className="exclude-dark">&nbsp;🕹️</span>
              </KatLink>
              <KatLink
                href="https://w.amazon.com/bin/view/INTech/Panther/LUI"
                target="_blank"
              >
                <span>{t('lui-doc-link')}</span>
                <span className="exclude-dark">&nbsp;📖</span>
              </KatLink>
              <KatLink
                href="https://amzn-wwc.slack.com/archives/C01BT0L6W04"
                target="_blank"
              >
                <span>{t('lui-slack-channel-link')}</span>
                <span className="exclude-dark">&nbsp;💬</span>
              </KatLink>
              <KatLink
                href="https://sim.amazon.com/issues/create?template=5bf40357-bcaa-4d81-82b2-337cf179e2e5"
                target="_blank"
              >
                <span>{t('create-sim-ticket-link')}</span>
                <span className="exclude-dark">&nbsp;🧯</span>
              </KatLink>
            </KatRow>
          </div>
        </KatRow>
      </KatBox>
    </footer>
  );
};

export default Footer;
