import { useState } from 'react';
import { useSuperuserQuery } from 'src/generated/graphql';
import { AuthenticationState } from 'src/components/auth/FederatedAuth';

type Props = {
  authState: AuthenticationState;
};
type SuperuserStatus = boolean | undefined;
function useSuperuser(props: Props) {
  const [isSuperuser, setIsSuperUser] = useState<SuperuserStatus>();

  const { data, loading } = useSuperuserQuery({
    errorPolicy: 'all',
    skip: isSuperuser !== undefined, //Only execute query once
  });

  if (data?.isSuperuser) {
    console.log('IS SUPERUSER: ', data.isSuperuser.isAuthorized);
    setIsSuperUser(!!data.isSuperuser.isAuthorized);
  }

  return { isSuperuser, isSuperUserLoading: loading };
}

export default useSuperuser;
