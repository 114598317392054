import convert from 'xml-js';
import ElementTypes2 from './inline-elements/ElementTypes2.js';
import { extractValue } from './xml-js/xmlToObject.js';
import { extractMetadata } from './xml-js/metadataConverter.js';

class Unit {
  constructor(source = '', target) {
    this.source = source;
    this.target = target;
  }

  //function stringify nested inline elements
  getVal(name) {
    return this[`${name}XmlObj`] && convert.js2xml(this[`${name}XmlObj`]);
  }
}

const replaceMDAPrefix = (val) => val.replace('mda:', '');

const xliffToJsClb = (str, options, cb) => {
  if (typeof options === 'function') {
    cb = options;
    options = {};
  }
  options = options || {};
  if (typeof str !== 'string') {
    const err = new Error('The first parameter was not a string');
    if (cb) return cb(err);
    return err;
  }

  const result = {};

  let xmlObj;
  try {
    xmlObj = convert.xml2js(str, { elementNameFn: replaceMDAPrefix });
  } catch (err) {
    if (cb) return cb(err);
    return err;
  }

  const xliffRoot = xmlObj.elements.find((ele) => ele.name === 'xliff');

  if (xliffRoot.attributes) {
    const srcLang = xliffRoot.attributes.srcLang;
    const trgLang = xliffRoot.attributes.trgLang;

    result.sourceLanguage = srcLang;
    result.targetLanguage = trgLang;
    if (!result.targetLanguage) delete result.targetLanguage;

    xliffRoot.elements = xliffRoot.elements.filter(
      (child) => child.type !== 'comment'
    );
    result.resources = xliffRoot.elements.reduce((resources, file) => {
      const namespace = options.namespace || file.attributes.id;

      const initValues = { source: '', target: '' };
      if (!result.targetLanguage) delete initValues.target;

      // namespace
      file.elements = file.elements || [];
      file.elements = file.elements.filter((child) => child.type !== 'comment');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resources[namespace] = createUnits(file, initValues, options);

      return resources;
    }, {});
  }

  if (cb) return cb(null, result);
  return result;
};

function createUnit(_unit, initValues, options) {
  return _unit.elements.reduce((unit, _unitChild) => {
    // metadata,
    if (_unitChild.name === 'metadata') {
      unit[_unitChild.name] = extractMetadata(_unitChild);
    }
    // originalData. This is not going to be editable or displayed by user, so saving as xml obj for easy serialization
    if (_unitChild.name === 'originalData') {
      unit[_unitChild.name] = JSON.parse(JSON.stringify(_unitChild));
    }
    //source, target, note
    if (_unitChild.name === 'segment') {
      _unitChild.elements.forEach((element) => {
        switch (element.name) {
          case 'source':
          case 'target':
          case 'note':
            unit[element.name] = extractValue(element.elements, ElementTypes2);
            //make xml object deserialized by xml-js non enumerable
            Object.defineProperty(unit, `${element.name}XmlObj`, {
              enumerable: false,
              writable: true,
              value: JSON.parse(JSON.stringify(element)),
            });
            break;
        }
      });
    }
    return unit;
  }, new Unit());
}

function createUnits(parent, initValues, options) {
  return parent.elements.reduce((file, unit) => {
    const key = unit.attributes.id;
    const additionalAttributes = unit.attributes;
    delete additionalAttributes.id;

    switch (unit.name) {
      case 'unit':
        file[key] = createUnit(unit, initValues, options);
        if (Object.keys(additionalAttributes).length) {
          Object.assign(file[key], { additionalAttributes });
        }
        return file;

      case 'group':
        file[key] = { groupUnits: createUnits(unit, initValues, options) };
        if (Object.keys(additionalAttributes).length) {
          Object.assign(file[key], { additionalAttributes });
        }
        return file;

      default:
        return file;
    }
  }, {});
}

export default function xliffToJs(str, options, cb) {
  if (!cb && options === undefined) {
    return new Promise((resolve, reject) =>
      xliffToJsClb(str, options, (err, ret) =>
        err ? reject(err) : resolve(ret)
      )
    );
  }
  if (!cb && typeof options !== 'function') {
    return new Promise((resolve, reject) =>
      xliffToJsClb(str, options, (err, ret) =>
        err ? reject(err) : resolve(ret)
      )
    );
  }
  xliffToJsClb(str, options, cb);
}
