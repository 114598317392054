import { KatFlashbar, KatRow, KatSpinner } from '@amzn/katal-react';
import React, { useEffect } from 'react';
import initialMetricsPublisher from 'src/metrics';
import TypeaheadList from 'src/components/common/ui/TypeaheadList/TypeaheadList';
import { Mode } from 'src/components/common/ui/TypeaheadList/TypeaheadList';
import { useGetPackageTypesQuery } from 'src/generated/graphql';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import { getClientNameLabel } from 'src/components/common/util/PackageHelpers';

type Props = {
  queryLimit?: number;
  mode: Mode;
  typeaheadProps: any;
  i18nFamilies?: (string | number)[];
  owners?: (string | number)[];
};

const DEFAULT_LIMIT = 20;
const DEFAULT_SCROLL_INCREASE = 10;

//AppSync has a bug that doesn't take default query params defined in schema, so need to do it here
const packageTypeQueryParams = {
  nextToken: '',
  limit: DEFAULT_LIMIT,
  packageType: '',
  i18nFamilies: '[]',
  owners: '[]',
};

let previousDataCount = 0;

const metricPublisher: any =
  initialMetricsPublisher.newChildActionPublisherForMethod(
    'PackageTypeTypeaheadList'
  );

const PackageTypeTypeaheadList = (props: Props) => {
  const { t } = useTranslation();

  const {
    data: packageTypeData,
    loading: packageTypeLoading,
    error: packageTypeError,
    refetch: refetchPackageType,
  } = useGetPackageTypesQuery({
    variables: { params: packageTypeQueryParams },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    packageTypeQueryParams.i18nFamilies = JSON.stringify(props.i18nFamilies);
    refetchPackageType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.i18nFamilies]);

  useEffect(() => {
    packageTypeQueryParams.owners = JSON.stringify(props.owners);
    refetchPackageType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.owners]);

  const onTextChange = (text: string) => {
    if (packageTypeLoading) return;
    packageTypeQueryParams.limit = DEFAULT_LIMIT;
    packageTypeQueryParams.packageType = text;
    refetchPackageType();
  };

  const onScrollDown = () => {
    if (packageTypeLoading || !packageTypeData) return;
    if (packageTypeData.packageTypes.length == previousDataCount) return;

    previousDataCount = packageTypeData.packageTypes.length;

    //no more results to get
    if (packageTypeData && packageTypeData.packageTypes.length < DEFAULT_LIMIT)
      return;

    packageTypeQueryParams.limit += DEFAULT_SCROLL_INCREASE;
    refetchPackageType();
  };

  const selectionBody = () => {
    if (packageTypeError) {
      metricPublisher.publishCounterMonitor('error-count', 1);
      metricPublisher.publishStringTruncate(
        'error-log',
        `${packageTypeError?.message}`
      );
    }

    if (packageTypeData === undefined) {
      return (
        <KatRow className="typeahead-list-spinner">
          <KatSpinner id={'spinner'} />
        </KatRow>
      );
    }

    const packageTypesData = packageTypeData
      ? packageTypeData.packageTypes?.map((packageType) => {
          return {
            id: packageType?.packageType,
            name: getClientNameLabel(packageType?.packageType, t),
          };
        })
      : [];

    if (
      !packageTypeLoading &&
      packageTypesData.length == 0 &&
      packageTypeQueryParams.packageType == ''
    ) {
      return (
        <KatFlashbar header={t('add-filter-modal-error-no-client-name')} />
      );
    }

    return (
      <TypeaheadList
        mode={props.mode}
        typeaheadProps={{
          ...props.typeaheadProps,
          onTextChange: onTextChange,
          onScrollDown: onScrollDown,
          isLoading: packageTypeLoading,
          isSearching: packageTypeQueryParams.packageType !== '',
          placeholder: t('add-filter-modal-search-for-client-name'),
          data: packageTypesData,
        }}
      />
    );
  };

  return <>{selectionBody()}</>;
};

export default PackageTypeTypeaheadList;
