import { GetLocalizedPackagesDocument } from 'src/generated/graphql';
import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';

export const localizedPackagesMock = (async () => {
  return {
    request: {
      query: GetLocalizedPackagesDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        localizedPackages: [
          { id: 0, name: 'packages-test-0' },
          { id: 1, name: 'packages-test-1' },
          { id: 2, name: 'packages-test-2' },
          { id: 3, name: 'packages-test-3' },
          { id: 4, name: 'packages-test-4' },
        ],
      },
    },
  };
})();
