import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';
import katalReady from '@amzn/katal-components-ready';
import { GQLWildcardLink } from './api/mocks/mocks';
import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './styles/index.scss';
import { whenDOMContentLoaded } from './whenDOMContentLoaded';
import { BrowserRouter as Router } from 'react-router-dom';
import loadPolyfill from './polyfills/intlDisplaynamesPolyfill';
import { ApolloLink, ApolloProvider } from '@apollo/client';
import { MockedProvider } from '@apollo/client/testing';
import { getApolloClient } from 'src/api/client';
import ArbI18nextWrapper from './i18next-arb-shim/ArbI18nextWrapper';

declare const MOCK_GRAPHQL: boolean;
let mockLink: ApolloLink;
const luiApp = async () => {
  // Wait for Intl.Displaynames polyfill to load
  await loadPolyfill();
  mockLink = await GQLWildcardLink();

  const app = (
    <Router>
      <ArbI18nextWrapper>
        <App />
      </ArbI18nextWrapper>
    </Router>
  );
  const apolloApp = MOCK_GRAPHQL ? (
    <MockedProvider link={mockLink} addTypename={false}>
      {app}
    </MockedProvider>
  ) : (
    <ApolloProvider client={getApolloClient()}>{app}</ApolloProvider>
  );

  ReactDOM.render(
    <AppInitWrapper>
      <Suspense fallback={<KatSpinner size="large" />}>{apolloApp}</Suspense>
    </AppInitWrapper>,
    document.getElementById('root')
  );
};
whenDOMContentLoaded(() => katalReady(luiApp));
export const getMockLink = () => {
  return mockLink;
};
