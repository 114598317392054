import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import rootMetricPublisher, { METRIC } from 'src/metrics';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import {
  LUIError,
  unwrapGqlError,
} from 'src/components/common/ui/ErrorHandler/ErrorHandlerHelpers';
import { FetchResult } from 'apollo-link';

const metricPublisher: KatalMetricsPublisher =
  rootMetricPublisher.newChildActionPublisherForMethod('AppSyncClient');

//using https://docs.aws.amazon.com/apigateway/api-reference/handling-errors/ for now
const retryCodes = new Set([
  LUIError.NETWORK_ERROR,
  LUIError.TOO_MANY_REQUESTS,
  LUIError.INTERNAL_ERROR,
  LUIError.BAD_GATEWAY,
  LUIError.SERVICE_UNAVAILABLE,
  LUIError.REQUEST_TIMEOUT,
]);

const appSyncUrl = '{{GraphQLUrl}}';

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem('Feline-jwtToken') || null,
    },
  }));

  return forward(operation);
});

const raiseGraphQLErrorForRetry = new ApolloLink((operation, forward) => {
  return forward(operation).map((data: FetchResult) => {
    if (
      data &&
      data.errors &&
      data.errors.length > 0 &&
      'errorType' in data.errors[0]
    ) {
      const apiGatewayResponseCode = (data.errors[0] as any)['errorType'];
      if (retryCodes.has(apiGatewayResponseCode))
        throw new Error('GraphQL Operational Error');
    }
    return data;
  });
});

const retryLink = new RetryLink({
  delay: {
    //milliseconds to wait before attempting the first retry
    initial: 300,
    //milliseconds that the link should wait for any retry
    max: Infinity,
    jitter: true,
  },
  attempts: {
    //number of tries including the initial request
    max: 2,
    //retry if error is truthy
    retryIf: (error, _operation) => !!error,
  },
});

const errorMetricLink = onError((apolloError) => {
  if (apolloError) {
    const { errorType, rawErrorMessage } = unwrapGqlError(apolloError);
    metricPublisher.publishCounterMonitor(
      METRIC.PRE_RETRY_ERROR_CODE + errorType,
      1
    );
    metricPublisher.publishStringTruncate(
      METRIC.PRE_RETRY_ERROR_MSG,
      rawErrorMessage
    );
  }
});

const httpLink = new HttpLink({
  uri: appSyncUrl,
});

export const getApolloClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      // errorLink to be added later
      retryLink,
      raiseGraphQLErrorForRetry,
      errorMetricLink,
      authMiddleware,
      httpLink,
    ]),
  });
};
