import React from 'react';
import CompactTypeaheadList from './CompactTypeaheadList';
import FullTypeaheadList from './FullTypeaheadList';

export enum Mode {
  Compact,
  Full,
}

type Props = {
  typeaheadProps: any;
  mode: Mode;
};

const TypeaheadList: React.FC<Props> = (props: Props) => {
  const renderByMode = (mode: Mode) => {
    switch (mode) {
      case Mode.Compact:
        return <CompactTypeaheadList typeaheadProps={props.typeaheadProps} />;

      case Mode.Full:
        return <FullTypeaheadList typeaheadProps={props.typeaheadProps} />;
    }
  };

  return <div>{renderByMode(props.mode)}</div>;
};

export default TypeaheadList;
