import React, { useState, useRef } from 'react';
import { uniqBy, concat, includes, indexOf, map, filter } from 'lodash';
import Header from './Header';
import Options from './Options';
import Container from 'src/components/common/ui/TypeaheadDropdown/Container';
import { KatInput } from '@amzn/katal-react';

export type Option = {
  name: string;
  value: string;
};

export type Options = Option[];

export type BasicInputProps = {
  label?: string;
  placeholder?: string;
  tooltipText?: string;
  state?: KatInput.State;
  stateEmphasis?: string;
  stateLabel?: string;
};

type Props = {
  options: Options;
  onSelectionChange?: (selected: Option[] | Options) => void;
  onTextChange?: (text: string) => void;
  onScrollDown?: () => void;
  isLoading?: boolean;
} & BasicInputProps;

export type DropdownState = {
  filtered: Options;
  selected: Options;
};

const TypeaheadDropdown = (props: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number>();
  /**
   * The filtered and selected options needs to be bundled so any update that happens to one of them can be reacted to and update the UI
   */
  const [state, setState] = useState<DropdownState>({
    filtered: props.options,
    selected: [],
  });

  const open = () => {
    setIsExpanded(true);
  };

  const close = () => {
    setIsExpanded(false);
    setFocusedIndex(undefined);
  };

  /**
   * Clear selected items
   */
  const onClearSelections = () => {
    setState({
      filtered: state.filtered,
      selected: [],
    });
    if (props.onSelectionChange) {
      props.onSelectionChange([]);
    }
  };

  /**
   * Merge selected and filtered options to present in the dropdown
   * @param filteredOptions
   */
  const onSearchResultUpdate = (filteredOptions: any[]) => {
    setState({
      filtered: !filteredOptions
        ? []
        : uniqBy(concat(state.selected, filteredOptions), 'value'),
      selected: state.selected,
    });
    setFocusedIndex(undefined);
  };

  /**
   * Toggle option selection and set the focused item index to the option
   * @param option
   */
  const toggleOptionSelection = (option: Option) => {
    setFocusedIndex(indexOf(state.filtered, option));

    let selected = [];

    //we're deselecting
    if (includes(map(state.selected, 'value'), option.value)) {
      selected = filter(
        state.selected,
        (selected) => selected.value !== option.value
      );
    }
    //we're selecting
    else {
      selected = concat(state.selected, option);
    }

    setState({
      filtered: state.filtered,
      selected: selected,
    });

    if (props.onSelectionChange) {
      props.onSelectionChange(selected);
    }
  };

  return (
    <Container
      ref={containerRef}
      options={state.filtered}
      isExpanded={isExpanded}
      focusedIndex={focusedIndex}
      setFocusedIndex={setFocusedIndex}
      close={close}
      onSelectOption={toggleOptionSelection}
    >
      <Header
        containerRef={containerRef}
        {...props}
        options={props.options}
        isExpanded={isExpanded}
        selected={state.selected}
        close={close}
        onTextChange={props.onTextChange}
        onFocus={open}
        onClearSelection={onClearSelections}
        onSearchResultUpdate={onSearchResultUpdate}
      />

      <Options
        dropdownState={state}
        focusedIndex={focusedIndex}
        onScrollDown={props.onScrollDown}
        onSelectOption={toggleOptionSelection}
        isLoading={props.isLoading}
      />
    </Container>
  );
};

export default TypeaheadDropdown;
