import { Token } from '@amzn/awsui-components-react/polaris/property-filter/interfaces';
import { useEffect } from 'react';
import {
  Criteria,
  FilterCategory,
} from 'src/components/Preferences/FilterModal/FilterModal';
import { FilterField } from 'src/components/Search/SearchConstants';

const getMissingIds = (cache: Map<any, any>, ids: string) => {
  const idArr = JSON.parse(ids);
  if (cache.size == 0 || idArr.length == 0) return [];
  return idArr.filter((id: string | number) => !cache.has(id));
};

const getCriteria = (cache: Map<any, any>, ids: string) => {
  const idArr = JSON.parse(ids);
  if (cache.size == 0 || idArr.length == 0) return [];
  const criteria: Criteria[] = [];
  idArr.forEach((id: any) => {
    if (cache.has(id)) {
      const name = cache.get(id);
      criteria.push({ id, name } as Criteria);
    }
  });
  return criteria;
};

const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

const updateFilterFromSearchToken = (
  searchToken: Token,
  currentFilter: FilterCategory
): FilterCategory => {
  const filter: FilterCategory = currentFilter;
  const id = searchToken.propertyKey ?? FilterField.DEFAULT_SEARCH;
  switch (id) {
    case FilterField.SOURCE_TEXT:
      filter.sourceTexts = pushIfAbsent(searchToken, filter.sourceTexts);
      return filter;
    case FilterField.PSMS_ID:
      filter.psmsIds = pushIfAbsent(searchToken, filter.psmsIds);
      return filter;
    case FilterField.STRING_ID:
      filter.stringIds = pushIfAbsent(searchToken, filter.stringIds);
      return filter;
    case FilterField.TARGET_LOCALE:
      filter.locales = pushIfAbsent(searchToken, filter.locales);
      return filter;
    case FilterField.TRANSLATION:
      filter.translations = [
        { id: FilterField.TRANSLATION, name: searchToken.value } as Criteria,
      ];
      return filter;
    default:
      throw new Error(
        `Unknown search property key: ${searchToken.propertyKey}`
      );
  }
};

const pushIfAbsent = (
  token: Token,
  filterCriteria?: Criteria[]
): Criteria[] => {
  const criterion: Criteria = {
    id: token.propertyKey ?? FilterField.DEFAULT_SEARCH,
    name: token.value,
  };
  if (!filterCriteria) {
    return [criterion];
  }

  let containsValue = false;
  filterCriteria.forEach((item: Criteria) => {
    if (item.name === criterion.name) {
      containsValue = true;
    }
  });

  if (!containsValue) {
    filterCriteria.push(criterion);
  }

  return filterCriteria;
};

export {
  getMissingIds,
  getCriteria,
  useDocumentTitle,
  updateFilterFromSearchToken,
};
