import { KatFlashbar, KatRow, KatSpinner } from '@amzn/katal-react';
import React, { useEffect } from 'react';
import initialMetricsPublisher from 'src/metrics';
import TypeaheadList from 'src/components/common/ui/TypeaheadList/TypeaheadList';
import { Mode } from 'src/components/common/ui/TypeaheadList/TypeaheadList';
import { Owner, useGetOwnersQuery } from 'src/generated/graphql';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';

type Props = {
  queryLimit?: number;
  mode: Mode;
  typeaheadProps: any;
  i18nFamilies?: (string | number)[];
};

const DEFAULT_LIMIT = 20;
const DEFAULT_SCROLL_INCREASE = 10;

//AppSync has a bug that doesn't take default query params defined in schema, so need to do it here
const ownerQueryParams = {
  limit: DEFAULT_LIMIT,
  name: '',
  offset: 0,
  i18nFamilies: '[]',
};

let previousDataCount = 0;

const metricPublisher: any =
  initialMetricsPublisher.newChildActionPublisherForMethod(
    'OwnerTypeaheadList'
  );

const OwnerTypeaheadList = (props: Props) => {
  const { t } = useTranslation();

  const {
    data: ownerData,
    loading: ownerLoading,
    error: ownerError,
    refetch: refetchOwner,
  } = useGetOwnersQuery({
    variables: { params: ownerQueryParams },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    ownerQueryParams.i18nFamilies = JSON.stringify(props.i18nFamilies);
    refetchOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.i18nFamilies]);

  const onTextChange = (text: string) => {
    if (ownerLoading) return;
    ownerQueryParams.limit = DEFAULT_LIMIT;
    ownerQueryParams.name = text;
    refetchOwner();
  };

  const onScrollDown = () => {
    if (ownerLoading || !ownerData) return;
    if (ownerData.owners.length == previousDataCount) return;

    previousDataCount = ownerData.owners.length;

    //no more results to get
    if (ownerData && ownerData.owners.length < DEFAULT_LIMIT) return;

    ownerQueryParams.limit += DEFAULT_SCROLL_INCREASE;
    refetchOwner();
  };

  const selectionBody = () => {
    if (ownerError) {
      metricPublisher.publishCounterMonitor('error-count', 1);
      metricPublisher.publishStringTruncate(
        'error-log',
        `${ownerError?.message}`
      );
    }

    if (ownerData === undefined) {
      return (
        <KatRow className="typeahead-list-spinner">
          <KatSpinner id={'spinner'} />
        </KatRow>
      );
    }

    const owners = ownerData ? ownerData.owners : [];

    if (!ownerLoading && owners.length == 0 && ownerQueryParams.name == '') {
      return <KatFlashbar header={t('add-filter-modal-error-no-owners')} />;
    }

    return (
      <TypeaheadList
        mode={props.mode}
        typeaheadProps={{
          ...props.typeaheadProps,
          onTextChange: onTextChange,
          onScrollDown: onScrollDown,
          isLoading: ownerLoading,
          isSearching: ownerQueryParams.name !== '',
          placeholder: t('add-filter-modal-search-for-owner'),
          data: owners as Owner[],
        }}
      />
    );
  };

  return <>{selectionBody()}</>;
};

export default OwnerTypeaheadList;
