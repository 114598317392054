import React from 'react';
import TypeaheadDropdown from 'src/components/common/ui/TypeaheadDropdown/TypeaheadDropdown';

type Props = {
  typeaheadProps: any;
};

const CompactTypeaheadList: React.FC<Props> = (props: Props) => {
  const data =
    props.typeaheadProps?.data.map((ele: any) => {
      return {
        name: ele.name,
        value: JSON.stringify({ id: ele.id, name: ele.name }),
      };
    }) || [];

  return <TypeaheadDropdown {...props.typeaheadProps} options={data} />;
};

export default CompactTypeaheadList;
