import React, { useEffect, useRef, useState } from 'react';
import { Localization } from 'src/generated/graphql';
import ReactSlidingPane from 'react-sliding-pane/dist/react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import {
  KatAccordion,
  KatAccordionItem,
  KatButton,
  KatDropdown,
  KatBox,
  KatLabel,
  KatCheckbox,
  KatIcon,
  KatSpinner,
  KatTextarea,
} from '@amzn/katal-react';
import { cloneDeep, debounce, findKey, isEmpty } from 'lodash';
import './EditPane.scss';
import { isRTL } from 'src/i18n/locales';
import MFEditor from 'src/components/MessageFormatEditor/MFEditor';
import InputState from 'src/components/MessageFormatEditor/MFTextarea/UserInputValidationStatusEnum';
import {
  getPreview,
  Preview,
  UnitKey,
} from 'src/components/common/util/XLIFFHelpers';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import rootMetricPublisher from 'src/metrics';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';

type Props = {
  id: string;
  source: string;
  sourceLocale: string;
  show: boolean;
  isLoading: boolean;
  setShow: (state?: boolean) => void;
  onSubmit: (newLocalizationBlob: string, isMF: boolean) => void;
  data: Localization[];
  format: string | null | undefined;
};
type InputStateMap = {
  [id: string]: InputState;
};
const metricPublisher: KatalMetricsPublisher =
  rootMetricPublisher.newChildActionPublisherForMethod('EditPane');
const EditPane: React.FC<Props> = (props: Props) => {
  /* React Hooks */
  const { t, i18n } = useTranslation();
  const overwriteOptions = [
    {
      name: t('edit-pane-dropdown-option-quality'),
      value: 'QUALITY',
    },
    {
      name: t('edit-pane-dropdown-option-message-format'),
      value: 'MESSAGE_FORMAT',
    },
    {
      name: t('edit-pane-dropdown-option-legal'),
      value: 'LEGAL',
    },
    {
      name: t('edit-pane-dropdown-option-store'),
      value: 'STORE_SPECIFIC',
    },
    {
      name: t('edit-pane-dropdown-option-other'),
      value: 'OTHER',
    },
  ];
  const [localizations, setLocalizations] = useState(cloneDeep(props.data));
  const [sourcePreview, setSourcePreview] = useState<Preview[]>([
    { metadataText: '', content: '' },
  ]);
  const [originalTransPreview, setOriginalTransPreview] = useState<Preview[][]>(
    []
  );
  const [isFormValid, setIsFormValid] = useState(true);
  const [hasWarning, setHasWarning] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const textAreaRefs = useRef<any[]>([]);
  const inputStateRefMap = useRef<InputStateMap>({});
  const isMFRef = useRef(false);
  useEffect(() => {
    setIsAgreed(false);
  }, [props.show]);
  useEffect(() => {
    setLocalizations(cloneDeep(props.data));
  }, [props.data]);
  useEffect(() => {
    async function someCalculation() {
      const { previews, isMF } = await getPreview(
        props.source,
        props.format,
        UnitKey.SOURCE
      );
      setSourcePreview(previews);
      isMFRef.current = isMF;
      const newTargetPreviews: Preview[][] = [];
      await props.data?.reduce(async (promise, localization) => {
        await promise;
        const { previews } = await getPreview(
          localization.translation || '',
          props.format,
          UnitKey.TARGET
        );
        newTargetPreviews.push(previews);
      }, Promise.resolve());
      setOriginalTransPreview(newTargetPreviews);
    }
    someCalculation();
  }, [props.source, props.format, props.data]);
  useEffect(() => {
    //Validate new edit (This loop can be removed once fully migrated to xliff)
    if (props.format?.toLowerCase() === 'xliff') {
      for (const item of localizations) {
        const hasWarning = isAgreed || isEmpty(item.translation);
        setHasWarning(hasWarning);
        const isValid =
          isAgreed ||
          (!isEmpty(item.dropDownReasons) &&
            !isEmpty(item.explanation) &&
            !isEmpty(item.translation));
        setIsFormValid(isValid);
        if (!isValid) return;
      }
    } else {
      for (const item of localizations) {
        const isValid =
          !isEmpty(item.dropDownReasons) &&
          !isEmpty(item.explanation) &&
          !isEmpty(item.translation);
        setIsFormValid(isValid);
        if (!isValid) return;
      }
    }

    //Is there any input in error state
    if (
      findKey(
        inputStateRefMap.current,
        (inputState) => inputState == InputState.Error
      )
    ) {
      setIsFormValid(false);
      return;
    }

    //Is there any input in warning state
    findKey(
      inputStateRefMap.current,
      (inputState) => inputState == InputState.Warning
    )
      ? setHasWarning(true)
      : setHasWarning(false);

    //Validate disclaimer is agreed upon
    if (hasWarning && !isAgreed) {
      setIsFormValid(false);
      return;
    }

    //Form Valid
    setIsFormValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizations, isAgreed, hasWarning]);

  /* Gql Hooks */
  /* Handlers */
  const afterOpenHandler = () => {
    textAreaRefs.current.map((ref) => {
      if (ref) {
        ref.setAttribute('dir', isRTL(ref.locale || '') ? 'rtl' : 'ltr');
      }
    });
  };

  const validateKatInputField = (input: string | undefined | null) => {
    if (input && input.trim().length !== 0) {
      return;
    }
    const state: KatTextarea.State = 'error';
    const stateEmphasis = t('edit-pane-invalid-input-emphasis');
    const stateLabel = t('edit-pane-empty-input-label');
    return { state, stateEmphasis, stateLabel };
  };

  const validateKatOverwriteDropdown = (input: string[] | undefined | null) => {
    if (input && input.length !== 0) {
      return;
    }
    const state: KatDropdown.State = 'error';
    const stateEmphasis = t('edit-pane-invalid-input-emphasis');
    const stateLabel = t('edit-pane-invalid-reason-dropdown-emphasis');
    return { state, stateEmphasis, stateLabel };
  };

  const validateKatOverwriteExplanationInputField = (
    input: string | undefined | null
  ) => {
    if (input && input.trim().length !== 0) {
      return;
    }
    const state: KatTextarea.State = 'error';
    const stateEmphasis = t('edit-pane-invalid-input-emphasis');
    const stateLabel = t('edit-pane-invalid-reason-description-emphasis');
    return { state, stateEmphasis, stateLabel };
  };

  const handleKatInput = (item: Localization, index: number) => {
    return debounce((e: KatTextarea.InputEvent) => {
      const newLocalizations = [...localizations];
      newLocalizations[index].translation = (
        e.target as KatTextarea.Props
      ).value;
      setLocalizations(newLocalizations);
    }, 400);
  };

  const handleKatOverwriteReasonsInput = (
    item: Localization,
    index: number
  ) => {
    return debounce((e: KatDropdown.ChangeEvent) => {
      const newLocalizations = [...localizations];
      newLocalizations[index].dropDownReasons = (
        e.target as KatDropdown.Props
      ).values;
      setLocalizations(newLocalizations);
    }, 400);
  };

  const handleKatOverwriteExplanationInput = (
    item: Localization,
    index: number
  ) => {
    return debounce((e: KatTextarea.InputEvent) => {
      const newLocalizations = [...localizations];
      newLocalizations[index].explanation = (
        e.target as KatTextarea.Props
      ).value;
      setLocalizations(newLocalizations);
    }, 400);
  };

  const updateInputStateRefMap = (unitId: string, inputState: InputState) => {
    const pair = { [unitId]: inputState };
    inputStateRefMap.current = { ...inputStateRefMap.current, ...pair };
  };

  const handleInput = (item: Localization, index: number) => {
    return (newTranslation: string, unitId: string, inputState: InputState) => {
      const newLocalizations = [...localizations];
      newLocalizations[index].translation = newTranslation;
      setLocalizations(newLocalizations);
      updateInputStateRefMap(unitId, inputState);
    };
  };

  const handleAgreement = (e: KatCheckbox.ChangeEvent) => {
    const { checked } = e.detail;
    setIsAgreed(checked);
  };

  const requestBody = Object.assign(
    {},
    ...localizations.map((item) => {
      if (item.locale) {
        return {
          [item.locale]: {
            content: item.translation,
            justification: {
              reasons: item.dropDownReasons,
              details: item.explanation,
            },
          },
        };
      }
    })
  );
  const handleSubmit = () => {
    metricPublisher.publishCounterMonitor('total-overwrite', 1);
    if (isMFRef.current)
      metricPublisher.publishCounterMonitor('MF-overwrite', 1);
    props.onSubmit(JSON.stringify(requestBody), isMFRef.current);
  };

  const languageDir = isRTL(i18n.language) ? 'rtl' : 'ltr';

  /* JSX */
  return (
    <ReactSlidingPane
      title={t('edit-pane-header', { count: props.data.length })}
      isOpen={props.show}
      from={isRTL(i18n.language) ? 'left' : 'right'}
      onRequestClose={() => props.setShow(false)}
      onAfterOpen={afterOpenHandler}
      width="40%"
      closeIcon={
        <KatIcon name="close" size="large" className="pane-close-icon" />
      }
      className="lui-pane"
    >
      {props.isLoading ? (
        <KatSpinner />
      ) : (
        <>
          {' '}
          <KatAccordion>
            <KatAccordionItem
              key={props.source}
              expanded={false}
              remainsExpanded={true}
            >
              <span slot="label">
                {t('string-field-source')} ({props.sourceLocale})
              </span>
              {sourcePreview.map(({ metadataText, content }, index) => (
                <div
                  dir={isRTL(props.sourceLocale || '') ? 'rtl' : 'ltr'}
                  key={index}
                  className="preview-container"
                >
                  <h5>{metadataText}</h5>
                  <span>{content}</span>
                </div>
              ))}
            </KatAccordionItem>
            {localizations.map(
              (item, index) =>
                item?.canEdit && (
                  <KatAccordionItem
                    key={String(item.locale)}
                    expanded={index === 0}
                    remainsExpanded={true}
                  >
                    <span slot="label">{item.locale}</span>
                    <div dir={isRTL(item.locale || '') ? 'rtl' : 'ltr'}>
                      {originalTransPreview[index]?.map(
                        ({ metadataText, content }, idx) => (
                          <div
                            key={idx}
                            className="preview-container"
                            dir={isRTL(item.locale || '') ? 'rtl' : 'ltr'}
                          >
                            {props.format?.toLowerCase() !== 'xliff' && (
                              <KatTextarea
                                ref={(elem) => {
                                  if (elem) {
                                    textAreaRefs.current[index] = elem;
                                    textAreaRefs.current[index].locale =
                                      item.locale;
                                  }
                                }}
                                disabled
                                value={content}
                                label={t('edit-pane-current-translation-label')}
                                onInput={handleKatInput(item, index)}
                                {...validateKatInputField(content)}
                              />
                            )}
                          </div>
                        )
                      )}
                      {props.format?.toLowerCase() === 'xliff' ? (
                        <MFEditor
                          id={item.locale + 'editor'}
                          localization={item}
                          onInput={handleInput(item, index)}
                        />
                      ) : (
                        <KatTextarea
                          ref={(elem) => {
                            if (elem) {
                              textAreaRefs.current[index] = elem;
                              textAreaRefs.current[index].locale = item.locale;
                            }
                          }}
                          value={item.translation || ''}
                          label={t('edit-pane-input-label')}
                          onInput={handleKatInput(item, index)}
                          {...validateKatInputField(item.translation)}
                        />
                      )}
                    </div>
                    <div className="preview-container" dir={languageDir}>
                      <KatLabel
                        text={t('edit-pane-reason-label')}
                        className="boxlabel"
                      />
                      <KatBox variant="nordic" className="box">
                        <KatDropdown
                          className="dropdown"
                          options={overwriteOptions}
                          label={t('edit-pane-reason-category-label')}
                          tooltipText={t('edit-pane-reason-category-tooltip')}
                          multiple
                          multipleHideSelect
                          onChange={handleKatOverwriteReasonsInput(item, index)}
                          {...validateKatOverwriteDropdown(
                            item.dropDownReasons
                          )}
                        />
                        <KatTextarea
                          className="description"
                          label={t('edit-pane-reason-description-label')}
                          tooltipText={t(
                            'edit-pane-reason-description-tooltip'
                          )}
                          placeholder={t(
                            'edit-pane-reason-description-placeholder'
                          )}
                          onInput={handleKatOverwriteExplanationInput(
                            item,
                            index
                          )}
                          {...validateKatOverwriteExplanationInputField(
                            item.explanation
                          )}
                        />
                      </KatBox>
                    </div>
                  </KatAccordionItem>
                )
            )}
          </KatAccordion>
          <div className="footer">
            {hasWarning && (
              <>
                <span
                  className="disclaimer"
                  id={'${props.id}-disclaimer-detail'}
                >
                  {t('edit-pane-removed-protected-content-reminder')}
                </span>
                <KatCheckbox
                  className="checkbox"
                  id={'${props.id}-disclaimer-checkbox'}
                  label={t('edit-pane-disclaimer-label')}
                  checked={isAgreed}
                  onChange={handleAgreement}
                />
              </>
            )}
            <KatButton
              id={'${props.id}-submit-btn'}
              className="submit-btn"
              label={t('edit-pane-submit-label')}
              disabled={!isFormValid || props.isLoading}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
    </ReactSlidingPane>
  );
};

export default EditPane;
