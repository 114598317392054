import { useEffect, useState } from 'react';
import { CognitoAuth, CognitoAuthSession } from 'amazon-cognito-auth-js';
import { getStage, Stage } from 'src/components/common/util/StageHelpers';
import {
  getAuthConfigByStage,
  AuthConfig,
} from 'src/components/common/util/AuthConfigHelpers';
import {
  savedOriginalUserLocationKey,
  readFromLocal,
  writeToLocal,
} from 'src/api/preferencesClient';
import { useHistory } from 'react-router';
import { useSendMessageLazyQuery } from 'src/generated/graphql';
import { getPostAuthPermissionsParams } from 'src/components/auth/PostAuth';

export enum AuthenticationState {
  LOGIN_REQUIRED,
  LOGGING_IN,
  LOGGED_IN,
}

function useFederatedAuth() {
  const [authState, setAuthState] = useState(AuthenticationState.LOGGING_IN);
  const [userId, setUserId] = useState(null);
  const history = useHistory();
  const [sendMessage] = useSendMessageLazyQuery();

  /* Helper functions */
  const resetLoginState = (authState: AuthenticationState) => {
    setUserId(null);
    setAuthState(authState);
  };

  let timer: number;
  const clearAutologOutTimer = () => {
    timer && window.clearTimeout(timer);
  };
  const autoLogOutAfter = (millies: number) => {
    console.info(`Session will expire in ${Math.floor(millies / 1000)}s`);
    clearAutologOutTimer(); //Clears the old timer
    timer = window.setTimeout(() => {
      resetLoginState(AuthenticationState.LOGIN_REQUIRED);
    }, millies);
  };

  // This code removes the "?code=..." from the URL.
  // It is because the grant code is not reusable. Sometimes
  // the SDK will report weird messages because of using old grant code.
  const updateHistory = () => {
    // Replace the location because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    // And replacing sets the location to where user originally wanted to go
    if (history.length > 0) {
      const originalLocation = readFromLocal(savedOriginalUserLocationKey) || {
        pathname: '/',
      };
      // Using react-router's history will make sure Switch is in sync with location changes
      history.replace(originalLocation);
    }
  };

  /* Cognito Auth Client*/
  const stage: Stage = getStage();
  const authConfig: AuthConfig = getAuthConfigByStage(stage);
  const auth: CognitoAuth = new CognitoAuth(authConfig);

  auth.useCodeGrantFlow();
  auth.userhandler = {
    onFailure: (err: any) => {
      console.error(`Cognito ${stage} onFailure`, err);
      updateHistory();
      resetLoginState(AuthenticationState.LOGIN_REQUIRED);
    },
    onSuccess: (result: CognitoAuthSession) => {
      const token = result.getIdToken();
      const millies = token.getExpiration() * 1000 - Date.now();

      console.info(`Cognito ${stage} onSuccess`, result);

      // Send post authentication message
      const userId =
        (result.getIdToken().decodePayload() as any).identities[0]?.userId ||
        '';
      sendMessage({
        variables: { params: getPostAuthPermissionsParams(userId) },
      });

      updateHistory();
      localStorage.setItem('Feline-jwtToken', token.getJwtToken());
      setUserId(userId);
      setAuthState(AuthenticationState.LOGGED_IN);
      autoLogOutAfter(millies);
    },
  };

  const login = (auth: CognitoAuth) => {
    const { href } = window.location;
    const session = auth.getSignInUserSession();
    const payload = session.getIdToken().decodePayload() as any;
    console.debug('payload', JSON.stringify(payload, null, 2));

    if (session.isValid()) {
      console.info('Valid session already exists: ', session);
      setUserId(payload.identities[0].userId);
      setAuthState(AuthenticationState.LOGGED_IN);
      //Set the timer if it's not set
      if (!timer) {
        const millies =
          session.getIdToken().getExpiration() * 1000 - Date.now();
        autoLogOutAfter(millies);
      }
      return true;
    }
    // Check specifically for 'code' query param since a blanket check for
    // query params will catch our user filters as well
    else if (href.indexOf('?code') > 0) {
      auth.parseCognitoWebResponse(href);
      console.info('Parsed code query param: ', session);
      return false;
    } else {
      console.info('Invalid session, redirecting to Auth');
      // Cognito SDK will handle session refresh / authentication.
      // clear the session tokens and scopes from local storage
      setAuthState(AuthenticationState.LOGGING_IN);

      // So we know to redirect user back to where they
      // wanted to go after logging in
      writeToLocal(savedOriginalUserLocationKey, window.location);

      auth.clearCachedTokensScopes();
      auth.getSession();
      return false;
    }
  };
  useEffect(() => {
    login(auth);
    return clearAutologOutTimer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { authState, userId };
}

export default useFederatedAuth;
