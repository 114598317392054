import { LocalizedPackage } from 'src/generated/graphql';
import { getStage, Stage } from 'src/components/common/util/StageHelpers';
import { TranslateFn } from 'src/i18next-arb-shim/useTranslation';

// Constants
const GIT_FARM_PACKAGE_TYPE = 'GitFarm';
const CONTENT_SYMPHONY_PACKAGE_TYPE = 'ContentSymphony';
const ONCILLA_PACKAGE_TYPE = 'Oncilla';

export const GIT_FARM_PACKAGE_LINK_PARTIAL = 'https://code.amazon.com/packages';
export const CONTENT_SYMPHONY_PACKAGE_LINK_PARTIAL = `https://${
  getStage() === Stage.Prod ? '' : 'beta.'
}console.harmony.a2z.com/content-symphony/NA/creatives/summary`;

export const getClientNameLabel = (
  packageType: LocalizedPackage['packageType'],
  t: TranslateFn
) => {
  switch (packageType) {
    case GIT_FARM_PACKAGE_TYPE:
      return t('client-name-for-gitfarm-package-type');
    case CONTENT_SYMPHONY_PACKAGE_TYPE:
      return t('client-name-for-content-symphony-package-type');
    case ONCILLA_PACKAGE_TYPE:
      return t('client-name-for-oncilla-package-type');
    default:
      return packageType;
  }
};

// Map package to URL based on package type
export const getPackageLink = (pkg: Omit<LocalizedPackage, 'id'>) => {
  switch (pkg.packageType) {
    case GIT_FARM_PACKAGE_TYPE:
      return `${GIT_FARM_PACKAGE_LINK_PARTIAL}/${pkg.name}`;
    case CONTENT_SYMPHONY_PACKAGE_TYPE:
      // Parse creative ID from package name
      const creativeId = pkg.name.split('-').slice(-5).join('-');
      return `${CONTENT_SYMPHONY_PACKAGE_LINK_PARTIAL}/${creativeId}`;
    case ONCILLA_PACKAGE_TYPE:
    // handled by default case
    default:
      return '';
  }
};

// Map package to link label based on package type
export const getPackageLinkLabel = (
  packageType: LocalizedPackage['packageType'],
  t: TranslateFn
): string => {
  switch (packageType) {
    case GIT_FARM_PACKAGE_TYPE:
      return t('string-link-label-gitfarm-repository');
    case CONTENT_SYMPHONY_PACKAGE_TYPE:
      return t('string-link-label-symphony-creative');
    case ONCILLA_PACKAGE_TYPE:
      return t('string-link-label-oncilla-string-group');
    default:
      return '';
  }
};

// Returns formatted package type, package link, and package link label
export const getPackageMetadata = (
  pkg: Omit<LocalizedPackage, 'id'>,
  t: TranslateFn
) => ({
  link: getPackageLink(pkg),
  linkLabel: getPackageLinkLabel(pkg?.packageType, t),
  clientNameLabel: getClientNameLabel(pkg?.packageType, t),
});
