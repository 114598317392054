import React, { useEffect, useState } from 'react';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import styles from 'src/components/Header/SyncBanner/SyncBanner.module.scss';
import sanitizeHtml from 'sanitize-html';

export enum STATUS {
  UNKNOWN = 'undefined',
  OK = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
}

export type SyncData = {
  name: string;
  level: STATUS;
  message: string;
  tickets: string;
};

type Props = {
  id: string;
  enable?: boolean;
};

const SyncBanner: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const url = 'https://is-it-down.amazon.com/lui-sync';
  const levelMap = [
    styles.statusZero,
    styles.statusOne,
    styles.statusTwo,
    styles.statusThree,
  ];

  const errorData: SyncData = {
    name: 'LUI Synchronization',
    level: STATUS.UNKNOWN,
    message: t('lui-sync-error-message'),
    tickets: '',
  };

  /* React Hooks */
  const [data, setData] = useState({} as SyncData);
  const [message, setMessage] = useState('OK');
  const fetchIsItDownData = () => {
    const statusURL = url + '/components/lui-sync.js';
    fetch(statusURL, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch(() => setData(errorData));
  };
  useEffect(() => {
    if (process.env.NODE_ENV === 'test' && !props.enable) {
      return;
    }
    fetchIsItDownData();
    const timer = setInterval(fetchIsItDownData, 30000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!data.message || data.message == 'OK') {
      setMessage(t(`lui-sync-status-message-${data.level}`));
    } else {
      setMessage(data.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getClassName = (): string => {
    let statusClass;
    if (data.level in STATUS && data.level != STATUS.UNKNOWN) {
      statusClass = levelMap[data.level];
    } else {
      statusClass = styles.statusUnknown;
      data.level = STATUS.UNKNOWN;
    }

    return `${styles.isItDownStripe} ${statusClass}`;
  };

  return (
    <div className={getClassName()}>
      <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }} />
      <a className={styles.isItDownLink} href={url}>
        {t('lui-sync-is-it-down-text')}
      </a>
    </div>
  );
};

export default SyncBanner;
