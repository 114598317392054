import { flatten } from 'lodash';
import { WildcardMockedResponse } from 'wildcard-mock-link';
import { i18nFamiliesMock } from 'src/api/mocks/i18nFamiliesMock';
import { ownersMock } from 'src/api/mocks/ownersMock';
import { localizedPackagesMock } from 'src/api/mocks/localizedPackagesMock';
import { packageTypesMock } from 'src/api/mocks/packageTypesMock';
import { phrasesMock } from 'src/api/mocks/phrasesMock';
import { phraseMock } from 'src/api/mocks/phraseMock';
import { phraseVariantsMock } from 'src/api/mocks/queries/phraseVariants';
import { phraseLocalizationsMock } from 'src/api/mocks/queries/phraseLocalizations';
import { WildcardMockLink } from 'src/polyfills/wildcardMockLinkPolyfill';
import { superuserMock } from 'src/api/mocks/superUserMock';
import { localizationsUpdateMock } from 'src/api/mocks/mutation/localizationsUpdate';
import { localesMock } from './localesMock';
import { translationsMock } from './translationsMock';

export type MockObject = {
  request: object;
  result?: object;
  error?: Error;
};

export const GQLMocks = Promise.all([
  phrasesMock,
  phraseMock,
  ownersMock,
  i18nFamiliesMock,
  localesMock,
  localizedPackagesMock,
  packageTypesMock,
  phraseVariantsMock,
  phraseLocalizationsMock,
  superuserMock,
  localizationsUpdateMock,
  translationsMock,
]);

export const GQLWildcardLink = async () => {
  const mocks = flatten(await GQLMocks);
  return new WildcardMockLink(mocks as WildcardMockedResponse[]);
};
