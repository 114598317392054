export enum Stage {
  Test = 'test',
  Beta = 'beta',
  Gamma = 'gamma',
  Prod = 'prod',
}

export function getEnvFromHost(host: string) {
  return host.split('.')[0];
}

export function envToStage(env: string) {
  switch (env) {
    case 'beta':
      return Stage.Beta;
    case 'gamma':
      return Stage.Gamma;
    case 'lui':
      return Stage.Prod;
    default:
      return Stage.Test;
  }
}

export function getStage() {
  const env = getEnvFromHost(window.location.host);
  return envToStage(env);
}
