import { staticXliffStrMock } from 'src/api/mocks/types/xliffSampleStr';

export default {
  id: {
    faker: 'datatype.number',
  },
  name: {
    faker: 'lorem.word',
  },
  anchorTag: {
    faker: 'internet.url',
  },
  format: {
    values: [null, 'xliff'],
  },
  'object.format==="xliff",sourceText': {
    static: staticXliffStrMock,
  },
  'object.format!=="xliff",sourceText': {
    values: [
      'Hello',
      `You have {itemCount, plural,
        =0 {no items}
        one {# item}
        other {# items}
        }.`,
    ],
  },
  sourceLocale: {
    faker: 'random.locale',
  },
  version: {
    faker: 'datatype.number',
  },
  context: {
    faker: 'lorem.paragraph',
  },
  created: {
    faker: 'date.past',
  },
  createdBy: {
    faker: 'company.companyName',
  },
  lastUpdated: {
    values: [1602803146, 1529100000, 1564469400, 1581754800, 1589065200],
  },
  lastUpdatedBy: {
    faker: 'company.companyName',
  },
  owner: {
    faker: 'company.companyName',
  },
  ownerType: {
    values: ['TEAM', 'USER'],
  },
  i18nFamily: {
    values: [
      'Retail',
      'books',
      'advertising',
      'HRServices',
      'PrimeVideoWebPlayer',
      'TwitchPrimeStandard',
    ],
  },
  localizedPackage: {
    faker: 'company.catchPhrase',
  },
  localizedPackageType: {
    values: ['GitFarm', 'ContentSymphony'],
  },
  localizedPackageOriginId: {
    faker: 'company.catchPhrase',
  },
  localizedPackageTsAceName: {
    values: [null, 'MockTsAceName'],
  },
  psmsId: {
    values: ['PSMS-ID-e56a26db-9ea5-4ea1-aefb-9c0faaf69498'],
  },
};
