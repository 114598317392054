import React, { useEffect, useState } from 'react';
import { KatSpinner } from '@amzn/katal-react';
import { MessageBundle } from '@amzn/arb-tools';
import { useLocalizationContext } from '@amzn/react-arb-tools';
import DefaultBundleContext from './DefaultBundleContext';
import { DEFAULT_BUNDLE, i18n } from 'src/i18n';

interface Props {
  children?: React.ReactNode;
}

/**
 * This component makes the transition from i18next/KatalLocalization ARB JS
 * easier.
 *
 * Resolves the default bundle, stores it in the `DefaultBundleContext` and
 * makes it available via `useTranslations()` hook.
 * @param children
 * @constructor
 */
const DefaultBundleProvider: React.FC<Props> = ({ children }: Props) => {
  const [bundle, setBundle] = useState<MessageBundle>();
  const { localizationContext } = useLocalizationContext();

  async function bundleResolver() {
    const loadedBundle = await i18n.mbm.getBundle(DEFAULT_BUNDLE);
    i18n.defaultBundle = loadedBundle;
    setBundle(loadedBundle);
  }

  useEffect(() => {
    bundleResolver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(localizationContext)]);

  if (bundle === undefined) {
    return <KatSpinner size="large" />;
  }

  return (
    <DefaultBundleContext.Provider value={bundle}>
      {children}
    </DefaultBundleContext.Provider>
  );
};

export default DefaultBundleProvider;
