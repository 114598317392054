import React, { useRef, useEffect, useState } from 'react';
import styles from './TextOverflowHover.module.scss';

type Props = {
  text: string;
};

const TextOverflowHover: React.FC<Props> = ({ text }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showHoverText, setShowHoverText] = useState<boolean>(false);

  useEffect(() => {
    const handleTextOverflow = () => {
      const offsetWidth = ref?.current?.offsetWidth || 0;
      const scrollWidth = ref?.current?.scrollWidth || 0;
      setShowHoverText(offsetWidth < scrollWidth);
    };
    window.addEventListener('resize', handleTextOverflow);
    handleTextOverflow();
    return () => window.removeEventListener('resize', handleTextOverflow);
  }, [text]);

  const title = showHoverText ? text : undefined;
  return (
    <div ref={ref} title={title} className={styles.text} dir="auto">
      {text}
    </div>
  );
};

export default TextOverflowHover;
