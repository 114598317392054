import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import StringList from 'src/components/StringList/StringList';
import StringDetailView from 'src/views/StringDetailView';
import LandingPageView from 'src/views/LandingPageView/LandingPageView';
import { loadPreferences } from 'src/api/preferencesClient';
import TargetSearch from './TargetSearch/TargetSearch';
import { I18nFamilyQueryResultProvider } from './common/provider/QueryResultProvider';

//persist query params when redirected back from auth

const Routes: React.FC = () => {
  const [hasPersona, setHasPersona] = useState(!!loadPreferences()?.persona);
  const onInitialSetupDone = () => {
    setHasPersona(true);
  };
  const landingPage = {
    pathname: '/landing',
    state: { redirected: true },
  };
  return (
    <Switch>
      <Route path="/landing">
        <LandingPageView onSetupDone={onInitialSetupDone} />
      </Route>
      <Route path="/strings/:id">
        {hasPersona ? <StringDetailView /> : <Redirect to={landingPage} />}
      </Route>
      <Route path="/translations">
        {hasPersona ? (
          <I18nFamilyQueryResultProvider>
            <TargetSearch id="lui-target-search" />
          </I18nFamilyQueryResultProvider>
        ) : (
          <Redirect to={landingPage} />
        )}
      </Route>
      <Route path="/">
        {hasPersona ? (
          <I18nFamilyQueryResultProvider>
            <StringList id="lui-string-list" />
          </I18nFamilyQueryResultProvider>
        ) : (
          <Redirect to={landingPage} />
        )}
      </Route>
    </Switch>
  );
};

export default Routes;
