import React, { useEffect, useState } from 'react';
import { debounce, extend, map } from 'lodash';
import { KatInput } from '@amzn/katal-react';
import Fuse from 'fuse.js';

const DEFAULT_DEBOUNCE_WAIT = process.env.NODE_ENV === 'test' ? 0 : 1000;
//const DEFAULT_DEBOUNCE_WAIT = 1000;
const DEFAULT_MIN_MATCH_CHAR_LENGTH = 2;

const DEFAULT_FUSE_OPTIONS = {
  ignoreLocation: true, // search for pattern for no particular location
  includeScore: true, // For debugging purpose keep scores come in - shows relevance
  threshold: 0.6, // Not same as relevance score - this value happened to be working well then higher values
  findAllMatches: true,
  shouldSort: true,
  minMatchCharLength: DEFAULT_MIN_MATCH_CHAR_LENGTH,
};

type Props<T, O extends Fuse.IFuseOptions<T>> = {
  items: T[] | [];
  fuseOptions?: O;
  onTextChange?: (text: string) => void;
  onSearchResultUpdate?: (result: T[] | []) => void;
} & KatInput.Props;

const FuzzySearchInput = <T, O extends Fuse.IFuseOptions<T>>(
  props: Props<T, O>
) => {
  const [fuse, setFuse] = useState<Fuse<T>>(new Fuse([]));
  const [previousValue, setPreviousValue] = useState('');

  const updateItems = (value: string) => {
    if (props.onSearchResultUpdate) {
      setPreviousValue(value);

      props.onSearchResultUpdate(
        value.length < DEFAULT_MIN_MATCH_CHAR_LENGTH
          ? props.items
          : map(fuse.search(value), 'item')
      );
    }
  };

  useEffect(() => {
    setFuse(
      new Fuse(
        props.items,
        extend({}, DEFAULT_FUSE_OPTIONS, props?.fuseOptions)
      )
    );
  }, [props.items, props.fuseOptions]);

  useEffect(() => {
    updateItems(previousValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuse]);

  const onKeyup = debounce((event: KatInput.ChangeEvent) => {
    const value = (event.target as KatInput.Props).value || '';
    updateItems(value);
    if (props.onKeyup) {
      props.onKeyup(event);
    }
    if (props.onTextChange) {
      props.onTextChange(value);
    }
  }, DEFAULT_DEBOUNCE_WAIT);

  return <KatInput onKeyup={onKeyup} {...props} />;
};

export default FuzzySearchInput;
