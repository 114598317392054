export default {
  Standalone: 'Standalone',
  GenericSpan: 'GenericSpan',
  GenericSpanStart: 'GenericSpanStart',
  GenericSpanEnd: 'GenericSpanEnd',
  Span: 'Span',
  SpanStart: 'SpanStart',
  SpanEnd: 'SpanEnd',
  Marker: 'Marker',
};
