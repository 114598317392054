import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PreferenceModal from 'src/components/Preferences/InitialModal/InitialModal';
import {
  KatButton,
  KatDataTable,
  KatFlashbar,
  KatRow,
} from '@amzn/katal-react';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import rootMetricPublisher, { METRIC, timerStopwatchMetric } from 'src/metrics';
import { loadPreferences } from 'src/api/preferencesClient';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import columnConfigConstructor from 'src/views/LandingPageView/releasesTableColumnConfig';
import releaseContentConstructor from 'src/views/LandingPageView/releasesTableContent';
import workflowStepsConstructor from 'src/views/LandingPageView/workflowContent';
import overview from 'src/images/landing-overview.svg';
import styles from './LandingPage.module.scss';
import './releasesTable.scss';
import sanitizeHtml from 'sanitize-html';

interface LocationState {
  redirected?: boolean;
}

type Props = {
  onSetupDone: () => void;
};

const metricPublisher: KatalMetricsPublisher =
  rootMetricPublisher.newChildActionPublisherForMethod('LandingPage');
const LandingPageView: React.FC<Props> = (props: Props) => {
  /* Metrics */
  const initLatencyMetric = timerStopwatchMetric(METRIC.LOADING_LATENCY);
  const timeSpentMetric = timerStopwatchMetric(METRIC.TIME_SPENT);

  /* React Hooks */
  const [displayModal, setDisplayModal] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const redirected: boolean =
    (history.location?.state as LocationState)?.redirected || false;

  useEffect(() => {
    metricPublisher.publish(initLatencyMetric);
    return () => metricPublisher.publish(timeSpentMetric);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Handlers */
  const onStartSetup = () => {
    if (!!loadPreferences()?.persona) {
      history.push('/');
    } else {
      setDisplayModal(true);
    }
  };

  /* Callbacks */
  const onInitialSetupDone = () => {
    setDisplayModal(false);
    props.onSetupDone();
    history.push('/');
  };

  /* JSX */
  const workflow = workflowStepsConstructor(t).map((step) => {
    return (
      <div id={step.id} key={step.id} className={styles.step}>
        <img src={step.img} />
        <h2>{step.stepTitle}</h2>
        <p>{step.stepDetail}</p>
      </div>
    );
  });

  return (
    <>
      <section id="lui-landing-page-body">
        {redirected && (
          <KatFlashbar
            className={styles.redirectInfo}
            header={t('landing-page-redirect')}
          />
        )}
        <div id="lui-landing-hero" className={styles.hero}>
          <div id="lui-landing-hero-content" className={styles.heroContent}>
            <div>
              <h1 id="lui-landing-hero-title" className="text-size-xl">
                {t('landing-page-hero-title')}
              </h1>
              <p id="lui-landing-hero-context">
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(t('landing-page-hero-context')),
                  }}
                />
              </p>
            </div>
            <KatButton
              id="lui-landing-hero-start-btn"
              label={t('landing-page-start-btn-label')}
              onClick={onStartSetup}
            />
          </div>
        </div>
        <KatRow id="lui-landing-overview" className={styles.overview}>
          <img src={overview} alt="LUI Overview Image" />
          <div id="lui-landing-overview-content">
            <h1>{t('landing-page-overview-title')}</h1>
            <ul>
              <li>{t('landing-page-overview-item-1')}</li>
              <li>{t('landing-page-overview-item-2')}</li>
              <li>{t('landing-page-overview-item-3')}</li>
              <li>{t('landing-page-overview-item-4')}</li>
            </ul>
            <a
              href="https://w.amazon.com/bin/view/INTech/Panther/LUI"
              rel="noreferrer external"
              target="_blank"
            >
              <KatButton
                variant="secondary"
                id="lui-landing-learn-more"
                label={t('landing-page-learn-more-btn-label')}
                onClick={() =>
                  metricPublisher.publishCounterMonitor('LUI_Wiki_Click', 1)
                }
              />
            </a>
          </div>
        </KatRow>
        <div id="lui-landing-workflow" className={styles.workflow}>
          <KatRow
            id="lui-landing-workflow-title"
            className={styles.workflowTitle}
          >
            <h1>{t('landing-workflow-title')}</h1>
          </KatRow>
          <KatRow
            id="lui-landing-workflow-steps"
            className={styles.workflowSteps}
          >
            {workflow}
          </KatRow>
          <KatRow
            id="lui-landing-learn-transcom"
            className={styles.learnTranscom}
          >
            <a
              href="https://w.amazon.com/bin/view/INTech/TranslationServices/TranslationIngestion/API/TRANSCOM/"
              rel="noreferrer external"
              target="_blank"
            >
              <KatButton
                variant="secondary"
                id="lui-landing-learn-more-transcom"
                label={t('landing-learn-more-transcom-btn-label')}
                onClick={() =>
                  metricPublisher.publishCounterMonitor(
                    'TRANSCOM_Wiki_Click',
                    1
                  )
                }
              />
            </a>
          </KatRow>
        </div>
        <div id="lui-landing-releases" className={styles.releases}>
          <KatRow id="lui-landing-releases-title">
            <h1>{t('landing-releases-title')}</h1>
          </KatRow>
          <KatDataTable
            id="lui-landing-releases-table"
            columns={columnConfigConstructor(i18n)}
            rowData={releaseContentConstructor(t)}
          />
        </div>
        <div id="lui-landing-footer" className={styles.footer}>
          <h1>{t('landing-manage-content-text')}</h1>
          <KatButton
            id="lui-landing-footer-start-btn"
            label={t('landing-page-start-btn-label')}
            onClick={onStartSetup}
          />
        </div>
      </section>
      <PreferenceModal
        display={displayModal}
        id="lui-pref-modal"
        onDone={onInitialSetupDone}
        onCancel={() => setDisplayModal(false)}
      />
    </>
  );
};

export default LandingPageView;
