import React from 'react';
type LUISuperuserContext = {
  isSuperuser?: boolean;
  isSudoMode: boolean;
  isSuperUserLoading: boolean;
  setIsSudoMode: (isSudo: boolean) => void;
};
export const SuperuserContext = React.createContext({
  isSuperuser: false,
  isSudoMode: false,
  isSuperUserLoading: true,
  setIsSudoMode: (isSudoMode) => undefined,
} as LUISuperuserContext);
