import mocker from 'mocker-data-generator';
import { PhraseLocalizationsDocument } from 'src/generated/graphql';
import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';
import localization from 'src/api/mocks/types/localization';
import phrase from 'src/api/mocks/types/phrase';
import { MockObject } from '../mocks';
import { extend } from 'lodash';
import variant from '../types/variant';
import xliffLocalization from 'src/api/mocks/types/xliffLocalization';

const phraseWithLocalizations = extend({}, phrase, {
  variant: {
    hasOne: 'fakedVariants',
  },
  'object.format==="xliff",localizations': {
    hasMany: 'fakedXliffLocalizations',
    amount: 5,
  },
  'object.format!=="xliff",localizations': {
    hasMany: 'fakedLocalizations',
    amount: 5,
  },
});

export const phraseLocalizationsMock = (async (): Promise<
  MockObject | MockObject[]
> => {
  const { fakedPhrases } = await mocker()
    .schema('fakedVariants', variant, 1)
    .schema('fakedLocalizations', localization, 8)
    .schema('fakedXliffLocalizations', xliffLocalization, 1)
    .schema('fakedPhrases', phraseWithLocalizations, 1)
    .build();
  return {
    request: {
      query: PhraseLocalizationsDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        phrase: fakedPhrases[0],
      },
    },
  };
})();
