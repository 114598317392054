import React, { useCallback } from 'react';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import { KatDataTable, KatSpinner } from '@amzn/katal-react';
import { Localization, Phrase } from 'src/generated/graphql';
import columnConfigConstructor from './ColumnConfig';
import ErrorHandler from 'src/components/common/ui/ErrorHandler/ErrorHandler';
import { map, orderBy } from 'lodash';
import initialMetricsPublisher from 'src/metrics';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import { TranslationEditHandler } from 'src/views/StringDetailView/StringDetailView';
import { getLocalizedTimestamp } from 'src/components/common/util/TimestampHelpers';
import moment from 'moment';
import './Table.scss';

const metricPublisher: any =
  initialMetricsPublisher.newChildActionPublisherForMethod(
    'StringDetail-Table'
  );

type Props = {
  localizations: Localization[];
  loading: boolean;
  error: ApolloError | null | undefined;
  edit: TranslationEditHandler;
  onSelectionChange: (e: CustomEvent) => void;
  phrase?: Phrase;
};

const Table: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation();

  const tableColumns = React.useMemo(() => {
    return columnConfigConstructor(props.edit, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.edit, i18n.language]);
  const rows = React.useMemo(() => {
    return map(props.localizations, (row, localizationIndex) => {
      return {
        ...row,
        lastUpdated: getLocalizedTimestamp(row?.lastUpdated),
        name: props.phrase?.name,
        format: props.phrase?.format,
        localizationIndex,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.localizations, i18n.language]);

  const onSettingChangedHandler = useCallback(
    (event: KatDataTable.SettingsChangedEvent) => {
      const sortColumn = event.detail.sortColumn;
      const sortDirection = event.detail.sortDirection;

      if (!sortColumn) {
        return;
      }

      (event.target as KatDataTable.Props).rowData = orderBy(
        rows,
        (row) => {
          if (sortColumn.property == 'lastUpdated') {
            return moment(row.lastUpdated) as moment.Moment;
          }

          return row[sortColumn.property as keyof Localization];
        },
        [sortDirection]
      );
    },
    [rows]
  );

  if (props.loading) {
    return <KatSpinner />;
  }

  if (props.error) {
    return (
      <ErrorHandler
        publisher={metricPublisher}
        header={t('error-flashbar-header-message-string-translations')}
        error={props.error}
      />
    );
  }

  return (
    <KatDataTable
      id="lui-string-detail-table"
      titleLabel={t('string-detail-table-title')}
      columns={tableColumns}
      rowData={rows}
      onSettingsChanged={onSettingChangedHandler}
      config={{ enableSelectionColumn: true }}
      onSelectionChanged={props.onSelectionChange}
    />
  );
};

export default React.memo(Table);
