import React from 'react';
import { KatIcon } from '@amzn/katal-react';
import styles from './Tags.module.scss';
import { isEmpty, uniqueId } from 'lodash';
import {
  Criteria,
  FilterCategory,
} from 'src/components/Preferences/FilterModal/FilterModal';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import { FilterField } from 'src/components/Search/SearchConstants';

type Props = {
  id: string;
  filter: FilterCategory;
  onChange?: (e: React.MouseEvent, tag: Tag) => void;
};
export type Tag = {
  field: string;
  id: string | number;
  name: string;
};

const Tags: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const closeIcon = (tag: Tag) => {
    const callback = props.onChange;
    if (!callback) {
      return null;
    } else {
      return (
        <span
          className={styles.close}
          onClick={(e) => {
            callback(e, tag);
          }}
        >
          <KatIcon name="close" size="small" className={styles.icon} />
        </span>
      );
    }
  };

  const tags: Tag[] = createTagsFromFilter(props.filter);

  const tagList = tags.map((tag, idx) => (
    <div id={`${props.id}-${idx}`} key={uniqueId()} className={styles.tag}>
      <span className={styles.label}>
        <b>{t(tag.field)}: </b>
        {tag.name}
      </span>
      {closeIcon(tag)}
    </div>
  ));

  return (
    <div id={props.id} className={styles.tags}>
      {tagList}
    </div>
  );
};

const createTagsFromFilter = (filter: FilterCategory): Tag[] => {
  if (isEmpty(filter)) return [];

  const tags: Tag[] = [];
  filter.i18nFamily?.forEach((family: Criteria) =>
    tags.push({
      field: FilterField.I18N_FAMILY,
      ...family,
    })
  );
  filter.owners?.forEach((owner: Criteria) =>
    tags.push({ field: FilterField.OWNER, ...owner })
  );
  filter.localizedPackages?.forEach((group: Criteria) =>
    tags.push({ field: FilterField.GROUP, ...group })
  );
  filter.packageTypes?.forEach((client: Criteria) =>
    tags.push({
      field: FilterField.CLIENT_NAME,
      ...client,
    })
  );
  filter.statuses?.forEach((status: Criteria) =>
    tags.push({ field: FilterField.STATUS, ...status })
  );
  filter.sourceTexts?.forEach((sourceText: Criteria) =>
    tags.push({ field: FilterField.SOURCE_TEXT, ...sourceText })
  );
  filter.psmsIds?.forEach((psmsId: Criteria) =>
    tags.push({ field: FilterField.PSMS_ID, ...psmsId })
  );
  filter.stringIds?.forEach((stringId: Criteria) =>
    tags.push({ field: FilterField.STRING_ID, ...stringId })
  );
  filter.locales?.forEach((locale: Criteria) =>
    tags.push({ field: FilterField.TARGET_LOCALE, ...locale })
  );
  filter.translations?.forEach((translation: Criteria) =>
    tags.push({ field: FilterField.TRANSLATION, ...translation })
  );

  return tags;
};

export default Tags;
