import mocker from 'mocker-data-generator';
import { PhraseDocument, Bindle } from 'src/generated/graphql';
import {
  GraphQLRequestWithWildcard,
  MATCH_ANY_PARAMETERS,
  WildcardMockedResponse,
} from 'wildcard-mock-link';
import phrase from 'src/api/mocks/types/phrase';
import variant from 'src/api/mocks/types/variant';
import { extend, shuffle } from 'lodash';
import { MockedResponse } from '@apollo/client/testing';
import { GraphQLError } from 'graphql';
import bindle from 'src/api/mocks/types/bindle';
import { FetchResult } from '@apollo/client';

const phraseWithVariant = extend({}, phrase, {
  variant: {
    hasOne: 'fakedVariants',
    min: 1,
    max: 3,
  },
});

export const phraseMock = (async () => {
  const { fakedPhrases } = await mocker()
    .schema('fakedVariants', variant, 1)
    .schema('fakedPhrases', phraseWithVariant, 3)
    .build();
  return {
    request: {
      query: PhraseDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        phrase: shuffle(fakedPhrases)[0],
      },
    },
  };
})();

export const getPhraseErrorMock = (): MockedResponse => {
  return {
    request: {
      query: PhraseDocument,
    },
    error: new Error('Mock error'),
  };
};

export const getForbiddenPhraseErrorMockLink = async ({
  includeBindle = false,
  includeMalformedMessage = false,
}): Promise<WildcardMockedResponse> => {
  const { fakedBindle }: { fakedBindle: Bindle[] } = await mocker()
    .schema('fakedBindle', bindle, 1)
    .build();

  let message: {
    [index: string]: string | Bindle;
  } = {
    message: 'Forbidden',
  };
  if (includeBindle) {
    message = { ...message, bindle: fakedBindle[0] };
  }

  const messageStr = includeMalformedMessage
    ? `${Math.random().toString(36)}`
    : JSON.stringify(message);

  const gqlError = {
    ...new GraphQLError(messageStr, undefined, undefined, undefined, [
      'phrase',
    ]),
    errorType: '403',
  };

  return {
    request: {
      query: PhraseDocument,
      variables: MATCH_ANY_PARAMETERS,
    } as GraphQLRequestWithWildcard,
    result: {
      errors: [gqlError],
    } as FetchResult,
  };
};
