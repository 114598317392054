import { DateTime } from 'luxon';

const getLocalizedTimestamp = (dateInput: number | undefined | null) => {
  const dateToParse = dateInput as number;
  const dateTimeObj = DateTime.fromSeconds(dateToParse);

  return dateTimeObj.toLocaleString({
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    weekday: 'short',
    timeZoneName: 'short',
    // DateTime objects are created in the system's local zone so this will get user's local timezone
    timeZone: dateTimeObj.zoneName,
  });
};

export { getLocalizedTimestamp };
