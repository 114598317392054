import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Authorization = {
  __typename?: 'Authorization';
  isAuthorized?: Maybe<Scalars['Boolean']>;
};

export type Bindle = {
  __typename?: 'Bindle';
  id: Scalars['Int'];
  name: Scalars['String'];
  sourceSystemId?: Maybe<Scalars['String']>;
};

export type I18nFamily = {
  __typename?: 'I18nFamily';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Locale = {
  __typename?: 'Locale';
  locale: Scalars['String'];
};

export type LocaleInput = {
  i18nFamilies?: InputMaybe<Scalars['String']>;
};

export type Localization = {
  __typename?: 'Localization';
  canEdit?: Maybe<Scalars['Boolean']>;
  dropDownReasons?: Maybe<Array<Scalars['String']>>;
  explanation?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
  translationStatus?: Maybe<Scalars['String']>;
};

export type LocalizationUpdateInput = {
  localizations?: InputMaybe<Scalars['String']>;
};

export type LocalizationUpdateResponse = {
  __typename?: 'LocalizationUpdateResponse';
  localization: Localization;
  status: TaskStatus;
};

export type LocalizedPackage = {
  __typename?: 'LocalizedPackage';
  bindle?: Maybe<Bindle>;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner?: Maybe<Owner>;
  packageType?: Maybe<Scalars['String']>;
  sourceSystemId?: Maybe<Scalars['String']>;
};

export type LocalizedPackageInput = {
  i18nFamilies?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  localizationsUpdate: Array<Maybe<LocalizationUpdateResponse>>;
};

export type MutationLocalizationsUpdateArgs = {
  body: Scalars['String'];
  params: PhraseLocalizationsInput;
};

export type Owner = {
  __typename?: 'Owner';
  id: Scalars['Int'];
  name: Scalars['String'];
  ownerType?: Maybe<Scalars['String']>;
  sourceSystemId?: Maybe<Scalars['String']>;
};

export type OwnerInput = {
  i18nFamilies?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PackageType = {
  __typename?: 'PackageType';
  packageType?: Maybe<Scalars['String']>;
};

export type PackageTypeInput = {
  i18nFamilies?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  count: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Phrase = {
  __typename?: 'Phrase';
  anchorTag: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  i18nFamily?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastUpdated?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  localizations?: Maybe<Array<Maybe<Localization>>>;
  localizedPackage?: Maybe<Scalars['String']>;
  localizedPackageOriginId?: Maybe<Scalars['String']>;
  localizedPackageTsAceName?: Maybe<Scalars['String']>;
  localizedPackageType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  psmsId?: Maybe<Scalars['String']>;
  sourceLocale: Scalars['String'];
  sourceText: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  translationsLastUpdated?: Maybe<Scalars['Int']>;
  variant?: Maybe<Variant>;
  variants?: Maybe<Array<Maybe<Variant>>>;
  version?: Maybe<Scalars['String']>;
};

export type PhraseInput = {
  caseSensitive?: InputMaybe<Scalars['Boolean']>;
  exactMatch?: InputMaybe<Scalars['Boolean']>;
  i18nFamilies?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  localizedPackages?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  owners?: InputMaybe<Scalars['String']>;
  packageTypes?: InputMaybe<Scalars['String']>;
  psmsIds?: InputMaybe<Scalars['String']>;
  sortDir1?: InputMaybe<Scalars['String']>;
  sortDir2?: InputMaybe<Scalars['String']>;
  sortDir3?: InputMaybe<Scalars['String']>;
  sortDir4?: InputMaybe<Scalars['String']>;
  sortDir5?: InputMaybe<Scalars['String']>;
  sortDir6?: InputMaybe<Scalars['String']>;
  sortKey1?: InputMaybe<Scalars['String']>;
  sortKey2?: InputMaybe<Scalars['String']>;
  sortKey3?: InputMaybe<Scalars['String']>;
  sortKey4?: InputMaybe<Scalars['String']>;
  sortKey5?: InputMaybe<Scalars['String']>;
  sortKey6?: InputMaybe<Scalars['String']>;
  sourceTexts?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Scalars['String']>;
  stringIds?: InputMaybe<Scalars['String']>;
  sudo?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};

export type PhraseLocalizationsInput = {
  id: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sudo?: InputMaybe<Scalars['Boolean']>;
  variantId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

export type Phrases = {
  __typename?: 'Phrases';
  pageInfo: PageInfo;
  phraseList: Array<Phrase>;
};

export type Query = {
  __typename?: 'Query';
  i18nFamilies: Array<Maybe<I18nFamily>>;
  isSuperuser?: Maybe<Authorization>;
  locales: Array<Maybe<Locale>>;
  localizedPackages: Array<Maybe<LocalizedPackage>>;
  owners: Array<Maybe<Owner>>;
  packageTypes: Array<Maybe<PackageType>>;
  phrase: Phrase;
  phraseLocalizations: Phrase;
  phraseVariants: Phrase;
  phrases: Phrases;
  sendMessage: SendMessageResult;
  translations: Translations;
};

export type QueryLocalesArgs = {
  params?: InputMaybe<LocaleInput>;
};

export type QueryLocalizedPackagesArgs = {
  params?: InputMaybe<LocalizedPackageInput>;
};

export type QueryOwnersArgs = {
  params?: InputMaybe<OwnerInput>;
};

export type QueryPackageTypesArgs = {
  params?: InputMaybe<PackageTypeInput>;
};

export type QueryPhraseArgs = {
  params: PhraseLocalizationsInput;
};

export type QueryPhraseLocalizationsArgs = {
  params: PhraseLocalizationsInput;
};

export type QueryPhraseVariantsArgs = {
  params: PhraseLocalizationsInput;
};

export type QueryPhrasesArgs = {
  params: PhraseInput;
};

export type QuerySendMessageArgs = {
  params: SendMessageInput;
};

export type QueryTranslationsArgs = {
  params: TranslationInput;
};

export type SendMessageInput = {
  message: Scalars['String'];
  queueName: Scalars['String'];
};

export type SendMessageResult = {
  __typename?: 'SendMessageResult';
  messageId: Scalars['String'];
};

export enum TaskStatus {
  FailedLui = 'FAILED_LUI',
  FailedPanther = 'FAILED_PANTHER',
  FailedUnknown = 'FAILED_UNKNOWN',
  RejectedIncomplete = 'REJECTED_INCOMPLETE',
  RejectedNoChange = 'REJECTED_NO_CHANGE',
  RejectedOutstanding = 'REJECTED_OUTSTANDING',
  RejectedUnauthorized = 'REJECTED_UNAUTHORIZED',
  Succeeded = 'SUCCEEDED',
}

export type Translation = {
  __typename?: 'Translation';
  format?: Maybe<Scalars['String']>;
  localization: Localization;
  phraseId: Scalars['Int'];
  phraseName: Scalars['String'];
  sourceText: Scalars['String'];
};

export type TranslationInput = {
  caseSensitive?: InputMaybe<Scalars['Boolean']>;
  exactMatch?: InputMaybe<Scalars['Boolean']>;
  i18nFamilies?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Scalars['String']>;
  localizedPackages?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  owners?: InputMaybe<Scalars['String']>;
  packageTypes?: InputMaybe<Scalars['String']>;
  sudo?: InputMaybe<Scalars['Boolean']>;
  translations?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export type Translations = {
  __typename?: 'Translations';
  pageInfo: PageInfo;
  translationList: Array<Translation>;
};

export type Variant = {
  __typename?: 'Variant';
  category: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type LocalizationsUpdateMutationVariables = Exact<{
  params: PhraseLocalizationsInput;
  body: Scalars['String'];
}>;

export type LocalizationsUpdateMutation = {
  __typename?: 'Mutation';
  localizationsUpdate: Array<{
    __typename?: 'LocalizationUpdateResponse';
    status: TaskStatus;
    localization: {
      __typename?: 'Localization';
      locale?: string | null;
      translationStatus?: string | null;
      translation?: string | null;
      lastUpdated?: number | null;
      lastUpdatedBy?: string | null;
      canEdit?: boolean | null;
    };
  } | null>;
};

export type SuperuserQueryVariables = Exact<{ [key: string]: never }>;

export type SuperuserQuery = {
  __typename?: 'Query';
  isSuperuser?: {
    __typename?: 'Authorization';
    isAuthorized?: boolean | null;
  } | null;
};

export type AllI18nFamiliesQueryVariables = Exact<{ [key: string]: never }>;

export type AllI18nFamiliesQuery = {
  __typename?: 'Query';
  i18nFamilies: Array<{
    __typename?: 'I18nFamily';
    id: number;
    name: string;
  } | null>;
};

export type GetLocalesQueryVariables = Exact<{
  params: LocaleInput;
}>;

export type GetLocalesQuery = {
  __typename?: 'Query';
  locales: Array<{ __typename?: 'Locale'; locale: string } | null>;
};

export type GetLocalizedPackagesQueryVariables = Exact<{
  params?: InputMaybe<LocalizedPackageInput>;
}>;

export type GetLocalizedPackagesQuery = {
  __typename?: 'Query';
  localizedPackages: Array<{
    __typename?: 'LocalizedPackage';
    id: number;
    name: string;
  } | null>;
};

export type GetOwnersQueryVariables = Exact<{
  params?: InputMaybe<OwnerInput>;
}>;

export type GetOwnersQuery = {
  __typename?: 'Query';
  owners: Array<{ __typename?: 'Owner'; id: number; name: string } | null>;
};

export type GetPackageTypesQueryVariables = Exact<{
  params?: InputMaybe<PackageTypeInput>;
}>;

export type GetPackageTypesQuery = {
  __typename?: 'Query';
  packageTypes: Array<{
    __typename?: 'PackageType';
    packageType?: string | null;
  } | null>;
};

export type PhraseQueryVariables = Exact<{
  params: PhraseLocalizationsInput;
}>;

export type PhraseQuery = {
  __typename?: 'Query';
  phrase: {
    __typename?: 'Phrase';
    id: number;
    name: string;
    anchorTag: string;
    sourceText: string;
    sourceLocale: string;
    context?: string | null;
    version?: string | null;
    created?: number | null;
    createdBy?: string | null;
    lastUpdated?: number | null;
    lastUpdatedBy?: string | null;
    owner?: string | null;
    ownerType?: string | null;
    i18nFamily?: string | null;
    localizedPackage?: string | null;
    localizedPackageType?: string | null;
    localizedPackageOriginId?: string | null;
    localizedPackageTsAceName?: string | null;
    psmsId?: string | null;
    format?: string | null;
    variant?: {
      __typename?: 'Variant';
      id: number;
      category: string;
      name: string;
    } | null;
  };
};

export type PhrasesQueryVariables = Exact<{
  params: PhraseInput;
}>;

export type PhrasesQuery = {
  __typename?: 'Query';
  phrases: {
    __typename?: 'Phrases';
    phraseList: Array<{
      __typename?: 'Phrase';
      id: number;
      name: string;
      sourceText: string;
      sourceLocale: string;
      owner?: string | null;
      i18nFamily?: string | null;
      localizedPackageType?: string | null;
      localizedPackage?: string | null;
      translationsLastUpdated?: number | null;
      status?: string | null;
      format?: string | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      offset: number;
      limit: number;
      count: number;
      totalCount: number;
    };
  };
};

export type PhraseLocalizationsQueryVariables = Exact<{
  params: PhraseLocalizationsInput;
}>;

export type PhraseLocalizationsQuery = {
  __typename?: 'Query';
  phrase: {
    __typename?: 'Phrase';
    id: number;
    variant?: { __typename?: 'Variant'; id: number } | null;
    localizations?: Array<{
      __typename?: 'Localization';
      locale?: string | null;
      translationStatus?: string | null;
      translation?: string | null;
      lastUpdated?: number | null;
      lastUpdatedBy?: string | null;
      canEdit?: boolean | null;
    } | null> | null;
  };
};

export type PhraseVariantsQueryVariables = Exact<{
  params: PhraseLocalizationsInput;
}>;

export type PhraseVariantsQuery = {
  __typename?: 'Query';
  phrase: {
    __typename?: 'Phrase';
    id: number;
    variants?: Array<{
      __typename?: 'Variant';
      id: number;
      category: string;
      name: string;
    } | null> | null;
  };
};

export type SendMessageQueryVariables = Exact<{
  params: SendMessageInput;
}>;

export type SendMessageQuery = {
  __typename?: 'Query';
  sendMessage: { __typename?: 'SendMessageResult'; messageId: string };
};

export type TranslationsQueryVariables = Exact<{
  params: TranslationInput;
}>;

export type TranslationsQuery = {
  __typename?: 'Query';
  translations: {
    __typename?: 'Translations';
    translationList: Array<{
      __typename?: 'Translation';
      phraseId: number;
      phraseName: string;
      sourceText: string;
      format?: string | null;
      localization: {
        __typename?: 'Localization';
        locale?: string | null;
        translation?: string | null;
        lastUpdated?: number | null;
        lastUpdatedBy?: string | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      offset: number;
      limit: number;
      count: number;
      totalCount: number;
    };
  };
};

export const LocalizationsUpdateDocument = gql`
  mutation LocalizationsUpdate(
    $params: PhraseLocalizationsInput!
    $body: String!
  ) {
    localizationsUpdate(params: $params, body: $body) {
      localization {
        locale
        translationStatus
        translation
        lastUpdated
        lastUpdatedBy
        canEdit
      }
      status
    }
  }
`;
export type LocalizationsUpdateMutationFn = Apollo.MutationFunction<
  LocalizationsUpdateMutation,
  LocalizationsUpdateMutationVariables
>;

/**
 * __useLocalizationsUpdateMutation__
 *
 * To run a mutation, you first call `useLocalizationsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocalizationsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [localizationsUpdateMutation, { data, loading, error }] = useLocalizationsUpdateMutation({
 *   variables: {
 *      params: // value for 'params'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useLocalizationsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LocalizationsUpdateMutation,
    LocalizationsUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LocalizationsUpdateMutation,
    LocalizationsUpdateMutationVariables
  >(LocalizationsUpdateDocument, options);
}
export type LocalizationsUpdateMutationHookResult = ReturnType<
  typeof useLocalizationsUpdateMutation
>;
export type LocalizationsUpdateMutationResult =
  Apollo.MutationResult<LocalizationsUpdateMutation>;
export type LocalizationsUpdateMutationOptions = Apollo.BaseMutationOptions<
  LocalizationsUpdateMutation,
  LocalizationsUpdateMutationVariables
>;
export const SuperuserDocument = gql`
  query Superuser {
    isSuperuser {
      isAuthorized
    }
  }
`;

/**
 * __useSuperuserQuery__
 *
 * To run a query within a React component, call `useSuperuserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperuserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperuserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuperuserQuery(
  baseOptions?: Apollo.QueryHookOptions<SuperuserQuery, SuperuserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuperuserQuery, SuperuserQueryVariables>(
    SuperuserDocument,
    options
  );
}
export function useSuperuserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuperuserQuery,
    SuperuserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuperuserQuery, SuperuserQueryVariables>(
    SuperuserDocument,
    options
  );
}
export type SuperuserQueryHookResult = ReturnType<typeof useSuperuserQuery>;
export type SuperuserLazyQueryHookResult = ReturnType<
  typeof useSuperuserLazyQuery
>;
export type SuperuserQueryResult = Apollo.QueryResult<
  SuperuserQuery,
  SuperuserQueryVariables
>;
export const AllI18nFamiliesDocument = gql`
  query AllI18nFamilies {
    i18nFamilies {
      id
      name
    }
  }
`;

/**
 * __useAllI18nFamiliesQuery__
 *
 * To run a query within a React component, call `useAllI18nFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllI18nFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllI18nFamiliesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllI18nFamiliesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllI18nFamiliesQuery,
    AllI18nFamiliesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllI18nFamiliesQuery, AllI18nFamiliesQueryVariables>(
    AllI18nFamiliesDocument,
    options
  );
}
export function useAllI18nFamiliesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllI18nFamiliesQuery,
    AllI18nFamiliesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllI18nFamiliesQuery,
    AllI18nFamiliesQueryVariables
  >(AllI18nFamiliesDocument, options);
}
export type AllI18nFamiliesQueryHookResult = ReturnType<
  typeof useAllI18nFamiliesQuery
>;
export type AllI18nFamiliesLazyQueryHookResult = ReturnType<
  typeof useAllI18nFamiliesLazyQuery
>;
export type AllI18nFamiliesQueryResult = Apollo.QueryResult<
  AllI18nFamiliesQuery,
  AllI18nFamiliesQueryVariables
>;
export const GetLocalesDocument = gql`
  query GetLocales($params: LocaleInput!) {
    locales(params: $params) {
      locale
    }
  }
`;

/**
 * __useGetLocalesQuery__
 *
 * To run a query within a React component, call `useGetLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetLocalesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocalesQuery,
    GetLocalesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocalesQuery, GetLocalesQueryVariables>(
    GetLocalesDocument,
    options
  );
}
export function useGetLocalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocalesQuery,
    GetLocalesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocalesQuery, GetLocalesQueryVariables>(
    GetLocalesDocument,
    options
  );
}
export type GetLocalesQueryHookResult = ReturnType<typeof useGetLocalesQuery>;
export type GetLocalesLazyQueryHookResult = ReturnType<
  typeof useGetLocalesLazyQuery
>;
export type GetLocalesQueryResult = Apollo.QueryResult<
  GetLocalesQuery,
  GetLocalesQueryVariables
>;
export const GetLocalizedPackagesDocument = gql`
  query GetLocalizedPackages($params: LocalizedPackageInput) {
    localizedPackages(params: $params) {
      id
      name
    }
  }
`;

/**
 * __useGetLocalizedPackagesQuery__
 *
 * To run a query within a React component, call `useGetLocalizedPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalizedPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalizedPackagesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetLocalizedPackagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocalizedPackagesQuery,
    GetLocalizedPackagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLocalizedPackagesQuery,
    GetLocalizedPackagesQueryVariables
  >(GetLocalizedPackagesDocument, options);
}
export function useGetLocalizedPackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocalizedPackagesQuery,
    GetLocalizedPackagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLocalizedPackagesQuery,
    GetLocalizedPackagesQueryVariables
  >(GetLocalizedPackagesDocument, options);
}
export type GetLocalizedPackagesQueryHookResult = ReturnType<
  typeof useGetLocalizedPackagesQuery
>;
export type GetLocalizedPackagesLazyQueryHookResult = ReturnType<
  typeof useGetLocalizedPackagesLazyQuery
>;
export type GetLocalizedPackagesQueryResult = Apollo.QueryResult<
  GetLocalizedPackagesQuery,
  GetLocalizedPackagesQueryVariables
>;
export const GetOwnersDocument = gql`
  query GetOwners($params: OwnerInput) {
    owners(params: $params) {
      id
      name
    }
  }
`;

/**
 * __useGetOwnersQuery__
 *
 * To run a query within a React component, call `useGetOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnersQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOwnersQuery, GetOwnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnersQuery, GetOwnersQueryVariables>(
    GetOwnersDocument,
    options
  );
}
export function useGetOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnersQuery,
    GetOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnersQuery, GetOwnersQueryVariables>(
    GetOwnersDocument,
    options
  );
}
export type GetOwnersQueryHookResult = ReturnType<typeof useGetOwnersQuery>;
export type GetOwnersLazyQueryHookResult = ReturnType<
  typeof useGetOwnersLazyQuery
>;
export type GetOwnersQueryResult = Apollo.QueryResult<
  GetOwnersQuery,
  GetOwnersQueryVariables
>;
export const GetPackageTypesDocument = gql`
  query GetPackageTypes($params: PackageTypeInput) {
    packageTypes(params: $params) {
      packageType
    }
  }
`;

/**
 * __useGetPackageTypesQuery__
 *
 * To run a query within a React component, call `useGetPackageTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageTypesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetPackageTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPackageTypesQuery,
    GetPackageTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackageTypesQuery, GetPackageTypesQueryVariables>(
    GetPackageTypesDocument,
    options
  );
}
export function useGetPackageTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPackageTypesQuery,
    GetPackageTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPackageTypesQuery,
    GetPackageTypesQueryVariables
  >(GetPackageTypesDocument, options);
}
export type GetPackageTypesQueryHookResult = ReturnType<
  typeof useGetPackageTypesQuery
>;
export type GetPackageTypesLazyQueryHookResult = ReturnType<
  typeof useGetPackageTypesLazyQuery
>;
export type GetPackageTypesQueryResult = Apollo.QueryResult<
  GetPackageTypesQuery,
  GetPackageTypesQueryVariables
>;
export const PhraseDocument = gql`
  query Phrase($params: PhraseLocalizationsInput!) {
    phrase(params: $params) {
      id
      name
      anchorTag
      sourceText
      sourceLocale
      context
      version
      created
      createdBy
      lastUpdated
      lastUpdatedBy
      owner
      ownerType
      i18nFamily
      localizedPackage
      localizedPackageType
      localizedPackageOriginId
      localizedPackageTsAceName
      psmsId
      format
      variant {
        id
        category
        name
      }
    }
  }
`;

/**
 * __usePhraseQuery__
 *
 * To run a query within a React component, call `usePhraseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhraseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhraseQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePhraseQuery(
  baseOptions: Apollo.QueryHookOptions<PhraseQuery, PhraseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhraseQuery, PhraseQueryVariables>(
    PhraseDocument,
    options
  );
}
export function usePhraseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhraseQuery, PhraseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhraseQuery, PhraseQueryVariables>(
    PhraseDocument,
    options
  );
}
export type PhraseQueryHookResult = ReturnType<typeof usePhraseQuery>;
export type PhraseLazyQueryHookResult = ReturnType<typeof usePhraseLazyQuery>;
export type PhraseQueryResult = Apollo.QueryResult<
  PhraseQuery,
  PhraseQueryVariables
>;
export const PhrasesDocument = gql`
  query Phrases($params: PhraseInput!) {
    phrases(params: $params) {
      phraseList {
        id
        name
        sourceText
        sourceLocale
        owner
        i18nFamily
        localizedPackageType
        localizedPackage
        translationsLastUpdated
        status
        format
      }
      pageInfo {
        offset
        limit
        count
        totalCount
      }
    }
  }
`;

/**
 * __usePhrasesQuery__
 *
 * To run a query within a React component, call `usePhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhrasesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePhrasesQuery(
  baseOptions: Apollo.QueryHookOptions<PhrasesQuery, PhrasesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhrasesQuery, PhrasesQueryVariables>(
    PhrasesDocument,
    options
  );
}
export function usePhrasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhrasesQuery, PhrasesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhrasesQuery, PhrasesQueryVariables>(
    PhrasesDocument,
    options
  );
}
export type PhrasesQueryHookResult = ReturnType<typeof usePhrasesQuery>;
export type PhrasesLazyQueryHookResult = ReturnType<typeof usePhrasesLazyQuery>;
export type PhrasesQueryResult = Apollo.QueryResult<
  PhrasesQuery,
  PhrasesQueryVariables
>;
export const PhraseLocalizationsDocument = gql`
  query PhraseLocalizations($params: PhraseLocalizationsInput!) {
    phrase: phraseLocalizations(params: $params) {
      id
      variant {
        id
      }
      localizations {
        locale
        translationStatus
        translation
        lastUpdated
        lastUpdatedBy
        canEdit
      }
    }
  }
`;

/**
 * __usePhraseLocalizationsQuery__
 *
 * To run a query within a React component, call `usePhraseLocalizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhraseLocalizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhraseLocalizationsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePhraseLocalizationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PhraseLocalizationsQuery,
    PhraseLocalizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PhraseLocalizationsQuery,
    PhraseLocalizationsQueryVariables
  >(PhraseLocalizationsDocument, options);
}
export function usePhraseLocalizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PhraseLocalizationsQuery,
    PhraseLocalizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PhraseLocalizationsQuery,
    PhraseLocalizationsQueryVariables
  >(PhraseLocalizationsDocument, options);
}
export type PhraseLocalizationsQueryHookResult = ReturnType<
  typeof usePhraseLocalizationsQuery
>;
export type PhraseLocalizationsLazyQueryHookResult = ReturnType<
  typeof usePhraseLocalizationsLazyQuery
>;
export type PhraseLocalizationsQueryResult = Apollo.QueryResult<
  PhraseLocalizationsQuery,
  PhraseLocalizationsQueryVariables
>;
export const PhraseVariantsDocument = gql`
  query PhraseVariants($params: PhraseLocalizationsInput!) {
    phrase: phraseVariants(params: $params) {
      id
      variants {
        id
        category
        name
      }
    }
  }
`;

/**
 * __usePhraseVariantsQuery__
 *
 * To run a query within a React component, call `usePhraseVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhraseVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhraseVariantsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePhraseVariantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PhraseVariantsQuery,
    PhraseVariantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhraseVariantsQuery, PhraseVariantsQueryVariables>(
    PhraseVariantsDocument,
    options
  );
}
export function usePhraseVariantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PhraseVariantsQuery,
    PhraseVariantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhraseVariantsQuery, PhraseVariantsQueryVariables>(
    PhraseVariantsDocument,
    options
  );
}
export type PhraseVariantsQueryHookResult = ReturnType<
  typeof usePhraseVariantsQuery
>;
export type PhraseVariantsLazyQueryHookResult = ReturnType<
  typeof usePhraseVariantsLazyQuery
>;
export type PhraseVariantsQueryResult = Apollo.QueryResult<
  PhraseVariantsQuery,
  PhraseVariantsQueryVariables
>;
export const SendMessageDocument = gql`
  query SendMessage($params: SendMessageInput!) {
    sendMessage(params: $params) {
      messageId
    }
  }
`;

/**
 * __useSendMessageQuery__
 *
 * To run a query within a React component, call `useSendMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendMessageQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSendMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SendMessageQuery,
    SendMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendMessageQuery, SendMessageQueryVariables>(
    SendMessageDocument,
    options
  );
}
export function useSendMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SendMessageQuery,
    SendMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendMessageQuery, SendMessageQueryVariables>(
    SendMessageDocument,
    options
  );
}
export type SendMessageQueryHookResult = ReturnType<typeof useSendMessageQuery>;
export type SendMessageLazyQueryHookResult = ReturnType<
  typeof useSendMessageLazyQuery
>;
export type SendMessageQueryResult = Apollo.QueryResult<
  SendMessageQuery,
  SendMessageQueryVariables
>;
export const TranslationsDocument = gql`
  query Translations($params: TranslationInput!) {
    translations(params: $params) {
      translationList {
        phraseId
        phraseName
        sourceText
        format
        localization {
          locale
          translation
          lastUpdated
          lastUpdatedBy
        }
      }
      pageInfo {
        offset
        limit
        count
        totalCount
      }
    }
  }
`;

/**
 * __useTranslationsQuery__
 *
 * To run a query within a React component, call `useTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslationsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useTranslationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TranslationsQuery,
    TranslationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TranslationsQuery, TranslationsQueryVariables>(
    TranslationsDocument,
    options
  );
}
export function useTranslationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TranslationsQuery,
    TranslationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TranslationsQuery, TranslationsQueryVariables>(
    TranslationsDocument,
    options
  );
}
export type TranslationsQueryHookResult = ReturnType<
  typeof useTranslationsQuery
>;
export type TranslationsLazyQueryHookResult = ReturnType<
  typeof useTranslationsLazyQuery
>;
export type TranslationsQueryResult = Apollo.QueryResult<
  TranslationsQuery,
  TranslationsQueryVariables
>;
