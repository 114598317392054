import { makeElement, makeText } from './objectToXml';

const makeMetaEntries = (metaEntriesElem) => {
  return metaEntriesElem?.map((meta) => {
    const { type } = meta.attributes; //type as key
    const { text: value = '' } = meta?.elements[0]; //text as val
    return { type, value };
  });
};

const makeMetaGroups = (metaGroupsElem) => {
  return metaGroupsElem?.map((group) => {
    const entries = makeMetaEntries(group.elements);
    return Object.assign(
      {},
      { entries },
      { additionalAttributes: group.attributes }
    );
  });
};

/**
 * Extracted metadata structure example
 const sampleMetadata = {
  //maps to mda:metadata
  groups: [
    //maps to mda:metaGroup
    {
      entries: [
        //each entry maps to mda:meta
        {
          type: 'plural:appleCount',
          value: '=0',
        },
        {
          type: 'select:gender',
          value: 'male',
        },
      ],
      attributes: {
        id: '1', //optional
        category: 'linguistic variables', //optional
        appliesTo: 'foo', //optional
      },
    },
    {},
  ],

  attributes: {
    //optional
    'xmlns:mda': 'urn:oasis:names:tc:xliff:metadata:2.0', //key value pairs
  },
};
 **/
export function extractMetadata(metadataElem) {
  const groups = makeMetaGroups(metadataElem.elements);
  return Object.assign(
    {},
    { groups },
    { additionalAttributes: metadataElem.attributes }
  );
}

const createMetaEntries = (entries) => {
  return entries?.map((entry) => {
    const additionalAttributes = { type: entry.type };
    const content = makeText(entry.value);
    return makeElement('mda:meta', additionalAttributes, [content]);
  });
};

const createMetaGroups = (groups) => {
  return groups?.map((group) => {
    const additionalAttributes = group.additionalAttributes;
    const entries = createMetaEntries(group.entries);
    return makeElement('mda:metaGroup', additionalAttributes, entries);
  });
};

export function createMetadataTag(metadata) {
  const additionalAttributes = metadata.additionalAttributes;
  const metaGroups = createMetaGroups(metadata.groups);
  return makeElement('mda:metadata', additionalAttributes, metaGroups);
}
