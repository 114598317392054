import React, { useEffect } from 'react';
import { KatModal } from '@amzn/katal-react';

type Props = React.ComponentProps<typeof KatModal>;

const NoDismissKatModal: React.FC<Props> = (props: Props) => {
  const modalRef = React.createRef<any>();

  const escapeKeyHandler = (evt: KeyboardEvent) => {
    if (evt.key === 'Escape') {
      evt.stopImmediatePropagation();
      evt.stopPropagation();
    }
  };

  const mouseUpHandler = (evt: any) => {
    evt.stopImmediatePropagation();
    evt.stopPropagation();
  };

  useEffect(() => {
    //catching escape key needs to be on document since that's where Katal adds the listener
    const shadowDiv = modalRef.current.shadowRoot;
    if (!shadowDiv) return;

    window.addEventListener('keydown', escapeKeyHandler, { capture: true });
    shadowDiv.addEventListener('mouseup', mouseUpHandler, { capture: true });

    return () => {
      window.removeEventListener('keydown', escapeKeyHandler, {
        capture: true,
      });
      shadowDiv.removeEventListener('mouseup', mouseUpHandler, {
        capture: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <KatModal {...props} ref={modalRef} />;
};

export default NoDismissKatModal;
