const marketplaceMock = [
  {
    name: 'Marketplace Mock1',
    value: '001',
  },
  {
    name: 'Marketplace Mock2',
    value: '002',
  },
  {
    name: 'Marketplace Mock3',
    value: '003',
  },
  {
    name: 'Marketplace Mock4',
    value: '004',
  },
];

export default marketplaceMock;
