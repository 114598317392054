import { KatDataTable } from '@amzn/katal-react';
import { Phrase } from 'src/generated/graphql';
import {
  cellFormatterConstructor,
  headerFormatterConstructor,
  textExpanderCellFormatterConstructor,
  textOverflowHoverCellFormatterConstructor,
} from 'src/components/common/util/KatTableColumnHelpers';
import { TranslateFn } from 'src/i18next-arb-shim/useTranslation';
import { getClientNameLabel } from 'src/components/common/util/PackageHelpers';

const headerFormatter = headerFormatterConstructor('lui-string-list-header');
const cellFormatter = cellFormatterConstructor('lui-string-list-item');
const textExpanderCellFormatter = textExpanderCellFormatterConstructor(
  'lui-string-list-item'
);
const textOverflowHoverCellFormatter =
  textOverflowHoverCellFormatterConstructor('lui-string-list-item');

function formatDetailsButton(
  rowIndex: number,
  rowData: Phrase,
  previouslyRenderedElement: any,
  t: TranslateFn,
  history: any
): KatDataTable.FormatterConfig {
  const element = previouslyRenderedElement
    ? previouslyRenderedElement
    : document.createElement('kat-link');

  // This is to update the row af an element if it got updated by the deletion of another element
  element.id = rowIndex;

  const url = `/strings/${rowData.id}`;

  element.href = url;
  element.label = t('string-list-action-details');
  element.onclick = (event: React.MouseEvent) => {
    if (!url.length) {
      return;
    }
    event.preventDefault();
    history.push(url, {
      from: `/${history.location.search}`,
    });
  };

  return { element: element, attributes: { class: 'table-action-col' } };
}

export default function (
  t: TranslateFn,
  history: any
): KatDataTable.ColumnConfiguration[] {
  return [
    {
      label: t('column-id'),
      property: 'name',
      sortable: true,
      headerFormatter: headerFormatter(t('column-id'), 'name', 'table-id-col'),
      formatter: textOverflowHoverCellFormatter('name', 'table-id-col'),
    },
    {
      label: t('column-source-text'),
      property: 'sourceText',
      headerFormatter: headerFormatter(
        t('column-source-text'),
        'sourceText',
        'table-source-text-col'
      ),
      formatter: textExpanderCellFormatter(
        'sourceText',
        'table-source-text-col'
      ),
    },
    {
      label: t('string-field-i18n-family'),
      property: 'i18nFamily',
      sortable: true,
      headerFormatter: headerFormatter(
        t('string-field-i18n-family'),
        'i18nFamily',
        'table-i18n-family-col'
      ),
      formatter: cellFormatter('i18nFamily', 'table-i18n-family-col'),
    },
    {
      label: t('string-field-owner'),
      property: 'owner',
      sortable: true,
      headerFormatter: headerFormatter(
        t('string-field-owner'),
        'owner',
        'table-owner-col'
      ),
      formatter: cellFormatter('owner', 'table-owner-col'),
    },
    {
      label: t('column-client-name'),
      property: 'localizedPackageType',
      sortable: false,
      headerFormatter: headerFormatter(
        t('column-client-name'),
        'localizedPackageType',
        'table-client-name-col'
      ),
      formatter: cellFormatter(
        'localizedPackageType',
        'table-client-name-col',
        'ltr',
        (text) => getClientNameLabel(text, t)
      ),
    },
    {
      label: t('column-package-name'),
      property: 'localizedPackage',
      sortable: true,
      headerFormatter: headerFormatter(
        t('column-package-name'),
        'localizedPackage',
        'table-pkg-col'
      ),
      formatter: cellFormatter('localizedPackage', 'table-pkg-col'),
    },
    {
      label: t('column-translations-last-updated'),
      property: 'translationsLastUpdated',
      sortable: true,
      headerFormatter: headerFormatter(
        t('column-translations-last-updated'),
        'translationsLastUpdated',
        'table-translations-last-updated-col'
      ),
      formatter: cellFormatter(
        'translationsLastUpdated',
        'table-translations-last-updated-col',
        'auto'
      ),
    },
    {
      label: t('column-string-status'),
      property: 'status',
      sortable: false,
      headerFormatter: headerFormatter(
        t('column-string-status'),
        'status',
        'table-status-col'
      ),
      formatter: cellFormatter('status', 'table-status-col'),
    },
    {
      label: t('column-action'),
      property: 'action',
      formatter: (
        rowIndex: number,
        rowData: any,
        previouslyRenderedElement: any
      ) =>
        formatDetailsButton(
          rowIndex,
          rowData,
          previouslyRenderedElement,
          t,
          history
        ),
    },
  ];
}
