import { useLocalizationContext } from '@amzn/react-arb-tools';
import { useContext } from 'react';
import DefaultBundleContext from 'src/i18next-arb-shim/DefaultBundleContext';

export type TranslateFn = (id: string, params?: Record<string, any>) => string;
export type I18nextArbShim = {
  t: TranslateFn;
  language: string;
};

/**
 * Provide a shim around ARB JS APIs that's identical to i18next APIs.
 */
export function useTranslation() {
  const bundle = useContext(DefaultBundleContext);
  const { localizationContext } = useLocalizationContext();

  if (bundle === undefined) {
    throw new Error('No default bundle provided for redirecting i18next calls');
  }

  const t = (id: string, params?: Record<string, any>) =>
    params
      ? bundle.formatMessage(id, params)
      : (bundle.getMessage(id) as string);

  const i18n: I18nextArbShim = {
    t,
    language: localizationContext.getLocale(),
  };
  Object.defineProperty(i18n, 'language', {
    get: () =>
      localizationContext.getLocale() || localizationContext.getDefaultLocale(),
  });

  return {
    t,
    i18n,
  };
}
