import React, { useState } from 'react';
import { KatBox, KatCheckbox } from '@amzn/katal-react';
import './FilterModal.scss';
import FuzzySearchInput from 'src/components/common/ui/FuzzySearchInput';
import { uniqBy, concat, filter, includes } from 'lodash';
import { onInfinityScroll } from 'src/components/common/ui/TypeaheadList/InfinityScroll';
import IsLoadingItem from 'src/components/common/ui/TypeaheadList/IsLoadingItem';
import { Criteria } from './FilterModal';

type Props = {
  id: string;
  label: string;
  useSearch: boolean;
  onTextChange?: (text: string) => void;
  onScrollDown?: () => void;
  isLoading?: boolean;
  isSearching?: boolean;
  placeholder?: string;
  data: any;
  selected?: Criteria[];
  onChange: (e: KatCheckbox.ChangeEvent) => void;
};

const fuseOptions = {
  keys: ['name', 'id'],
};

const FilterPane: React.FC<Props> = (props: Props) => {
  const [options, setOptions] = useState(props.data);

  //should we render loading/end of results items for dynamic content
  const hasDynamicContent = !!props.onScrollDown;

  const selectedSet = new Set(props.selected?.map((item) => item.id));

  // List selected criteria first unless searching
  const sortedOptions =
    props.selected && !props.isSearching
      ? [
          ...props.selected,
          ...options.filter((a: any) => !selectedSet.has(a.id)),
        ]
      : options;

  const optionArray = sortedOptions.map((item: any, idx: number) => {
    const checkBoxProps = {
      id: props.id + '-item-' + idx,
      value: item?.id,
      label: item?.name,
      onChange: props.onChange,
      ...(selectedSet.has(item?.id) ? { checked: true } : {}),
    };

    return <KatCheckbox {...checkBoxProps} key={item?.id} />;
  });

  const onSearchResultUpdate = (filteredOptions: any[]) => {
    if (filteredOptions.length === props.data.length) {
      setOptions(props.data);
      return;
    }
    const selectedOptions = filter(props.data, (item: any) =>
      includes(props.selected, item.id)
    );
    setOptions(uniqBy(concat(selectedOptions, filteredOptions), 'id'));
  };

  return (
    <div id={props.id} className="filter-pane">
      <h2> {props.label}</h2>
      <KatBox variant="white-shadow">
        <div
          className="filter-pane-inner"
          onScroll={(e) => onInfinityScroll(e, props.onScrollDown)}
        >
          {props.useSearch && (
            <FuzzySearchInput
              placeholder={props.placeholder}
              items={props.data}
              onTextChange={props.onTextChange}
              fuseOptions={fuseOptions}
              onSearchResultUpdate={onSearchResultUpdate}
            />
          )}
          {optionArray}
          {hasDynamicContent && <IsLoadingItem isLoading={!!props.isLoading} />}
        </div>
      </KatBox>
    </div>
  );
};

export default React.memo(FilterPane);
