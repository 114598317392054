export const statesAfterDOMContentLoaded = ['complete', 'interactive'];

function afterDOMContentLoaded() {
  return statesAfterDOMContentLoaded.indexOf(document.readyState) >= 0;
}

export function whenDOMContentLoaded(listener: () => void) {
  if (afterDOMContentLoaded()) {
    listener();
  } else {
    document.addEventListener('DOMContentLoaded', listener);
  }
}
