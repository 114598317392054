import React from 'react';
import { filter, some } from 'lodash';
import {
  KatColumn,
  KatProgressbar,
  KatRow,
  KatPopover,
  KatLink,
} from '@amzn/katal-react';
import { Localization } from 'src/generated/graphql';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import TranslationStatus from './TranslationStatusEnum';
import styles from './TranslationProgress.module.scss';

type Props = {
  localizations: Localization[];
};

const TranslationProgress: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const getCountByStatus = (status: string) => {
    const localizationByStatus = filter(
      props.localizations,
      (row) => row?.translationStatus === status
    );
    return localizationByStatus?.length;
  };

  const getPercent = () => {
    const completedLocalizations = filter(
      props.localizations,
      (row) => row?.translationStatus === TranslationStatus.Completed
    );
    const progress = parseFloat(
      (completedLocalizations.length / props.localizations.length).toFixed(2)
    );

    return progress * 100;
  };

  const hasFailures = () =>
    some(
      props.localizations,
      (row) => row.translationStatus === TranslationStatus.Failed
    );

  return (
    <KatRow className={styles.progressBarContainer}>
      <KatColumn md={6}>
        <div className={styles.progressBarLabel}>
          <h3>{t('translation-progress-header')}</h3>
          <KatPopover triggerType="hover" variant="tooltip">
            <span slot="trigger" className={styles.helpLabel}>
              {t('translation-help-popover-label')}
            </span>
            <KatLink
              href="https://w.amazon.com/bin/view/INTech/Panther/I18nFamily/#Hi18nFamilies"
              label={t('translation-help-instructions')}
              target="_blank"
            />
          </KatPopover>
        </div>
        <KatProgressbar value={getPercent()} />
      </KatColumn>
      <KatColumn md={6} className={styles.statusContainer}>
        <div className={styles.status}>
          <h4>{t('translation-progress-complete')}</h4>
          <div>{getCountByStatus(TranslationStatus.Completed)}</div>
        </div>
        <div className={styles.status}>
          <h4>{t('translation-progress-in-progress')}</h4>
          <div>{getCountByStatus(TranslationStatus.InProgress)}</div>
        </div>
        <div className={styles.status}>
          <h4>{t('translation-progress-not-started')}</h4>
          <div>{getCountByStatus(TranslationStatus.StatusUnknown)}</div>
        </div>
        <div
          className={`${styles.status} ${
            hasFailures() ? styles.statusRed : ''
          }`}
        >
          <h4>{t('translation-progress-failed')}</h4>
          <div>{getCountByStatus(TranslationStatus.Failed)}</div>
        </div>
      </KatColumn>
    </KatRow>
  );
};

export default TranslationProgress;
