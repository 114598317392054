import mocker from 'mocker-data-generator';
import { PhraseVariantsDocument } from 'src/generated/graphql';
import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';
import variant from 'src/api/mocks/types/variant';
import phrase from 'src/api/mocks/types/phrase';
import { MockObject } from '../mocks';
import { extend } from 'lodash';

const phraseWithVariants = extend({}, phrase, {
  variants: {
    hasMany: 'fakedVariants',
    min: 1,
    max: 3,
    unique: true,
  },
});

export const phraseVariantsMock = (async (): Promise<
  MockObject | MockObject[]
> => {
  const { fakedPhrases } = await mocker()
    .schema('fakedVariants', variant, 3)
    .schema('fakedPhrases', phraseWithVariants, 1)
    .build();
  return {
    request: {
      query: PhraseVariantsDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        phrase: fakedPhrases[0],
      },
    },
  };
})();
