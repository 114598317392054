export default {
  id: {
    faker: 'datatype.number',
  },
  category: {
    faker: 'lorem.word',
  },
  name: {
    faker: 'company.companyName',
  },
};
