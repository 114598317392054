import React from 'react';
import { KatFlashbar } from '@amzn/katal-react';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import { METRIC } from 'src/metrics';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import {
  LUIError,
  unwrapGqlError,
  getLinkMetadataFromError,
} from './ErrorHandlerHelpers';

export const SIM_LINK =
  'https://sim.amazon.com/issues/create?assignedFolder=86dc7268-065c-4c0c-9ffa-7f21794ec826';

type Props = {
  id?: string;
  header: string;
  description?: string;
  error: ApolloError;
  publisher: any;
};

const ErrorHandler: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { errorType, userFriendlyMessage } = unwrapGqlError(props.error);
  const { linkHref, linkLabel, onLinkClick } = getLinkMetadataFromError(
    props.error,
    props.publisher
  );

  props.publisher.publishCounterMonitor(METRIC.ERROR, 1);
  props.publisher.publishCounterMonitor(METRIC.ERROR_CODE + errorType, 1);
  props.publisher.publishStringTruncate(METRIC.ERROR_MSG, props.error.message);

  console.log('GQL Error Msg: ', JSON.stringify(props.error));

  switch (errorType) {
    case LUIError.NOT_FOUND:
      return (
        <KatFlashbar
          id={props.id}
          header={userFriendlyMessage}
          variant="info"
        />
      );
    case LUIError.FORBIDDEN: //Fall-through (link props handled via default case)
    case LUIError.INTERNAL_ERROR: //Fall-through
    default:
      return (
        <KatFlashbar
          id={props.id}
          header={props.header || t('error-flashbar-header-default')}
          description={props.description || userFriendlyMessage}
          variant="danger"
          linkLabel={linkLabel || t('create-sim-ticket-link')}
          linkHref={linkHref || SIM_LINK}
          onLinkClick={
            onLinkClick ||
            (() => props.publisher.publishCounterMonitor(METRIC.SIM_CLICK, 1))
          }
        />
      );
  }
};

export default ErrorHandler;
