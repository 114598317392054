import React from 'react';

type Props = {
  id: string;
  title: string;
  contents?: any[];
};

const AccordionHeading: React.FC<Props> = (props: Props) => {
  if (props.contents && props.contents.length > 0) {
    return (
      <p slot="label">
        {props.title} ({props.contents.length})
      </p>
    );
  } else {
    return <p slot="label">{props.title}</p>;
  }
};

export default AccordionHeading;
