import { KatDataTable } from '@amzn/katal-react';
import {
  rtlAppliedCellFormatterConstructor,
  headerFormatterConstructor,
  cellFormatterConstructor,
} from 'src/components/common/util/KatTableColumnHelpers';
import { Localization } from 'src/generated/graphql';
import { TranslationEditHandler } from 'src/views/StringDetailView/StringDetailView';
import { TranslateFn } from 'src/i18next-arb-shim/useTranslation';

const headerFormatter = headerFormatterConstructor('lui-translation-header');
const cellFormatter = cellFormatterConstructor('lui-translation-item');
const rtlAppliedCellFormatter = rtlAppliedCellFormatterConstructor(
  'lui-translation-item'
);

export type IndexedLocalization = Localization & { localizationIndex: number };

function formatEditButton(
  rowIndex: number,
  rowData: IndexedLocalization,
  previouslyRenderedElement: any,
  editHandler: TranslationEditHandler,
  t: TranslateFn
): KatDataTable.FormatterConfig {
  const element = previouslyRenderedElement
    ? previouslyRenderedElement
    : document.createElement('kat-button');

  element.id = `lui-translation-${rowIndex}-edit-btn`;
  element.disabled = !rowData.canEdit;
  element.variant = 'primary';
  element.label = t('column-single-edit');
  element.addEventListener('click', () => {
    editHandler(rowData.localizationIndex, rowData);
  });
  return { element: element, attributes: { class: 'table-action-col' } };
}

export default function (
  editHandler: TranslationEditHandler,
  t: TranslateFn
): KatDataTable.ColumnConfiguration[] {
  return [
    {
      label: t('column-locale'),
      property: 'locale',
      sortable: true,
      headerFormatter: headerFormatter(
        t('column-locale'),
        'locale',
        'table-locale-col'
      ),
      formatter: cellFormatter('locale', 'table-locale-col'),
    },
    {
      label: t('column-status'),
      property: 'translationStatus',
      sortable: true,
      headerFormatter: headerFormatter(
        t('column-status'),
        'translationStatus',
        'table-status-col'
      ),
      formatter: cellFormatter('translationStatus', 'table-status-col'),
    },
    {
      label: t('column-last-updated'),
      property: 'lastUpdated',
      sortable: true,
      headerFormatter: headerFormatter(
        t('column-last-updated'),
        'lastUpdated',
        'table-last-updated-col'
      ),
      formatter: cellFormatter('lastUpdated', 'table-last-updated-col', 'auto'),
    },
    {
      label: t('column-last-updated-by'),
      property: 'lastUpdatedBy',
      sortable: true,
      headerFormatter: headerFormatter(
        t('column-last-updated-by'),
        'lastUpdatedBy',
        'table-last-updated-by-col'
      ),
      formatter: cellFormatter(
        'lastUpdatedBy',
        'table-last-updated-by-col',
        'auto'
      ),
    },
    {
      label: t('column-translation'),
      property: 'translation',
      headerFormatter: headerFormatter(
        t('column-translation'),
        'translation',
        'table-translation-col'
      ),
      formatter: rtlAppliedCellFormatter(
        'translation',
        'table-translation-col',
        'locale'
      ),
    },
    {
      formatter: (
        rowIndex: number,
        rowData: any,
        previouslyRenderedElement: any
      ) =>
        formatEditButton(
          rowIndex,
          rowData,
          previouslyRenderedElement,
          editHandler,
          t
        ),
    },
  ];
}
