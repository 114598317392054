const POST_AUTH_EVENT_CLASS =
  'com.amazon.felinemonolith.integration.privatepermissions.model.PrivatePermissionsPostAuthEvent';
const PRIVATE_PERMISSIONS_QUEUE = 'PrivatePermissionsEvent';

interface PostAuthPermissionsEvent {
  data: {
    username: string;
    '@class': typeof POST_AUTH_EVENT_CLASS;
  };
}

interface PostAuthPermissionsParams {
  queueName: typeof PRIVATE_PERMISSIONS_QUEUE;
  message: string;
}

export const getPostAuthPermissionsEvent = (
  username: string
): PostAuthPermissionsEvent => ({
  data: {
    username,
    '@class': POST_AUTH_EVENT_CLASS,
  },
});

export const getPostAuthPermissionsParams = (
  username: string
): PostAuthPermissionsParams => ({
  queueName: PRIVATE_PERMISSIONS_QUEUE,
  message: JSON.stringify(getPostAuthPermissionsEvent(username)),
});
