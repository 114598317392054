/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo, useEffect, useRef, useState } from 'react';
import Search from 'src/components/Search/Search';
import {
  Criteria,
  FilterCategory,
} from 'src/components/Preferences/FilterModal/FilterModal';
import Tags, { Tag } from 'src/components/common/ui/Tags/Tags';
import columnConfigConstructor from './ColumnConfig';
import { getDefaultFilter } from 'src/api/preferencesClient';
import ErrorHandler from 'src/components/common/ui/ErrorHandler/ErrorHandler';
import './StringList.scss';
import {
  KatDataTable,
  KatPagination,
  KatRow,
  KatSpinner,
} from '@amzn/katal-react';
import { useHistory } from 'react-router-dom';
import { isEmpty, map } from 'lodash';
import debounce from 'lodash.debounce';
import {
  OwnerInput,
  LocalizedPackageInput,
  PackageTypeInput,
  PhraseInput,
  Scalars,
  useGetOwnersQuery,
  useGetLocalizedPackagesQuery,
  useGetPackageTypesQuery,
  usePhrasesQuery,
  PackageType,
} from 'src/generated/graphql';
import rootMetricPublisher, { METRIC, timerStopwatchMetric } from 'src/metrics';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import {
  idsToJsonString,
  removeFalsyAttrs,
} from 'src/components/common/util/JSToolbelt';
import { SuperuserContext } from 'src/components/auth/SuperuserContext';
import {
  updateFilterFromSearchToken,
  useDocumentTitle,
} from 'src/components/common/util/StringListHelpers';
import { getLocalizedTimestamp } from 'src/components/common/util/TimestampHelpers';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import FilterModal from 'src/components/Preferences/FilterModal/FilterModal';
import Filter from '../Filter/Filter';
import { getTranslationStatuses } from '../StringDetail/TranslationProgress/TranslationStatusEnum';
import {
  EMPTY_TOKEN,
  FilterField,
  stringListSearchProperties,
} from '../Search/SearchConstants';
import { useQueryResult } from '../common/provider/QueryResultProvider';
import Pagination from '../common/ui/Pagination/Pagination';
import {
  UrlParamKeys,
  getCriteriaFromUrlParams,
  getPhraseInputFromUrlSearchParams,
  getUrlSearchParam,
  setUrlFromPhraseInput,
} from '../common/util/URLHelpers';
import { getClientNameLabel } from '../common/util/PackageHelpers';

const metricPublisher: KatalMetricsPublisher =
  rootMetricPublisher.newChildActionPublisherForMethod('StringList');

export const DEFAULT_OFFSET = 0;
export const DEFAULT_PAGE_SIZE = 20;
export const FILTER_REMOVAL_DEBOUNCE_TIMEOUT = 1500;
//AppSync has a bug that doesn't take default query params defined in schema, so need to do it here
const ownerQueryParams: OwnerInput = {
  limit: DEFAULT_PAGE_SIZE,
  offset: DEFAULT_OFFSET,
};
const localizedPackageQueryParams: LocalizedPackageInput = {
  limit: DEFAULT_PAGE_SIZE,
};
const packageTypeQueryParams: PackageTypeInput = {
  limit: DEFAULT_PAGE_SIZE,
};

export const getPage = (
  offset: number | Scalars['Int'] = DEFAULT_OFFSET,
  pageSize: number | Scalars['Int'] = DEFAULT_PAGE_SIZE
) => {
  return Math.floor(offset / pageSize) + 1;
};

export const getOffset = (
  page: number | Scalars['Int'] = DEFAULT_OFFSET,
  pageSize: number | Scalars['Int'] = DEFAULT_PAGE_SIZE
) => {
  return pageSize * (page - 1);
};

type Props = {
  id: string;
};

const StringList: React.FC<Props> = (props: Props) => {
  /* Metrics */
  const initLatencyMetric = timerStopwatchMetric(METRIC.LOADING_LATENCY);
  const timeSpentMetric = timerStopwatchMetric(METRIC.TIME_SPENT);

  useDocumentTitle('LUI');
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const urlParams = new URLSearchParams(history?.location.search);
  urlParams.sort();
  history.replace({ search: urlParams.toString() });

  ownerQueryParams.ids = idsToJsonString(urlParams.getAll('owners'));
  localizedPackageQueryParams.ids = idsToJsonString(
    urlParams.getAll('localizedPackages')
  );

  /* React Hooks */
  const { isSudoMode, setIsSudoMode, isSuperuser, isSuperUserLoading } =
    useContext(SuperuserContext);

  const [queryInput, setQueryInput] = useState(
    getPhraseInputFromUrlSearchParams(urlParams)
  );
  const [filter, setFilter] = useState<FilterCategory>({});
  const [searchToken, setSearchToken] = useState(EMPTY_TOKEN);
  const [caseSensitive, setCaseSensitive] = useState(
    !!queryInput.caseSensitive
  );
  const [exactMatch, setExactMatch] = useState(!!queryInput.exactMatch);

  const sorting = useRef<KatDataTable.SortState>({});
  const tableRef: any = useRef(null);

  /* Gql Hooks */
  const { data, loading, error } = usePhrasesQuery({
    variables: {
      params: {
        ...queryInput,
        packageTypes: queryInput.packageTypes
          ? encodeURIComponent(queryInput.packageTypes)
          : null,
        sourceTexts: queryInput.sourceTexts
          ? encodeURIComponent(queryInput.sourceTexts)
          : null,
        psmsIds: queryInput.psmsIds
          ? encodeURIComponent(queryInput.psmsIds)
          : null,
        stringIds: queryInput.stringIds
          ? encodeURIComponent(queryInput.stringIds)
          : null,
      },
    },
    errorPolicy: 'all',
  });
  const {
    data: i18nData,
    loading: i18nLoading,
    error: i18nError,
    fetch: fetchI18nFamilies,
  } = useQueryResult();
  if (!i18nData && !i18nLoading && !i18nError) {
    fetchI18nFamilies();
  }
  const { data: ownerData, loading: ownerLoading } = useGetOwnersQuery({
    variables: { params: ownerQueryParams },
  });
  const { data: localizedPackageData, loading: localizedPackageLoading } =
    useGetLocalizedPackagesQuery({
      variables: { params: localizedPackageQueryParams },
    });
  const { data: packageTypeData, loading: packageTypeLoading } =
    useGetPackageTypesQuery({
      variables: { params: packageTypeQueryParams },
    });
  const statusData = getTranslationStatuses();

  /* Handlers */
  const setQueryInputFromFilter = (filter: FilterCategory) => {
    const i18nFamilies = JSON.stringify(
      filter.i18nFamily?.map((item) => item.id)
    ) as PhraseInput['i18nFamilies'];
    const owners = JSON.stringify(
      filter.owners?.map((item) => item.id)
    ) as PhraseInput['owners'];
    const localizedPackages = JSON.stringify(
      filter.localizedPackages?.map((item) => item.id)
    ) as PhraseInput['localizedPackages'];
    const packageTypes = JSON.stringify(
      filter.packageTypes?.map((item) => item.id)
    ) as PhraseInput['packageTypes'];
    const statuses = JSON.stringify(
      filter.statuses?.map((item) => item.id)
    ) as PhraseInput['statuses'];
    const sourceTexts = JSON.stringify(
      filter.sourceTexts?.map((item) => item.name)
    ) as PhraseInput['sourceTexts'];
    const psmsIds = JSON.stringify(
      filter.psmsIds?.map((item) => item.name)
    ) as PhraseInput['psmsIds'];
    const stringIds = JSON.stringify(
      filter.stringIds?.map((item) => item.name)
    ) as PhraseInput['stringIds'];

    const updatedQueryInput: PhraseInput = removeFalsyAttrs({
      ...queryInput,
      i18nFamilies,
      owners,
      localizedPackages,
      packageTypes,
      statuses,
      sourceTexts,
      psmsIds,
      stringIds,
    });

    setQueryInput(updatedQueryInput);
  };

  useEffect(() => {
    if (
      !i18nData ||
      ownerLoading ||
      localizedPackageLoading ||
      packageTypeLoading
    )
      return;

    const i18nFamily: Criteria[] = getCriteriaFromUrlParams(
      urlParams,
      UrlParamKeys.i18nFamilies,
      i18nData.i18nFamilies as Criteria[]
    );

    const owners: Criteria[] = getCriteriaFromUrlParams(
      urlParams,
      UrlParamKeys.owners,
      ownerData?.owners as Criteria[]
    );

    const localizedPackages: Criteria[] = getCriteriaFromUrlParams(
      urlParams,
      UrlParamKeys.localizedPackages,
      localizedPackageData?.localizedPackages as Criteria[]
    );

    const packageTypes: Criteria[] = urlParams
      .getAll(UrlParamKeys.packageTypes)
      .filter((type: string) =>
        (packageTypeData?.packageTypes as PackageType[])
          .map((item: PackageType) => item.packageType)
          .includes(type)
      )
      .map(
        (type: string) =>
          ({ id: type, name: getClientNameLabel(type, t) } as Criteria)
      );

    const statuses: Criteria[] = getCriteriaFromUrlParams(
      urlParams,
      UrlParamKeys.statuses,
      statusData
    );

    const sourceTexts: Criteria[] = urlParams
      .getAll(UrlParamKeys.sourceTexts)
      .map(
        (sourceText: string) =>
          ({ id: FilterField.SOURCE_TEXT, name: sourceText } as Criteria)
      );

    const psmsIds: Criteria[] = urlParams
      .getAll(UrlParamKeys.psmsIds)
      .map(
        (psmsId: string) =>
          ({ id: FilterField.PSMS_ID, name: psmsId } as Criteria)
      );

    const stringIds: Criteria[] = urlParams
      .getAll(UrlParamKeys.stringIds)
      .map(
        (stringId: string) =>
          ({ id: FilterField.STRING_ID, name: stringId } as Criteria)
      );

    let filterToSet: FilterCategory = removeFalsyAttrs({
      i18nFamily,
      owners,
      localizedPackages,
      packageTypes,
      statuses,
      sourceTexts,
      psmsIds,
      stringIds,
    });

    // If no filter criteria are set, use the default filter
    if (isEmpty(filterToSet)) {
      filterToSet = getDefaultFilter();
      setQueryInputFromFilter(filterToSet);
    }

    setFilter(filterToSet);
    setQueryInputFromFilter(filterToSet);
  }, [i18nData, ownerLoading, localizedPackageLoading, packageTypeLoading]);

  useEffect(() => {
    if (searchToken === EMPTY_TOKEN) {
      return;
    }
    if (!searchToken.propertyKey) {
      searchToken.propertyKey = FilterField.DEFAULT_SEARCH;
    }

    const filterToSet: FilterCategory = updateFilterFromSearchToken(
      searchToken,
      filter
    );
    setFilter(filterToSet);
    setQueryInputFromFilter(filterToSet);
    setSearchToken(EMPTY_TOKEN);
  }, [searchToken]);

  useEffect(() => {
    const sudoInUrl = getUrlSearchParam(UrlParamKeys.sudo, history);
    if (isSuperuser && sudoInUrl === 'true') {
      setIsSudoMode(true);
    }
  }, [isSuperuser]);

  useEffect(() => {
    if (isSuperUserLoading) return;
    const updatedQueryInput: PhraseInput = removeFalsyAttrs({
      ...queryInput,
      sudo: isSudoMode,
    });
    setQueryInput(updatedQueryInput);
  }, [isSudoMode]);

  useEffect(() => {
    const updatedQueryInput: PhraseInput = removeFalsyAttrs({
      ...queryInput,
      caseSensitive: caseSensitive,
    });
    setQueryInput(updatedQueryInput);
  }, [caseSensitive]);

  useEffect(() => {
    const updatedQueryInput: PhraseInput = removeFalsyAttrs({
      ...queryInput,
      exactMatch: exactMatch,
    });
    setQueryInput(updatedQueryInput);
  }, [exactMatch]);

  useEffect(() => {
    setUrlFromPhraseInput(queryInput, history);
  }, [queryInput]);

  useEffect(() => {
    metricPublisher.publish(initLatencyMetric);
    return () => metricPublisher.publish(timeSpentMetric);
  }, []);

  /* A hackish way to manually set the sorting state of KatDataTable after re-render,
   this is because KatDatatable doesn't take any props for sorting state.
   Adding deps to useEffect() such as queryInput won't work as we want to update the
   sorting state for every re-render, since setSortDirection() will trigger an event,
   some check has been done in that callback to prevent infinity loop.
   This should be refactored once Katal addresses its dataTable pain points.
   */
  useEffect(() => {
    if (!tableRef.current) return;
    const { columns } = tableRef.current;
    const colIndex = columns.findIndex(
      (c: KatDataTable.ColumnConfiguration) =>
        c.property === sorting.current.column ||
        c.property === queryInput.sortKey1
    );
    const sortDirChar: any = {
      A: 'asc',
      D: 'desc',
    };
    if (colIndex !== -1) {
      tableRef.current.deferredUpdateWrapper(() => {
        let sortDirection = sorting.current.direction;
        if (queryInput.sortDir1) {
          sortDirection = sortDirChar[queryInput.sortDir1];
        }
        if (sortDirection) {
          tableRef.current.setSortDirection(colIndex, sortDirection);
        }
      });
    }
  });

  /* Callbacks */
  const onFilterApply = (newFilter: FilterCategory) => {
    const updatedFilter: FilterCategory = removeFalsyAttrs({
      ...newFilter,
      sourceTexts: filter.sourceTexts,
      psmsIds: filter.psmsIds,
      stringIds: filter.stringIds,
    });
    setFilter(updatedFilter);
    setQueryInputFromFilter(updatedFilter);
  };

  const debounceFilterUpdate = useMemo(
    () => debounce(setQueryInputFromFilter, FILTER_REMOVAL_DEBOUNCE_TIMEOUT),
    [queryInput]
  );

  const onTagRemove = (e: React.MouseEvent, tag: Tag) => {
    const i18nFamily = filter.i18nFamily?.filter(
      (item) => item.id !== tag.id || item.name !== tag.name
    );
    const owners = filter.owners?.filter(
      (item) => item.id !== tag.id || item.name !== tag.name
    );
    const localizedPackages = filter.localizedPackages?.filter(
      (item) => item.id !== tag.id || item.name !== tag.name
    );
    const packageTypes = filter.packageTypes?.filter(
      (item) => item.id !== tag.id || item.name !== tag.name
    );
    const statuses = filter.statuses?.filter(
      (item) => item.id !== tag.id || item.name !== tag.name
    );
    const sourceTexts = filter.sourceTexts?.filter(
      (item) => item.id !== tag.id || item.name !== tag.name
    );
    const psmsIds = filter.psmsIds?.filter(
      (item) => item.id !== tag.id || item.name !== tag.name
    );
    const stringIds = filter.stringIds?.filter(
      (item) => item.id !== tag.id || item.name !== tag.name
    );
    const newFilter = removeFalsyAttrs({
      i18nFamily,
      owners,
      localizedPackages,
      packageTypes,
      statuses,
      sourceTexts,
      psmsIds,
      stringIds,
    });
    setFilter(newFilter);
    debounceFilterUpdate(newFilter);
  };

  const onPageChange = (e: KatPagination.ChangeEvent) => {
    const selectedPage = (e.target as any).page as number;
    const offset = getOffset(
      selectedPage,
      queryInput.limit || DEFAULT_PAGE_SIZE
    );
    setQueryInput(removeFalsyAttrs({ ...queryInput, offset }));
  };

  const onSortingChange = (e: KatDataTable.SettingsChangedEvent) => {
    if (!e.detail.sortColumn) return;

    const column = e.detail.sortColumn.property;
    const direction = e.detail.sortDirection;
    const sortKey1 = column;
    const sortDir1 = direction.charAt(0).toUpperCase();

    // Prevent useEffect() infinity loop
    if (
      sorting.current.column === column &&
      sorting.current.direction === direction
    )
      return;
    sorting.current = { ...sorting, column, direction };
    setQueryInput(removeFalsyAttrs({ ...queryInput, sortKey1, sortDir1 }));
  };

  const rowData = React.useMemo(() => {
    return map(data?.phrases.phraseList, (row) => {
      return {
        ...row,
        translationsLastUpdated: getLocalizedTimestamp(
          row?.translationsLastUpdated
        ),
      };
    });
  }, [data?.phrases.phraseList, i18n.language]);

  /* JSX */
  const stringListTable = (id: string) => {
    if (
      loading ||
      i18nLoading ||
      ownerLoading ||
      localizedPackageLoading ||
      packageTypeLoading
    ) {
      return <KatSpinner id={`${id}-spinner`} />;
    }

    if (error) {
      return (
        <ErrorHandler
          id={`${id}-error`}
          publisher={metricPublisher}
          header={t('error-flashbar-header-message-strings')}
          error={error}
        />
      );
    }

    return (
      <KatDataTable
        id={`${id}-table`}
        columns={columnConfigConstructor(t, history)}
        rowData={rowData}
        onSettingsChanged={onSortingChange}
        ref={tableRef}
      />
    );
  };

  return (
    <section id={props.id}>
      <KatRow id={props.id + '-header'}>
        <div>
          <h2 id={props.id + '-title'}>{t('string-list-page-title')}</h2>
        </div>
      </KatRow>
      <KatRow id={props.id + '-table-controls'}>
        <div>
          <Search
            id={props.id + '-search'}
            filteringPlaceholder={t('search-by-placeholder')}
            filteringProperties={stringListSearchProperties}
            token={searchToken}
            setToken={setSearchToken}
            exactMatch={exactMatch}
            setExactMatch={setExactMatch}
            caseSensitive={caseSensitive}
            setCaseSensitive={setCaseSensitive}
          />
          {!i18nLoading &&
            !ownerLoading &&
            !localizedPackageLoading &&
            !packageTypeLoading && (
              <FilterModal
                applied={filter}
                id={props.id + '-filter'}
                onApply={onFilterApply}
                includedStatuses
              />
            )}
          <span className="applied-filter-label">
            {' '}
            {t('string-list-page-applied-filters')}{' '}
          </span>
          <Tags
            id={props.id + '-filter-tags'}
            filter={filter}
            onChange={onTagRemove}
          />
          <Filter
            id={props.id + '-filter'}
            filter={filter}
            onFilterApply={onFilterApply}
          />
        </div>
      </KatRow>
      <section>{stringListTable(props.id)}</section>
      <section>
        {data?.phrases.pageInfo && (
          <Pagination
            id={props.id + '-pagination'}
            onChange={onPageChange}
            pageInfo={data.phrases.pageInfo}
          />
        )}
      </section>
    </section>
  );
};

export default StringList;
