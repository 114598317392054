const toggleDarkTheme = (isSudoMode: boolean) => {
  const htmlEle = document.documentElement;
  if (isSudoMode) {
    htmlEle.dataset.theme = 'dark';
  } else {
    htmlEle.dataset.theme = 'light';
  }
};

export { toggleDarkTheme };
