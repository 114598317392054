import { GetOwnersDocument } from 'src/generated/graphql';
import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';

export const ownersMock = (async () => {
  return {
    request: {
      query: GetOwnersDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        owners: [
          { id: 0, name: 'STT-STRIDER' },
          { id: 1, name: 'STT-STOP' },
          { id: 2, name: 'Test Team 3' },
          { id: 3, name: 'Test Team 4' },
          { id: 4, name: 'Test Team 5' },
          { id: 5, name: 'Test Team 5' },
          { id: 6, name: 'Test Team 6' },
          { id: 7, name: 'Test Team 7' },
          { id: 8, name: 'Test Team 8' },
          { id: 9, name: 'Test Team 9' },
          { id: 10, name: 'Test Team 10' },
          { id: 11, name: 'Test Team 11' },
          { id: 12, name: 'Test Team 12' },
          { id: 13, name: 'Test Team 13' },
          { id: 14, name: 'Test Team 14' },
          { id: 15, name: 'Test Team 15' },
          { id: 16, name: 'Test Team 16' },
          { id: 17, name: 'Test Team 17' },
          { id: 18, name: 'Test Team 18' },
          { id: 19, name: 'Test Team 19' },
          { id: 20, name: 'Test Team 20' },
          { id: 21, name: 'Test Team 21' },
          { id: 22, name: 'Test Team 22' },
          { id: 23, name: 'Test Team 23' },
          { id: 24, name: 'Test Team 24' },
          { id: 25, name: 'Test Team 25' },
        ],
      },
    },
  };
})();
