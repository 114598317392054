export default {
  locale: {
    values: ['he-IL', 'ar-AE', 'ar-SA', 'en-US', 'zh-CN', 'en-AU', 'en-GB'],
  },
  translationStatus: {
    values: ['COMPLETED', 'IN_PROGRESS', 'STATUS_UNKNOWN', 'FAILED'],
  },
  translation: {
    values: [
      'שלום לך בעברית',
      'هذا نص مترجم إلى اللغة العربية',
      'prime video text here',
      '65+',
      '你瞅啥？！',
    ],
  },
  dropDownReasons: {
    static: ['QUALITY'],
  },
  explanation: {
    static: 'Valid explanation',
  },
  lastUpdated: {
    values: [1602803146, 1529100000, 1564469400, 1581754800, 1589065200],
  },
  lastUpdatedBy: {
    faker: 'company.companyName',
  },
  canEdit: {
    faker: 'datatype.boolean',
  },
};
