import {
  AsyncMessageBundleManager,
  createAsyncMessageBundleManager,
  LocalizationContextBuilder,
  MessageBundle,
} from '@amzn/arb-tools';
import { loadPreferences, Preferences } from 'src/api/preferencesClient';
import arbManifest from '../../build/packaging_additional_published_artifacts/i18n/arbManifest';

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_BUNDLE = 'strings';
export const DEFAULT_LC_BUILDER =
  new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

export const localizationContextProvider = () => {
  const preferences: Preferences = loadPreferences();
  return DEFAULT_LC_BUILDER.withLocale(
    preferences?.preferredLanguage || DEFAULT_LOCALE
  ).build();
};

const mbm: AsyncMessageBundleManager = createAsyncMessageBundleManager({
  arbManifest,
  localizationContextProvider,
  urlPrefix: '/i18n',
});

// Force TS to always have i18n.defaultBundle - since we're loading this after
// initialization.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const i18n: {
  mbm: AsyncMessageBundleManager;
  defaultBundle: MessageBundle;
} = {
  mbm,
};

(async () => {
  i18n.defaultBundle = await i18n.mbm.getBundle(DEFAULT_BUNDLE);
})();
