export const staticXliffStrMock = `<xliff xmlns="urn:oasis:names:tc:xliff:document:2.0" version="2.0" srcLang="en-US" trgLang="fr-FR">
          <file id="HARDCODED">
          <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
            <mda:metaGroup category="file_configuration">
              <mda:meta type="perform_explosion">true</mda:meta>
            </mda:metaGroup>
          </mda:metadata>

          <group id="SUID-2">
            <unit id="id-4">
              <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
                <mda:metaGroup category="linguistic variables">
                  <mda:meta type="plural:fruitCount">=0</mda:meta>
                  <mda:meta type="select:fruit">apple</mda:meta>
                </mda:metaGroup>
              </mda:metadata>
              <segment>
                <source>You have no apples in the basket</source>
                <target>(Target)You have no apples in the basket</target>
              </segment>
            </unit>
            <unit id="id-5">
              <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
                <mda:metaGroup category="linguistic variables">
                  <mda:meta type="plural:fruitCount">one</mda:meta>
                  <mda:meta type="select:fruit">apple</mda:meta>
                </mda:metaGroup>
              </mda:metadata>
              <segment>
                <source>You have <ph id="3" disp="{fruitCount}"/> apple in the basket</source>
                <target>(Target)You have <ph id="4" disp="{fruitCount}"/> apple in the basket</target>
              </segment>
            </unit>
            <unit id="id-6">
              <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
                <mda:metaGroup category="linguistic variables">
                  <mda:meta type="plural:fruitCount">other</mda:meta>
                  <mda:meta type="select:fruit">apple</mda:meta>
                </mda:metaGroup>
              </mda:metadata>
              <segment>
                <source>You have <ph id="5" disp="{fruitCount}"/> apples in the basket</source>
                <target>(Target)You have <ph id="6" disp="{fruitCount}"/> apples in the basket</target>
              </segment>
            </unit>
            <unit id="id-7">
              <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
                <mda:metaGroup category="linguistic variables">
                  <mda:meta type="plural:fruitCount">=0</mda:meta>
                  <mda:meta type="select:fruit">orange</mda:meta>
                </mda:metaGroup>
              </mda:metadata>
              <segment>
                <source>You have no oranges in the basket</source>
                <target>(Target)You have no oranges in the basket</target>
              </segment>
            </unit>
            <unit id="id-8">
              <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
                <mda:metaGroup category="linguistic variables">
                  <mda:meta type="plural:fruitCount">one</mda:meta>
                  <mda:meta type="select:fruit">orange</mda:meta>
                </mda:metaGroup>
              </mda:metadata>
              <segment>
                <source>You have <ph id="7" disp="{fruitCount}"/> oranges in a basket</source>
                <target>(Target)You have <ph id="8" disp="{fruitCount}"/> oranges in a basket</target>
              </segment>
            </unit>
            <unit id="id-9">
              <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
                <mda:metaGroup category="linguistic variables">
                  <mda:meta type="plural:fruitCount">other</mda:meta>
                  <mda:meta type="select:fruit">orange</mda:meta>
                </mda:metaGroup>
              </mda:metadata>
              <segment>
                <source>You have <ph id="9" disp="{fruitCount}"/> oranges in a basket</source>
                <target>(Target)You have <ph id="10" disp="{fruitCount}"/> oranges in a basket</target>
              </segment>
            </unit>
            <unit id="id-10">
              <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
                <mda:metaGroup category="linguistic variables">
                  <mda:meta type="plural:fruitCount">few</mda:meta>
                  <mda:meta type="select:fruit">orange</mda:meta>
                </mda:metaGroup>
              </mda:metadata>
              <segment>
                <source>You have <ph id="11" disp="{fruitCount}"/> oranges in a basket</source>
              </segment>
            </unit>
            <unit id="id-11">
              <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
                <mda:metaGroup category="linguistic variables">
                  <mda:meta type="plural:fruitCount">many</mda:meta>
                  <mda:meta type="select:fruit">orange</mda:meta>
                </mda:metaGroup>
              </mda:metadata>
              <segment>
                <source></source>
                <target>(Target)You have <ph id="12" disp="{fruitCount}"/> oranges in a basket</target>
              </segment>
            </unit>
            <unit id="id-12">
              <mda:metadata xmlns:mda="urn:oasis:names:tc:xliff:metadata:2.0">
                <mda:metaGroup category="linguistic variables">
                  <mda:meta type="plural:fruitCount">many</mda:meta>
                  <mda:meta type="select:fruit">orange</mda:meta>
                </mda:metaGroup>
              </mda:metadata>
              <segment>
                <source>Just say which thing you'd like me to help you find. <ph id="13" disp="&lt;content_type>" dataRef="d1"/> or <ph id="14" disp="&lt;content_type&gt;" dataRef="d1"/>.</source>
                <target>(Target)Just say which thing you'd like me to help you find. <ph id="15" disp="&lt;content_type>" dataRef="d1"/> or <ph id="16" disp="&lt;content_type&gt;" dataRef="d1"/>.</target>
              </segment>
            </unit>
            <unit id="id-13">
              <segment>
                <source>I accept and acknowledge all the conditions set out in the <ph id="17" disp="&lt;a href={link}>" dataRef="d1"/>Tax agreement<ph id="18" disp="&lt;/a&gt;" dataRef="d2"/> and understand that once activated, my TAX registration number will be applied to all of my purchases. I also acknowledge that it may take 1-4 business days for my TAX number to be validated and available for use.</source>
                <target>(Target)I accept and acknowledge all the conditions set out in the <ph id="19" disp="&lt;a href={link}>" dataRef="d1"/>Tax agreement<ph id="20" disp="&lt;/a&gt;" dataRef="d2"/> and understand that once activated, my TAX registration number will be applied to all of my purchases. I also acknowledge that it may take 1-4 business days for my TAX number to be validated and available for use.</target>
              </segment>
            </unit>
          </group>
        </file>
      </xliff>`;
