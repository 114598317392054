import { isNil } from 'lodash';

import { locales } from 'src/i18n/locales';

// Detect if the feature exists in browser
const isDisplaynamesSupported = () => {
  let displayNamesSupported = false;

  try {
    const lang = new Intl.DisplayNames(['en-US'], { type: 'language' }).of(
      'en-US'
    );
    if (!isNil(lang)) displayNamesSupported = true;
  } catch (e) {
    // The feature is unsupported since the call to
    // Intl.DisplayNames throws an error
  }
  return displayNamesSupported;
};

export default async function loadPolyfill() {
  if (isDisplaynamesSupported()) return;

  // Load the polyfill 1st BEFORE loading locale data for all others
  await import('@formatjs/intl-displaynames/polyfill');
  await import('@formatjs/intl-displaynames/locale-data/en'); // required import for polyfill itself

  const allImports: Promise<object>[] = [];
  return new Promise<void>((resolve) => {
    locales.map((locale) => {
      allImports.push(
        import(
          '@formatjs/intl-displaynames/locale-data/' +
            locale.polyfillModuleName +
            '.js'
        )
      );
    });
    Promise.all(allImports).then(() => resolve());
  });
}
