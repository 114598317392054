import { staticXliffStrMock } from 'src/api/mocks/types/xliffSampleStr';

export default {
  locale: {
    values: ['he-IL', 'ar-AE', 'en-US', 'zh-CN', 'en-AU', 'en-GB'],
  },
  translationStatus: {
    values: ['COMPLETED', 'IN_PROGRESS', 'STATUS_UNKNOWN', 'FAILED'],
  },
  translation: {
    static: staticXliffStrMock,
  },
  dropDownReasons: {
    static: ['QUALITY'],
  },
  explanation: {
    static: 'Valid explanation',
  },
  lastUpdated: {
    values: [1602803146, 1529100000, 1564469400, 1581754800, 1589065200],
  },
  lastUpdatedBy: {
    faker: 'company.companyName',
  },
  canEdit: {
    static: true,
  },
};
