import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';
import { SuperuserDocument } from 'src/generated/graphql';

export const superuserMock = (async () => {
  return {
    request: {
      query: SuperuserDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        isSuperuser: {
          isAuthorized: true,
        },
      },
    },
  };
})();
