import convert from 'xml-js';
import ElementTypes2 from './inline-elements/ElementTypes2.js';
import { makeElement, makeText, makeValue } from './xml-js/objectToXml.js';

import escape from './util/escape.js';
import { createMetadataTag } from './xml-js/metadataConverter';

const js2xliffClb = (obj, opt, cb) => {
  if (!cb && typeof opt === 'function') {
    cb = opt;
    opt = { indent: '  ' };
  }

  const escapeUnsafeXMLChars = (value) => {
    return value
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/"/g, '&quot;');
  };
  const options = {
    spaces: opt?.indent || 0,
    attributeValueFn: escapeUnsafeXMLChars,
  };

  const rootAttributes = {
    xmlns: 'urn:oasis:names:tc:xliff:document:2.0',
    version: '2.0',
    srcLang: obj.sourceLanguage,
    trgLang: obj.targetLanguage,
  };
  const root = makeElement('xliff', rootAttributes, true);

  Object.keys(obj.resources).forEach((nsName) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const fileChildren = createUnitTags(obj.resources[nsName]);
    const f = makeElement('file', { id: nsName }, fileChildren);
    root.elements.push(f);
  });

  const xmlJs = {
    elements: [root],
  };

  const xml = convert.js2xml(xmlJs, options);
  if (cb) cb(null, xml);
  return xml;
};

function createUnitTags(unitElements) {
  return Object.keys(unitElements).map((key) => {
    if (unitElements[key].groupUnits) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return createGroupUnitTag(key, unitElements[key]);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return createUnitTag(key, unitElements[key]);
    }
  });
}

function createGroupUnitTag(id, group) {
  const additionalAttributes =
    group.additionalAttributes != null ? group.additionalAttributes : {};
  const groupUnits = createUnitTags(group.groupUnits);
  return makeElement(
    'group',
    Object.assign({ id: escape(id) }, additionalAttributes),
    groupUnits
  );
}

function createUnitTag(id, unit) {
  //metadata
  const metadata = unit.metadata ? createMetadataTag(unit.metadata) : undefined;
  //originalData
  const originalData = unit.originalData;

  //segment
  const segment = makeElement('segment', null, true);
  segment.elements.push(
    makeElement('source', null, makeValue(unit.source, ElementTypes2))
  );
  if (unit.target !== undefined)
    segment.elements.push(
      makeElement('target', null, makeValue(unit.target, ElementTypes2))
    );
  if ('note' in unit) {
    segment.elements.push(makeElement('note', null, [makeText(unit.note)]));
  }
  const additionalAttributes =
    unit.additionalAttributes != null ? unit.additionalAttributes : {};
  return makeElement(
    'unit',
    Object.assign({ id: escape(id) }, additionalAttributes),
    [
      ...(metadata ? [metadata] : []),
      ...(originalData ? [originalData] : []),
      segment,
    ]
  );
}

const js2xliff = (obj, opt, cb) => {
  if (!cb && opt === undefined) {
    return new Promise((resolve, reject) =>
      js2xliffClb(obj, opt, (err, ret) => (err ? reject(err) : resolve(ret)))
    );
  }
  if (!cb && typeof opt !== 'function') {
    return new Promise((resolve, reject) =>
      js2xliffClb(obj, opt, (err, ret) => (err ? reject(err) : resolve(ret)))
    );
  }
  return js2xliffClb(obj, opt, cb);
};

js2xliff.js2xliffClb = js2xliffClb;

export default js2xliff;
