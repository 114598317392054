import React, { useEffect, useState } from 'react';
import { KatButton, KatRadiobuttonGroup } from '@amzn/katal-react';
import NoDismissKatModal from 'src/components/common/ui/NoDismissKatModal/NoDismissKatModal';
import LocaleSelect from './LocaleSelect';
import MarketplaceSelect from './MarketplaceSelect';
import {
  savePreferences,
  Preferences,
  createDefaultFilter,
  Persona,
} from 'src/api/preferencesClient';
import './InitialModal.scss';
import { Option } from 'src/components/common/ui/TypeaheadDropdown/TypeaheadDropdown';
import { map } from 'lodash';
import OwnerPersonaFields from 'src/components/Preferences/InitialModal/OwnerPersonaFields';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import { I18nFamilyQueryResultProvider } from 'src/components/common/provider/QueryResultProvider';

type Props = {
  display: boolean;
  id: string;
  onDone: () => void;
  onCancel?: () => void;
};

const defaultPreferences: Preferences = {
  persona: undefined,
  owners: [],
  i18nFamily: '',
  marketplace: '',
  locale: '',
  preferredLanguage: '',
  currentFilter: 0,
};

const defaultErrors = {
  persona: false,
  owner: false,
  i18nFamily: false,
  marketplace: false,
};

const PreferenceModal: React.FC<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState(props.display);
  const [showOwnerSelect, setShowOwnerSelect] = useState(false);
  const [showMarketplaceSelect, setShowMarketplaceSelect] = useState(false);
  const [preferences, setPreferences] = useState(defaultPreferences);
  const [errors, setErrors] = useState(defaultErrors);

  const [refetchChildQueries, setRefetchChildQueries] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setShowModal(props.display);
    if (props.display) setRefetchChildQueries(!refetchChildQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.display]);

  const personas = [
    {
      label: t('initial-modal-radio-preference-team'),
      value: 'owner',
    },
    {
      label: t('initial-modal-radio-preference-marketplace'),
      value: 'marketplace',
      disabled: true,
    },
  ];

  const onPersonaChanged = (e: CustomEvent<{ value: string }>) => {
    const selectedPersona = e.detail.value as Persona;
    setPreferences({ ...defaultPreferences, persona: selectedPersona });
    setErrors({ ...defaultErrors });
    setShowOwnerSelect(selectedPersona === 'owner');
    setShowMarketplaceSelect(selectedPersona === 'marketplace');
  };

  const onOwnerChange = (selected: Option[]) => {
    setPreferences({
      ...preferences,
      owners: selected.length > 0 ? map(selected, 'value') : [],
    });
    if (selected?.length > 0) {
      setErrors({ ...errors, owner: false });
    }
  };

  const onI18nFamilyChange = (e: CustomEvent) => {
    const newI18nFamily = e.detail.value;
    setPreferences({ ...preferences, i18nFamily: newI18nFamily });
    newI18nFamily && setErrors({ ...errors, i18nFamily: false });
  };

  const onLocaleChange = (e: CustomEvent) => {
    const newLocale = e.detail.value;
    setPreferences({ ...preferences, locale: newLocale });
  };

  const onMarketplaceChange = (e: CustomEvent) => {
    const newMarketplace = e.detail.value;
    setPreferences({ ...preferences, marketplace: newMarketplace });
  };

  const selectionRenderControl = (errors: any) => {
    if (showOwnerSelect)
      return (
        <div key={0} className="modal-input">
          <I18nFamilyQueryResultProvider>
            <OwnerPersonaFields
              id={`${props.id}-owner-persona-fields`}
              onOwnerChange={onOwnerChange}
              onI18nFamilyChange={onI18nFamilyChange}
              ownerRequired={errors.owner}
              i18nRequired={errors.i18nFamily}
              refetchQueryToggle={refetchChildQueries}
            />
          </I18nFamilyQueryResultProvider>
        </div>
      );
    else if (showMarketplaceSelect)
      return [
        <MarketplaceSelect
          key={0}
          onMarketplaceChange={onMarketplaceChange}
          required={errors.marketplace}
        />,
        <LocaleSelect key={1} onLocaleChange={onLocaleChange} />,
      ];
  };

  const checkRequiredFields = () => {
    //state hooks are async, not guaranteed to be updated by the end of this function
    const newErrors = {
      persona: false,
      owner: false,
      i18nFamily: false,
      marketplace: false,
    };
    let isError = false;
    if (preferences.persona === 'owner') {
      if (preferences.owners.length === 0) {
        newErrors.owner = true;
        isError = true;
      }

      if (preferences.i18nFamily === '') {
        newErrors.i18nFamily = true;
        isError = true;
      }
    } else if (preferences.persona === 'marketplace') {
      if (preferences.marketplace === '') {
        newErrors.marketplace = true;
        isError = true;
      }
    } else {
      newErrors.persona = true;
      isError = true;
    }
    setErrors(newErrors);
    return isError;
  };

  const handleSave = () => {
    if (checkRequiredFields()) return;
    savePreferences({
      ...preferences,
      preferredLanguage: i18n.language, // since this is first load, language changes read from i18n
    });

    createDefaultFilter();

    setShowModal(false);
    props.onDone();
  };

  const personaRadiobuttonProps = {
    name: 'personaGroup',
    options: personas,
    label: t('initial-modal-body-title'),
    'constraint-label': t('initial-modal-body-constraint-label'),
    onChange: onPersonaChanged,
  };

  const handleClose = () => {
    setErrors({ ...defaultErrors });
    props.onCancel && props.onCancel();
  };

  return (
    <NoDismissKatModal
      visible={showModal}
      id={props.id + '-modal'}
      scroll-all
      onClose={handleClose}
    >
      <span slot="title">{t('initial-modal-title')}</span>
      <div id={props.id + '-modal-body'}>
        {errors.persona ? (
          <KatRadiobuttonGroup
            {...personaRadiobuttonProps}
            error-label={t('initial-model-body-error-label')}
          />
        ) : (
          <KatRadiobuttonGroup {...personaRadiobuttonProps} />
        )}
        <div>{selectionRenderControl(errors)}</div>
      </div>
      <div slot="footer" className="kat-group-horizontal">
        <KatButton
          label={t('initial-modal-save-button')}
          onClick={handleSave}
        />
      </div>
    </NoDismissKatModal>
  );
};

export default PreferenceModal;
