/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import './Search.scss';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import {
  FilteringOption,
  FilteringProperty,
  Query,
  Token,
} from '@amzn/awsui-components-react/polaris/property-filter/interfaces';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { DropdownStatusProps } from '@amzn/awsui-components-react/polaris/internal/components/dropdown-status';

const EMPTY_QUERY: Query = {
  operation: 'and',
  tokens: [],
};

type Props = {
  id: string;
  filteringPlaceholder: string;
  filteringProperties: FilteringProperty[];
  filteringOptions?: FilteringOption[];
  filteringStatusType?: DropdownStatusProps.StatusType;
  onLoadItems?: () => void;
  token: Token;
  setToken: (token: Token) => void;
  caseSensitive: boolean;
  setCaseSensitive: (caseSensitive: boolean) => void;
  exactMatch: boolean;
  setExactMatch: (exactMatch: boolean) => void;
  disableSearchOptions?: boolean;
};

const Search: React.FC<Props> = (props: Props) => {
  /* React Hooks */
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<
    readonly OptionDefinition[]
  >([]);
  const [query, setQuery] = useState(EMPTY_QUERY);

  useEffect(() => {
    setSelectedOptions(getSelectedOptions());
  }, [props.exactMatch, props.caseSensitive]);

  const searchOptions: OptionDefinition[] = [
    {
      label: t('search-case-sensitive'),
      value: '1',
    } as OptionDefinition,
    {
      label: t('search-exact-match'),
      value: '2',
    } as OptionDefinition,
  ];

  const getSelectedOptions = (): OptionDefinition[] =>
    searchOptions.filter(
      (option) =>
        (option.value === '1' && props.caseSensitive) ||
        (option.value === '2' && props.exactMatch)
    );

  /* Handlers */
  const onSearchOptionsChange = (options: readonly OptionDefinition[]) => {
    if (options.length === 1) {
      props.setCaseSensitive(options[0].value === '1');
      props.setExactMatch(options[0].value === '2');
    } else {
      props.setCaseSensitive(options.length === searchOptions.length);
      props.setExactMatch(options.length === searchOptions.length);
    }
  };

  const onQueryChange = (query: Query) => {
    const token: Token = query.tokens[0];
    const trimmedValue: string = token.value.trim();
    if (trimmedValue.length > 0) {
      props.setToken({
        ...token,
        value: token.value.trim(),
      });
      setQuery(EMPTY_QUERY);
    }
  };

  // Translate the group labels and property labels
  const filteringProperties: FilteringProperty[] =
    props.filteringProperties.map((property) => ({
      ...property,
      groupValuesLabel: t(property.groupValuesLabel),
      propertyLabel: t(property.propertyLabel),
    }));

  /* JSX */
  return (
    <>
      {!props.disableSearchOptions && (
        <Multiselect
          className="lui-search-multiselect"
          placeholder={t('search-options')}
          options={searchOptions}
          selectedOptions={selectedOptions}
          onChange={({ detail }) =>
            onSearchOptionsChange(detail.selectedOptions)
          }
        />
      )}
      <PropertyFilter
        className="lui-search-property-filter"
        filteringPlaceholder={props.filteringPlaceholder}
        filteringProperties={filteringProperties}
        filteringOptions={props.filteringOptions}
        filteringStatusType={props.filteringStatusType}
        filteringErrorText={t('property-filter-error-text')}
        filteringRecoveryText={
          props.onLoadItems && t('property-filter-retry-button')
        }
        onLoadItems={props.onLoadItems}
        onChange={({ detail }) => onQueryChange(detail)}
        query={query}
      />
    </>
  );
};

export default Search;
