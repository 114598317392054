import { ApolloError } from 'apollo-client';
import { ErrorResponse } from '@apollo/client/link/error';
import { find } from 'lodash';
import { GraphQLError } from 'graphql';
import { getBaseBindleUrl } from 'src/components/common/util/BindleHelpers';
import { METRIC } from 'src/metrics';
import { i18n } from 'src/i18n';

export enum LUIError {
  NETWORK_ERROR = '0',
  BAD_REQUEST = '400',
  FORBIDDEN = '403',
  NOT_FOUND = '404',
  TOO_MANY_REQUESTS = '429',
  INTERNAL_ERROR = '500',
  BAD_GATEWAY = '502',
  SERVICE_UNAVAILABLE = '503',
  REQUEST_TIMEOUT = '504',
}

export const errorMessageMap = {
  [LUIError.NOT_FOUND]: 'error-flashbar-header-empty',
  [LUIError.INTERNAL_ERROR]: 'error-flashbar-desc',
  [LUIError.REQUEST_TIMEOUT]: 'error-request-timeout-flashbar-desc',
  [LUIError.SERVICE_UNAVAILABLE]: 'error-service-unavailable-flashbar-desc',
  [LUIError.BAD_GATEWAY]: 'error-bad-gateway-flashbar-desc',
  [LUIError.TOO_MANY_REQUESTS]: 'error-too-many-requests-flashbar-desc',
  [LUIError.NETWORK_ERROR]: 'error-flashbar-header-no-network',
  [LUIError.BAD_REQUEST]: 'error-bad-request-flashbar-desc',
  [LUIError.FORBIDDEN]: 'error-forbidden-flashbar-desc',
};

const getUserFriendlyMessage = (errorType: LUIError) => {
  return i18n.defaultBundle.getMessage(errorMessageMap[errorType]);
};

export const unwrapGqlError = (error: ApolloError | ErrorResponse) => {
  const errorType = error.networkError
    ? LUIError.NETWORK_ERROR
    : find(error.graphQLErrors as any, 'errorType')?.errorType ||
      LUIError.INTERNAL_ERROR;
  const rawErrorMessage =
    error instanceof ApolloError
      ? error.message
      : JSON.stringify(error) ?? 'Unable to parse error message.';
  const userFriendlyMessage = getUserFriendlyMessage(errorType);
  return { errorType, rawErrorMessage, userFriendlyMessage };
};

const getLinkMetadataFromGqlError = (
  gqlError: LUIGqlError,
  publisher?: any
) => {
  switch (gqlError?.errorType) {
    case LUIError.FORBIDDEN:
      if (gqlError?.message) {
        const errorData = JSON.parse(gqlError.message);
        const { bindle } = errorData || {};
        if (bindle?.sourceSystemId) {
          return {
            linkLabel: i18n.defaultBundle.getMessage('view-bindle-link'),
            linkHref: `${getBaseBindleUrl()}/resource/${bindle.sourceSystemId}`,
            onLinkClick: (e: CustomEvent) => {
              if (publisher) {
                publisher.publishCounterMonitor(METRIC.BINDLE_CLICK, 1);
              }
            },
          };
        }
      }
      return {};
    default:
      return {};
  }
};

export const getLinkMetadataFromError = (
  error: ApolloError | ErrorResponse,
  publisher?: any
) => {
  try {
    if (!error.networkError) {
      const gqlError: LUIGqlError = find(
        error.graphQLErrors as GraphQLError[],
        'errorType'
      );
      return getLinkMetadataFromGqlError(gqlError, publisher);
    }
  } catch (e) {
    // fall-through
    console.log('Error parsing error metadata: ', e);
  }
  return {};
};

export type LUIGqlError =
  | { errorType?: LUIError; message?: string }
  | undefined;
