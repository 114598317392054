import { staticXliffStrMock } from 'src/api/mocks/types/xliffSampleStr';

export default {
  id: 123456,
  name: 'xliff_mock',
  sourceLocale: 'en_US',
  owner: 'STRIDER',
  i18nFamily: 'retail',
  owningPackage: 'XLIFF Mock Package',
  sourceText: staticXliffStrMock,
  format: 'xliff',
  anchorTag: 'GitFarm#XLIFFMockPackage',
  version: 1,
  context: `
        Nemo quod ab nisi iure magnam quasi ratione unde enim.
        Quas officiis et labore accusantium et soluta.
        Ad nostrum quo commodi autem suscipit dicta. Dignissimos reiciendis et.
        Impedit molestiae voluptate quia in esse eos.
        Soluta atque sapiente non nihil minus alias quasi eius ut.
    `,
  created: 1602803146,
  createdBy: 'STRIDER',
  lastUpdated: 1602803146,
  translationsLastUpdated: 1602803146,
  lastUpdatedBy: 'STRIDER',
  localizedPackage: 'XLIFFMockPackage',
  localizedPackageTsAceName: {
    values: [null, 'XLIFFMockTsAceName'],
  },
  ownerType: 'TEAM',
  owningPackageType: 'GitFarm',
  owningPackageOriginId: 'XLIFFMockPackage',
  psmsId: 'PSMS-ID-e56a26db-9ea5-4ea1-aefb-9c0faaf69498',
  status: 'COMPLETED',
  variant: {
    category: 'fugit',
    id: 123456,
    name: 'Konopelski, Wolf and Fahey',
  },
};
