/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { KatPagination } from '@amzn/katal-react';
import { getPage } from 'src/components/StringList/StringList';
import { PageInfo } from 'src/generated/graphql';

type Props = {
  id: string;
  onChange: any;
  pageInfo: PageInfo;
};

const Pagination: React.FC<Props> = (props: Props) => {
  const { count, limit, offset, totalCount } = props.pageInfo;
  const page = getPage(offset, limit);

  /* React Hooks */
  const [totalItems, setTotalItems] = useState(totalCount);

  if (!totalItems && count !== limit) {
    setTotalItems((page - 1) * limit + count);
  }

  /* JSX */
  return (
    <KatPagination
      id={props.id}
      itemsPerPage={limit}
      onChange={props.onChange}
      openEnded={!totalItems}
      page={page}
      totalItems={totalItems}
    />
  );
};

export default Pagination;
