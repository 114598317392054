import React from 'react';
import { KatDropdown } from '@amzn/katal-react';

import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import marketplaceMock from './InputOptions/marketplaces';

type Props = {
  onMarketplaceChange: (e: CustomEvent) => void;
  required?: boolean;
};

const MarketplaceSelect: React.FC<Props> = React.memo((props: Props) => {
  const { t } = useTranslation();

  const marketplaceDropdownProps = {
    options: marketplaceMock,
    label: t('choose-marketplace-dropdown-label'),
    placeholder: t('placeholder-text-choose'),
    'tooltip-text': t('choose-marketplace-tooltip'),
    onChange: props.onMarketplaceChange,
  };

  if (props.required) {
    return (
      <KatDropdown
        {...marketplaceDropdownProps}
        state="error"
        state-emphasis="Required"
        state-label={t('choose-marketplace-error-label')}
      ></KatDropdown>
    );
  } else {
    return <KatDropdown {...marketplaceDropdownProps}></KatDropdown>;
  }
});

MarketplaceSelect.displayName = 'MarketplaceSelect';

export default MarketplaceSelect;
