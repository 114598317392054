import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import { getStage } from 'src/components/common/util/StageHelpers';

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV === 'test') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  } else if (process.env.NODE_ENV !== 'production') {
    return new KatalMetricsDriverConsoleLogJson();
  } else {
    return new KatalMetricsDriverSushi.Builder()
      .withDomainRealm('prod', 'USAmazon')
      .withErrorHandler(metricsConsoleErrorHandler)
      .build();
  }
};

const makePublisher = (): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();
  const rootMetricContext = new KatalMetrics.Context.Builder()
    .withSite('LUI')
    .withServiceName(getStage())
    .build();
  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsConsoleErrorHandler,
    rootMetricContext
  );
};

const rootMetricPublisher = makePublisher();

/**
 * The rootMetricPublisher is a singleton that shouldn't be used to
 * publish metrics directly. Instead, create a child publisher
 * by using various methods provided by Katal.
 * For example, rootMetricPublisher.newChildActionPublisherForMethod('ComponentA')
 */
export default rootMetricPublisher;

/**
 * A growing list of common metric names used in LUI
 */
export enum METRIC {
  LOADING_LATENCY = 'Latency',
  TIME_SPENT = 'Time_Spent',
  ERROR = 'Error',
  ERROR_CODE = 'Error_Code_',
  PRE_RETRY_ERROR_CODE = 'Pre_Retry_Error_Code_',
  ERROR_MSG = 'Error_Msg',
  PRE_RETRY_ERROR_MSG = 'Pre_Retry_Error_Msg',
  SIM_CLICK = 'SIM_Click',
  BINDLE_CLICK = 'Bindle_Click',
}

//Helper function to generate metric object
const getMonitoredMetricObject = (
  canMonitor: boolean,
  metricObj: KatalMetrics.Metric.Object
) => (canMonitor ? metricObj.withMonitor() : metricObj);

/**
 * Katal Counter Metric Helper Function
 *  This metric can be used to count the number of times an event happened
 * @param metricKey: name of the metric
 * @param count: counter value, default to 1
 * @param canMonitor: send to PMET or not, true by default
 */
export const counterMetric = (
  metricKey: string,
  count = 1,
  canMonitor = true
) => {
  const metricObj = new KatalMetrics.Metric.Counter(metricKey, count);
  return getMonitoredMetricObject(canMonitor, metricObj);
};

/**
 * Katal Timer Metric Helper Function
 *  This metric can be used to record a time. This metric requires explicit
 *  value; see timerStopwatch for automatic timing.
 *
 * @param metricKey: name of the metric
 * @param value: specific time in milliseconds
 * @param canMonitor: send to PMET or not, true by default
 */
export const timerMetric = (
  metricKey: string,
  value: number,
  canMonitor = true
) => {
  const metricObj = new KatalMetrics.Metric.Timer(metricKey, value);
  return getMonitoredMetricObject(canMonitor, metricObj);
};

/**
 * Katal Timer Stopwatch Metrics Helper Function
 *  This metric will record the elapsed time between starting and stopping.
 *  By default it will start when the object is created, and stopped when the
 *  value is retrieved with the getter "value".
 *
 * @param metricKey: name of the metric
 * @param canMonitor: send to PMET or not, true by default
 */
export const timerStopwatchMetric = (metricKey: string, canMonitor = true) => {
  const metricObj = new KatalMetrics.Metric.TimerStopwatch(metricKey);
  return getMonitoredMetricObject(canMonitor, metricObj);
};

/**
 * Katal Timed Attempt Metrics Helper Function
 *  This metric pairs a timer and a failure counter to record the time and
 *  status of an attempt to do something.
 *
 * @param metricKey: name of the metric
 * @param canMonitor: send to PMET or not, true by default
 */
export const timedAttemptMetric = (metricKey: string, canMonitor = true) => {
  const metricObj = new KatalMetrics.Metric.TimedAttempt(metricKey);
  return getMonitoredMetricObject(canMonitor, metricObj);
};

/**
 * Katal String Metrics Helper Function
 * @param metricKey: name of the metric
 * @param value: value of the metric
 * @param canMonitor: send to PMET or not, true by default
 */
export const stringMetric = (
  metricKey: string,
  value: string,
  canMonitor = true
) => {
  const metricObj = new KatalMetrics.Metric.String(metricKey, value);
  return getMonitoredMetricObject(canMonitor, metricObj);
};
