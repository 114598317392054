import {
  FilteringProperty,
  Token,
} from '@amzn/awsui-components-react/polaris/property-filter/interfaces';

export enum FilterField {
  I18N_FAMILY = 'string-field-i18n-family',
  OWNER = 'string-field-owner',
  GROUP = 'string-field-package',
  CLIENT_NAME = 'string-field-client-name',
  STATUS = 'column-status',
  SOURCE_TEXT = 'column-source-text',
  PSMS_ID = 'string-field-psms-id',
  STRING_ID = 'column-id',
  TARGET_LOCALE = 'column-locale',
  TRANSLATION = 'column-translation',
  DEFAULT_SEARCH = SOURCE_TEXT,
}

export const EQUALS_OPERATOR = '=';
export const LIKE_OPERATOR = ':';
export const EMPTY_TOKEN: Token = {
  operator: LIKE_OPERATOR,
  value: [],
};

const stringListSearchFields: FilterField[] = [
  FilterField.SOURCE_TEXT,
  FilterField.PSMS_ID,
  FilterField.STRING_ID,
];

export const stringListSearchProperties: FilteringProperty[] =
  stringListSearchFields.map(
    (field: FilterField) =>
      ({
        groupValuesLabel: field,
        key: field,
        propertyLabel: field,
        defaultOperator:
          field === FilterField.PSMS_ID ? EQUALS_OPERATOR : LIKE_OPERATOR,
      } as FilteringProperty)
  );

export const targetTextSearchProperties: FilteringProperty[] = [
  {
    groupValuesLabel: FilterField.TARGET_LOCALE,
    key: FilterField.TARGET_LOCALE,
    propertyLabel: FilterField.TARGET_LOCALE,
  } as FilteringProperty,
  {
    groupValuesLabel: FilterField.TRANSLATION,
    key: FilterField.TRANSLATION,
    propertyLabel: FilterField.TRANSLATION,
    defaultOperator: LIKE_OPERATOR,
  } as FilteringProperty,
];
