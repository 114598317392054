import mocker from 'mocker-data-generator';
import xliffPhrase from './types/xliffPhrase';
import { PhrasesDocument } from 'src/generated/graphql';
import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';

const phrase = {
  id: {
    faker: 'datatype.number',
  },
  name: {
    faker: 'lorem.word',
  },
  sourceText: {
    faker: 'lorem.text',
  },
  format: {
    values: [null, 'xliff'],
  },
  sourceLocale: {
    faker: 'random.locale',
  },
  owner: {
    faker: 'company.companyName',
  },
  i18nFamily: {
    values: [
      'retail',
      'HRServices',
      'PrimeVideoWebPlayer',
      'TwitchPrimeStandard',
    ],
  },
  localizedPackage: {
    faker: 'company.catchPhrase',
  },
  packageTypes: {
    values: [
      'packageType-0',
      'packageType-1',
      'packageType-2',
      'packageType-3',
      'packageType-4',
    ],
  },
  localizedPackageTsAceName: {
    values: [null, 'MockTsAceName'],
  },
  translationsLastUpdated: {
    faker: 'datatype.number',
  },
  status: {
    values: ['COMPLETED', 'IN_PROGRESS', 'STATUS_UNKNOWN', 'FAILED'],
  },
};

const pageInfo = {
  offset: {
    static: 1,
  },
  limit: {
    static: 20,
  },
  count: {
    static: 20,
  },
  totalCount: {
    faker: 'datatype.number({"min": 20, "max": 100})',
  },
};

const phrases = {
  pageInfo: {
    hasOne: 'fakedPageInfo',
  },
  phraseList: {
    hasMany: 'fakedPhrase',
    amount: 20,
    unique: true,
  },
};

export const phrasesMock = (async () => {
  const { fakedPhrases } = await mocker()
    .seed('fakedPhrase', [xliffPhrase])
    .schema('fakedPageInfo', pageInfo, 1)
    .schema('fakedPhrase', phrase, 19)
    .schema('fakedPhrases', phrases, 1)
    .build();

  return {
    request: {
      query: PhrasesDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        phrases: fakedPhrases[0],
      },
    },
  };
})();
