import React from 'react';
import { includes, map } from 'lodash';
import { KatIcon, KatOption } from '@amzn/katal-react';
import style from './TypeaheadDropdown.module.scss';
import { DropdownState, Option } from './TypeaheadDropdown';
import { onInfinityScroll } from '../TypeaheadList/InfinityScroll';
import IsLoadingItem from '../TypeaheadList/IsLoadingItem';

type Props = {
  dropdownState: DropdownState;
  focusedIndex?: number;
  onSelectOption: (option: Option) => void;
  onScrollDown?: () => void;
  isLoading?: boolean;
};

const Options = (props: Props) => {
  return (
    <div className={style.selectOptions}>
      <div
        className={style.optionInnerContainer}
        onScroll={(e) => onInfinityScroll(e, props.onScrollDown)}
      >
        {props.dropdownState.filtered.map((option, index) => (
          <KatOption
            key={option.value}
            value={option.value}
            className={props.focusedIndex === index ? style.focused : ''}
            onClick={() => props.onSelectOption(option)}
          >
            <div className={style.optionContentWrapper}>
              <span>{option.name}</span>
              {includes(
                map(props.dropdownState.selected, 'value'),
                option.value
              ) && (
                <KatIcon
                  className={style.selectedMark}
                  size="small"
                  name="check_circle"
                />
              )}
            </div>
          </KatOption>
        ))}
        <IsLoadingItem isLoading={!!props.isLoading} />
      </div>
    </div>
  );
};

export default Options;
