import React, { useEffect, useState } from 'react';
import Header from 'src/components/Header/Header';
import './App.scss';
import Routes from 'src/components/Routes';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import useFederatedAuth, {
  AuthenticationState,
} from 'src/components/auth/FederatedAuth';
import { AuthContext } from './auth/AuthContext';
import { SuperuserContext } from './auth/SuperuserContext';
import {
  KatAlert,
  KatBox,
  KatIcon,
  KatStatusindicator,
} from '@amzn/katal-react';
import Footer from 'src/components/Footer/Footer';
import useSuperuser from 'src/components/auth/Superuser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isRTL } from 'src/i18n/locales';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import { getAllowedBetaFeature } from 'src/components/FeatureHelper';
import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from '@amzn/katal-metrics';

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { authState, userId } = useFederatedAuth();
  const { isSuperuser, isSuperUserLoading } = useSuperuser({ authState });
  const [isSudoMode, setIsSudoMode] = useState(false);

  useEffect(() => {
    const initializationMetricsPublisher =
      initialMetricsPublisher.newChildActionPublisherForInitialization();
    const initializationMetric =
      new KatalMetrics.Metric.Initialization().withMonitor();
    initializationMetric.setSuccess();
    initializationMetricsPublisher.publish(initializationMetric);
  }, []);

  useEffect(() => {
    const htmlDOMElement = document.querySelector('html');
    if (isRTL(i18n.language)) {
      // Setting this is required for katal components to load css accordingly
      // This is enough to enable RTL on the site
      htmlDOMElement?.setAttribute('dir', 'rtl');
    } else {
      // Since ltr is the default direction, remove it. Adding dir=ltr causes some weird
      // styling changes to the site that are unnecessary
      htmlDOMElement?.removeAttribute('dir');
    }
  }, [i18n.language]);

  /* JSX */
  const getLuiBody = () => {
    switch (authState) {
      case AuthenticationState.LOGGING_IN:
        return (
          <KatBox variant="mojito">
            <KatStatusindicator
              variant="loading"
              label={t('auth-status-logging-in')}
            />
          </KatBox>
        );
      case AuthenticationState.LOGGED_IN:
        return <Routes />;
      default:
        return (
          <KatAlert variant="danger" persistent={true}>
            <h5 slot="header">{t('auth-status-failed')}</h5>
            <p>{t('auth-status-failed-detail')}</p>
          </KatAlert>
        );
    }
  };

  return (
    <AuthContext.Provider value={{ authState, userId }}>
      <SuperuserContext.Provider
        value={{ isSuperuser, isSudoMode, setIsSudoMode, isSuperUserLoading }}
      >
        <FeatureToggles features={getAllowedBetaFeature(userId)}>
          <section id="lui-container">
            <ToastContainer
              containerId="lui-toaster-container"
              position="top-left"
              closeButton={<KatIcon name="close" size="small" />}
              rtl={isRTL(i18n.language)}
              draggable={false}
            />
            <Header id="lui-header" stage="{{Stage}}" />
            <section id="lui-body">{getLuiBody()}</section>
            <Footer id="lui-footer" />
          </section>
        </FeatureToggles>
      </SuperuserContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
