import React, { useCallback, useContext, useEffect, useState } from 'react';
import { KatDataTable, KatSpinner } from '@amzn/katal-react';
import ContextBox from 'src/components/StringDetail/ContextBox';
import MetadataBox from 'src/components/StringDetail/MetadataBox';
import styles from './StringDetailView.module.scss';
import Breadcrumb from 'src/components/common/ui/Breadcrumb';
import {
  Localization,
  LocalizationsUpdateMutation,
  Phrase,
  PhraseLocalizationsInput,
  TaskStatus,
  useLocalizationsUpdateMutation,
  usePhraseLocalizationsQuery,
  usePhraseQuery,
  usePhraseVariantsQuery,
  Variant,
} from 'src/generated/graphql';
import { useParams } from 'react-router-dom';
import Table from 'src/components/StringDetail/Table';
import ErrorHandler from 'src/components/common/ui/ErrorHandler/ErrorHandler';
import { find, isError, chain, uniqueId } from 'lodash';
import Banner from 'src/components/StringDetail/Banner';
import rootMetricPublisher, { METRIC, timerStopwatchMetric } from 'src/metrics';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import TranslationProgress from 'src/components/StringDetail/TranslationProgress/TranslationProgress';
import { SuperuserContext } from 'src/components/auth/SuperuserContext';
import EditPane from 'src/components/StringDetail/EditPane/EditPane';
import { toast } from 'react-toastify';
import LUIToaster, {
  LUIToasterMessageTuple,
} from 'src/components/common/ui/LUIToaster/LUIToaster';
import { unwrapGqlError } from 'src/components/common/ui/ErrorHandler/ErrorHandlerHelpers';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import { useDocumentTitle } from 'src/components/common/util/StringListHelpers';
import { ErrorResponse } from '@apollo/client/link/error';

const metricPublisher: KatalMetricsPublisher =
  rootMetricPublisher.newChildActionPublisherForMethod('StringDetail');

export type TranslationEditHandler = (
  localizationIndex: number,
  rowData?: Localization
) => void;

const validVariantTypes = ['DEFAULT', 'i18nDomain'];
const taskStatusToMessageMap = {
  [TaskStatus.Succeeded]: 'mutation-task-status-succeeded',
  [TaskStatus.RejectedNoChange]: 'mutation-task-status-succeeded', //Swallow as succeed
  [TaskStatus.RejectedUnauthorized]:
    'mutation-task-status-rejected-unauthorized',
  [TaskStatus.RejectedOutstanding]: 'mutation-task-status-rejected-outstanding',
  [TaskStatus.RejectedIncomplete]: 'mutation-task-status-rejected-incomplete',
  [TaskStatus.FailedLui]: 'mutation-task-status-failed-lui',
  [TaskStatus.FailedPanther]: 'mutation-task-status-failed-panther',
  [TaskStatus.FailedUnknown]: 'mutation-task-status-failed-unknown',
};
const taskFailureTypes = Object.keys(taskStatusToMessageMap).slice(2) as (
  | TaskStatus
  | undefined
)[];

const defaultVariant = {
  id: 0,
  category: 'DEFAULT',
  name: 'Default',
} as Variant;

const composeVariantsData = (variantsData: Variant[]) => {
  // This is a temp fix as backend doesn't allow filter variants by type as of Nov.18th 2020
  // It should be refactored to use gql query param to fetch i18nDomain type variants once backend is fixed
  const variants = variantsData.filter((variant) =>
    validVariantTypes.includes(variant.category)
  );
  //Variants endpoint doesn't return default variant, we have to add it manually.
  if (!variants.find((variant) => variant.id === 0)) {
    variants.unshift(defaultVariant);
  }
  return variants;
};

const StringDetailView: React.FC = () => {
  /* Metrics */
  const initLatencyMetric = timerStopwatchMetric(METRIC.LOADING_LATENCY);
  const timeSpentMetric = timerStopwatchMetric(METRIC.TIME_SPENT);

  /* React Hooks */
  const reactParams = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { isSudoMode } = useContext(SuperuserContext);
  const [variant, setVariant] = useState<Variant | undefined>(defaultVariant);
  const [localizations, setLocalizations] = useState<Localization[]>([]);
  const [showSidePane, setShowSidePane] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedLocalizations, setSelectedLocalizations] = useState<
    Localization[]
  >([]);

  const params: PhraseLocalizationsInput = {
    id: Number(reactParams.id),
    variantId: variant?.id,
    sudo: isSudoMode,
    version: undefined,
  };

  /* Gql Hooks*/
  const {
    data: phraseData,
    loading: phraseLoading,
    error: phraseError,
    refetch: refetchPhrase,
  } = usePhraseQuery({
    variables: { params },
    errorPolicy: 'all',
  });

  const {
    data: variantsData,
    loading: variantsLoading,
    error: variantError,
    refetch: refetchVariants,
  } = usePhraseVariantsQuery({
    variables: { params },
    errorPolicy: 'all',
  });

  const {
    data: localizationsData,
    loading: localizationsLoading,
    error: localizationsError,
    refetch: refetchLocalizations,
  } = usePhraseLocalizationsQuery({
    variables: { params },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  const [updateLocalization, { loading: localizationsUpdateLoading }] =
    useLocalizationsUpdateMutation({
      onCompleted: async () => {
        await Promise.all([
          refetchPhrase(),
          refetchLocalizations(),
          refetchVariants(),
        ]);
        setSelectedRows([]);
        setSelectedLocalizations([]);
      },
    });

  useEffect(() => {
    setLocalizations(
      localizationsData?.phrase?.localizations as Localization[]
    );
  }, [localizationsData]);

  useEffect(() => {
    metricPublisher.publish(initLatencyMetric);
    return () => metricPublisher.publish(timeSpentMetric);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDocumentTitle(phraseData?.phrase.name || 'LUI');

  /* Handlers */
  const rowSelectionChangeHandler = useCallback((e: CustomEvent<any>) => {
    const newSelection: number[] = e.detail.selectedRows.map(
      (row: KatDataTable.RowStruct) => Number(row.index)
    );
    setSelectedRows(newSelection);
  }, []);

  const toggleSidePane = (state?: boolean) => {
    if (state != null) {
      setShowSidePane(state);
    } else {
      setShowSidePane(!showSidePane);
    }
  };

  const editTranslation: TranslationEditHandler = useCallback(
    (rowIndex, rowData) => {
      const selectedIndices = chain(selectedRows)
        .concat(rowIndex)
        .uniq()
        .value()
        .sort((a, b) => a - b);
      const selectedTranslations = selectedIndices.map(
        (idx) => localizations[idx]
      );
      setSelectedLocalizations(selectedTranslations);

      toggleSidePane(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, localizations]
  );

  const localizationsUpdateResponseHandler = (
    data: LocalizationsUpdateMutation | null | undefined,
    metricPrefix: string
  ) => {
    const failedTasks = [] as LUIToasterMessageTuple[];
    const responses = data?.localizationsUpdate || [];
    responses.map((item, index) => {
      if (taskFailureTypes.includes(item?.status)) {
        failedTasks.push({
          id: item?.localization.locale || index,
          label: item?.localization.locale || 'Unknown',
          detail: t(
            taskStatusToMessageMap[item?.status || TaskStatus.FailedUnknown]
          ),
        });
      }
    });
    const failureToasterMsg = `${failedTasks.length}/${responses.length} ${t(
      'localization-update-failure-message',
      {
        count: failedTasks.length,
      }
    )}`;
    const successToasterMsg = `${responses.length - failedTasks.length}/${
      responses.length
    } ${t('localization-update-success-message', {
      count: responses.length,
    })}`;
    if (failedTasks.length === 0) {
      toast.success(
        <LUIToaster
          message={successToasterMsg}
          id="lui-localization-update-toaster"
        />,
        { toastId: uniqueId('success-') }
      );
    } else if (failedTasks.length === responses.length) {
      toast.error(
        <LUIToaster
          message={failureToasterMsg}
          lists={failedTasks}
          id="lui-localization-update-toaster"
        />,
        { autoClose: false, toastId: uniqueId('failure-') }
      );
      metricPublisher.publishCounterMonitor(
        metricPrefix + 'complete_failure',
        1
      );
    } else {
      //Partial success
      toast.warning(
        <LUIToaster
          message={failureToasterMsg}
          lists={failedTasks}
          id="lui-localization-update-toaster"
        />,
        { autoClose: false, toastId: uniqueId('partial-') }
      );
      metricPublisher.publishCounterMonitor(
        metricPrefix + 'partial_failure',
        1
      );
    }
  };

  const phrase = phraseData?.phrase as Phrase;
  const variants = composeVariantsData(
    (variantsData?.phrase.variants || []) as Variant[]
  );

  const handleLocalizationsUpdate = async (
    localizationUpdateBody: string,
    isMF = false
  ) => {
    toggleSidePane(false);
    params.version = phrase.version;
    const mutationInput = { params, body: localizationUpdateBody };
    const metricPrefix = isMF ? 'MF_' : '';
    try {
      const { data } = await updateLocalization({
        variables: mutationInput,
      });
      localizationsUpdateResponseHandler(data, metricPrefix);
    } catch (e) {
      const { errorType, userFriendlyMessage } = unwrapGqlError(
        e as ApolloError | ErrorResponse
      );
      toast.error(userFriendlyMessage, { autoClose: false });
      metricPublisher.publishCounterMonitor(metricPrefix + METRIC.ERROR, 1);
      metricPublisher.publishCounterMonitor(
        metricPrefix + METRIC.ERROR_CODE + errorType,
        1
      );
    }
  };

  /* JSX */
  if (phraseLoading || variantsLoading) {
    return <KatSpinner />;
  }

  if (phraseError) {
    return (
      <ErrorHandler
        publisher={metricPublisher}
        header={t('error-flashbar-header-message-string-detail-page')}
        error={find([phraseError, variantError], isError) as ApolloError}
      />
    );
  }

  return (
    <section>
      <div className={styles.detailHeaderRow}>
        <Breadcrumb items={[{ to: '', label: phrase.name || '' }]} />
      </div>
      <div>
        {variantsLoading ? (
          <KatSpinner size="small" />
        ) : (
          <Banner
            phrase={phrase}
            variants={variants || []}
            setVariant={setVariant}
          />
        )}
      </div>
      <div className={styles.detailDataRow}>
        <ContextBox phrase={phrase} />
        <MetadataBox phrase={phrase} />
      </div>
      <div>
        {localizations && localizations.length && (
          <TranslationProgress localizations={localizations} />
        )}
      </div>
      <div>
        <Table
          phrase={phrase}
          localizations={localizations}
          error={localizationsError as ApolloError}
          loading={localizationsLoading}
          edit={editTranslation}
          onSelectionChange={rowSelectionChangeHandler}
        />
      </div>
      <EditPane
        id="lui-string-detail-edit-translation-pane"
        source={phrase.sourceText}
        sourceLocale={phrase.sourceLocale}
        isLoading={localizationsUpdateLoading}
        show={showSidePane}
        setShow={toggleSidePane}
        onSubmit={handleLocalizationsUpdate}
        data={selectedLocalizations}
        format={phrase.format}
      />
    </section>
  );
};

export default StringDetailView;
