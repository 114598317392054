import React from 'react';
import { KatBreadcrumb, KatBreadcrumbItem } from '@amzn/katal-react';
import { useHistory } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import styles from './Breadcrumb.module.scss';

export interface BreadcrumbItem {
  to: string;
  label: string;
}

type Props = {
  items: BreadcrumbItem[];
};

const Breadcrumb: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onClick = (event: React.MouseEvent, to: string) => {
    if (!to.length) {
      return;
    }
    event.preventDefault();
    history.push(to);
  };

  const state = history?.location?.state as { from: string } | undefined;
  const items: BreadcrumbItem[] = [
    {
      to: state?.from ?? '/',
      label: state?.from.startsWith('/translations')
        ? t('target-search-page-title')
        : t('string-list-page-title'),
    },
    ...props.items,
  ];

  return (
    <KatBreadcrumb>
      {items.map((item) => {
        return (
          <KatBreadcrumbItem
            key={uniqueId()}
            className={styles.katBreadcrumbItem}
            href={item.to}
            label={item.label}
            onClick={(e) => onClick(e, item.to)}
          />
        );
      })}
    </KatBreadcrumb>
  );
};

export default Breadcrumb;
