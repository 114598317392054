/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PhraseInput, TranslationInput } from 'src/generated/graphql';
import {
  idsToJsonString,
  removeFalsyAttrs,
  stringIdsToJsonString,
} from './JSToolbelt';
import { parseInt } from 'lodash';
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGE_SIZE,
} from 'src/components/StringList/StringList';
import { Criteria } from 'src/components/Preferences/FilterModal/FilterModal';

export enum UrlParamKeys {
  caseSensitive = 'caseSensitive',
  exactMatch = 'exactMatch',
  i18nFamilies = 'i18nFamilies',
  limit = 'limit',
  locales = 'locales',
  localizedPackages = 'localizedPackages',
  offset = 'offset',
  owners = 'owners',
  packageTypes = 'packageTypes',
  psmsIds = 'psmsIds',
  sortDir1 = 'sortDir1',
  sortKey1 = 'sortKey1',
  sourceTexts = 'sourceTexts',
  statuses = 'statuses',
  stringIds = 'stringIds',
  sudo = 'sudo',
  translations = 'translations',
  user = 'user',
}

export const getCriteriaFromUrlParams = (
  urlParams: URLSearchParams,
  key: string,
  criteria: Criteria[] | undefined
): Criteria[] => {
  return criteria
    ? (urlParams
        .getAll(key)
        .map(Number)
        .filter((id: number) => !isNaN(id))
        .map((id: number) => criteria.find((item: Criteria) => item.id === id))
        .filter((item) => item) as Criteria[])
    : [];
};

export const getPhraseInputFromUrlSearchParams = (params: URLSearchParams) => {
  const caseSensitive = !!params.get(UrlParamKeys.caseSensitive);
  const exactMatch = !!params.get(UrlParamKeys.exactMatch);

  const i18nFamilies = idsToJsonString(
    params.getAll(UrlParamKeys.i18nFamilies)
  );
  const owners = idsToJsonString(params.getAll(UrlParamKeys.owners));
  const localizedPackages = idsToJsonString(
    params.getAll(UrlParamKeys.localizedPackages)
  );
  const packageTypes = stringIdsToJsonString(
    params.getAll(UrlParamKeys.packageTypes)
  );
  const statuses = idsToJsonString(params.getAll(UrlParamKeys.statuses));

  const sourceTexts = stringIdsToJsonString(
    params.getAll(UrlParamKeys.sourceTexts)
  );
  const psmsIds = stringIdsToJsonString(params.getAll(UrlParamKeys.psmsIds));
  const stringIds = stringIdsToJsonString(
    params.getAll(UrlParamKeys.stringIds)
  );

  const limit = params.has(UrlParamKeys.limit)
    ? parseInt(params.get(UrlParamKeys.limit)!, 10)
    : DEFAULT_PAGE_SIZE;
  const offset = params.has(UrlParamKeys.offset)
    ? parseInt(params.get(UrlParamKeys.offset)!, 10)
    : DEFAULT_OFFSET;

  const sudo = !!params.get(UrlParamKeys.sudo);
  const user = params.get(UrlParamKeys.user);

  const sortDir1 = params.get(UrlParamKeys.sortDir1);
  const sortKey1 = params.get(UrlParamKeys.sortKey1);

  const phraseInput: PhraseInput = {
    caseSensitive,
    exactMatch,
    i18nFamilies,
    limit,
    localizedPackages,
    offset,
    owners,
    packageTypes,
    psmsIds,
    sortDir1,
    sortKey1,
    sourceTexts,
    statuses,
    stringIds,
    sudo,
    user,
  };

  return removeFalsyAttrs(phraseInput);
};

export const getTranslationInputFromUrlSearchParams = (
  params: URLSearchParams
) => {
  //const caseSensitive = !!params.get(UrlKeys.caseSensitive);
  //const exactMatch = !!params.get(UrlKeys.exactMatch);

  const i18nFamilies = idsToJsonString(
    params.getAll(UrlParamKeys.i18nFamilies)
  );
  const owners = idsToJsonString(params.getAll(UrlParamKeys.owners));
  const localizedPackages = idsToJsonString(
    params.getAll(UrlParamKeys.localizedPackages)
  );
  const packageTypes = stringIdsToJsonString(
    params.getAll(UrlParamKeys.packageTypes)
  );

  const locales = stringIdsToJsonString(params.getAll(UrlParamKeys.locales));
  // Limit to one translation
  const translations =
    params.get(UrlParamKeys.translations) &&
    JSON.stringify([params.get(UrlParamKeys.translations)]);

  const limit = params.has(UrlParamKeys.limit)
    ? parseInt(params.get(UrlParamKeys.limit)!, 10)
    : DEFAULT_PAGE_SIZE;
  const offset = params.has(UrlParamKeys.offset)
    ? parseInt(params.get(UrlParamKeys.offset)!, 10)
    : DEFAULT_OFFSET;

  const sudo = !!params.get(UrlParamKeys.sudo);
  const user = params.get(UrlParamKeys.user);

  const translationInput: TranslationInput = {
    //caseSensitive,
    //exactMatch,
    i18nFamilies,
    limit,
    locales,
    localizedPackages,
    offset,
    owners,
    packageTypes,
    sudo,
    translations,
    user,
  };

  return removeFalsyAttrs(translationInput);
};

export const getUrlSearchParam = (key: string, history: any): string | null => {
  const urlParams = new URLSearchParams(history?.location.search);
  return urlParams.get(key);
};

export const setUrlFromPhraseInput = (
  queryInput: PhraseInput,
  history: any
): void => {
  const urlParams = new URLSearchParams();
  queryInput.caseSensitive && urlParams.set(UrlParamKeys.caseSensitive, 'true');
  queryInput.exactMatch && urlParams.set(UrlParamKeys.exactMatch, 'true');

  if (queryInput.i18nFamilies) {
    const i18nFamilies: string[] = JSON.parse(queryInput.i18nFamilies);
    i18nFamilies.forEach((family: string) =>
      urlParams.append(UrlParamKeys.i18nFamilies, family)
    );
  }
  if (queryInput.owners) {
    const owners: string[] = JSON.parse(queryInput.owners);
    owners.forEach((owner: string) =>
      urlParams.append(UrlParamKeys.owners, owner)
    );
  }
  if (queryInput.localizedPackages) {
    const localizedPackages: string[] = JSON.parse(
      queryInput.localizedPackages
    );
    localizedPackages.forEach((p: string) =>
      urlParams.append(UrlParamKeys.localizedPackages, p)
    );
  }
  if (queryInput.packageTypes) {
    const packageTypes: string[] = JSON.parse(queryInput.packageTypes);
    packageTypes.forEach((type: string) =>
      urlParams.append(UrlParamKeys.packageTypes, type)
    );
  }
  if (queryInput.statuses) {
    const statuses: string[] = JSON.parse(queryInput.statuses);
    statuses.forEach((status: string) =>
      urlParams.append(UrlParamKeys.statuses, status)
    );
  }

  if (queryInput.sourceTexts) {
    const sourceTexts: string[] = JSON.parse(queryInput.sourceTexts);
    sourceTexts.forEach((text: string) =>
      urlParams.append(UrlParamKeys.sourceTexts, text)
    );
  }
  if (queryInput.psmsIds) {
    const psmsIds: string[] = JSON.parse(queryInput.psmsIds);
    psmsIds.forEach((id: string) => urlParams.append(UrlParamKeys.psmsIds, id));
  }
  if (queryInput.stringIds) {
    const stringIds: string[] = JSON.parse(queryInput.stringIds);
    stringIds.forEach((id: string) =>
      urlParams.append(UrlParamKeys.stringIds, id)
    );
  }

  queryInput.limit &&
    urlParams.set(UrlParamKeys.limit, String(queryInput.limit));
  queryInput.offset &&
    urlParams.set(UrlParamKeys.offset, String(queryInput.offset));

  queryInput.sudo && urlParams.set(UrlParamKeys.sudo, 'true');
  queryInput.user && urlParams.set(UrlParamKeys.user, queryInput.user);

  queryInput.sortDir1 &&
    urlParams.set(UrlParamKeys.sortDir1, queryInput.sortDir1);
  queryInput.sortKey1 &&
    urlParams.set(UrlParamKeys.sortKey1, queryInput.sortKey1);

  urlParams.sort();
  history.replace({ search: urlParams.toString() });
};

export const setUrlFromTranslationInput = (
  queryInput: TranslationInput,
  history: any
): void => {
  const urlParams = new URLSearchParams();
  queryInput.caseSensitive && urlParams.set(UrlParamKeys.caseSensitive, 'true');
  queryInput.exactMatch && urlParams.set(UrlParamKeys.exactMatch, 'true');

  if (queryInput.i18nFamilies) {
    const i18nFamilies: string[] = JSON.parse(queryInput.i18nFamilies);
    i18nFamilies.forEach((family: string) =>
      urlParams.append(UrlParamKeys.i18nFamilies, family)
    );
  }
  if (queryInput.owners) {
    const owners: string[] = JSON.parse(queryInput.owners);
    owners.forEach((owner: string) =>
      urlParams.append(UrlParamKeys.owners, owner)
    );
  }
  if (queryInput.localizedPackages) {
    const localizedPackages: string[] = JSON.parse(
      queryInput.localizedPackages
    );
    localizedPackages.forEach((p: string) =>
      urlParams.append(UrlParamKeys.localizedPackages, p)
    );
  }
  if (queryInput.packageTypes) {
    const packageTypes: string[] = JSON.parse(queryInput.packageTypes);
    packageTypes.forEach((type: string) =>
      urlParams.append(UrlParamKeys.packageTypes, type)
    );
  }

  if (queryInput.locales) {
    const locales: string[] = JSON.parse(queryInput.locales);
    locales.forEach((locale: string) =>
      urlParams.append(UrlParamKeys.locales, locale)
    );
  }
  if (queryInput.translations) {
    const translations: string[] = JSON.parse(queryInput.translations);
    translations.forEach((t: string) =>
      urlParams.append(UrlParamKeys.translations, t)
    );
  }

  queryInput.limit &&
    urlParams.set(UrlParamKeys.limit, String(queryInput.limit));
  queryInput.offset &&
    urlParams.set(UrlParamKeys.offset, String(queryInput.offset));

  queryInput.sudo && urlParams.set(UrlParamKeys.sudo, 'true');
  queryInput.user && urlParams.set(UrlParamKeys.user, queryInput.user);

  urlParams.sort();
  history.replace({ search: urlParams.toString() });
};
