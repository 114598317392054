import React, { useEffect, useState } from 'react';
import { KatLink, KatIcon, KatButton } from '@amzn/katal-react';
import ReactSlidingPane from 'react-sliding-pane';
import { isRTL } from 'src/i18n/locales';
import {
  getPreview,
  Preview,
  UnitKey,
  XLIFF_FORMAT,
} from 'src/components/common/util/XLIFFHelpers';
import { unicodeWhitespaces } from 'src/components/common/util/FormatHelpers';
import styles from './TextExpander.module.scss';
import { localizationContextProvider, i18n } from 'src/i18n';

type Props = {
  name: string;
  text: string;
  format?: string | null;
  locale: string;
  type: UnitKey;
};

const TextExpander: React.FC<Props> = ({
  name,
  text,
  format,
  locale,
  type,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [onelinePreview, setOnelinePreview] = useState('');
  const [previews, setPreviews] = useState<Preview[]>([]);
  const [isMF, setIsMF] = useState(false);
  //const { i18n, t } = useTranslation();
  const globalDir = isRTL(localizationContextProvider().getLocale())
    ? 'left'
    : 'right';
  const localDir = isRTL(locale) ? 'rtl' : 'ltr';

  useEffect(() => {
    async function someCalculation() {
      const { onelinePreview, isMF, previews } = await getPreview(
        text,
        format,
        type
      );
      const encodedOnelinePreview = unicodeWhitespaces(onelinePreview);
      setOnelinePreview(encodedOnelinePreview);
      setIsMF(isMF);
      setPreviews(previews);
    }
    someCalculation();
  }, [text, format, type]);

  if (format !== XLIFF_FORMAT) {
    return (
      <div className={styles.text} dir="auto">
        {text}
      </div>
    );
  }

  return (
    <div className={styles.textExpanderContainer}>
      <div className={styles.formatText} dir="auto">
        {onelinePreview}
      </div>

      {isMF && (
        <>
          <div
            className={styles.more}
            onClick={() => setExpanded(true)}
            dir="auto"
          >
            <KatLink>
              {i18n.defaultBundle.getMessage('text-expander-more-label')}
            </KatLink>
          </div>

          <ReactSlidingPane
            title={name}
            from={globalDir}
            isOpen={expanded}
            onRequestClose={() => setExpanded(false)}
            width="40%"
            closeIcon={
              <KatIcon name="close" size="large" className="pane-close-icon" />
            }
            className="lui-pane"
          >
            {previews.map(({ metadataText, content }, index) => (
              <div key={index} className={styles.unitPreview} dir={localDir}>
                <h5>{metadataText}</h5>
                <span>{content}</span>
              </div>
            ))}
            <div style={{ float: globalDir }}>
              <KatButton
                label={i18n.defaultBundle.getMessage('text-expander-ok-label')}
                onClick={() => setExpanded(false)}
                className="submit-btn"
              />
            </div>
          </ReactSlidingPane>
        </>
      )}
    </div>
  );
};

export default TextExpander;
