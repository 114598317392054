import { GetLocalesDocument } from 'src/generated/graphql';
import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';

export const localesMock = (async () => {
  return {
    request: {
      query: GetLocalesDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        locales: [
          { locale: 'en-CA' },
          { locale: 'en-GB' },
          { locale: 'de-DE' },
          { locale: 'fr-FR' },
        ],
      },
    },
  };
})();
