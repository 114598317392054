import React from 'react';
import { KatDropdown } from '@amzn/katal-react';

import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import localeMock from './InputOptions/locales';

type Props = {
  onLocaleChange: (e: CustomEvent) => void;
};

const LocaleSelect: React.FC<Props> = React.memo((props: Props) => {
  const { t } = useTranslation();

  return (
    <KatDropdown
      options={localeMock}
      label={t('choose-locale-dropdown-label')}
      placeholder={t('placeholder-text-optional')}
      tooltip-text={t('choose-locale-tooltip')}
      onChange={props.onLocaleChange}
    ></KatDropdown>
  );
});

LocaleSelect.displayName = 'LocaleSelect';

export default LocaleSelect;
