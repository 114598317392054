import { AllI18nFamiliesDocument } from 'src/generated/graphql';
import { MATCH_ANY_PARAMETERS } from 'wildcard-mock-link';

export const i18nFamiliesMock = (async () => {
  return {
    request: {
      query: AllI18nFamiliesDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        i18nFamilies: [
          { id: 0, name: 'Retail' },
          { id: 1, name: 'Twitch' },
          { id: 2, name: 'Prime Video' },
          { id: 3, name: 'HRServices' },
          { id: 4, name: 'Test Org 1' },
          { id: 5, name: 'Test Org 5' },
          { id: 6, name: 'Test Org 6' },
          { id: 7, name: 'Test Org 7' },
          { id: 8, name: 'Test Org 8' },
        ],
      },
    },
  };
})();
