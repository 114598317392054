import React, { useState } from 'react';
import { KatDropdownButton } from '@amzn/katal-react';
import {
  deleteFilterByName,
  getDefaultFilter,
  loadSavedFilters,
  saveDefaultFilter,
  saveFilter,
  updateCurrentFilter,
} from 'src/api/preferencesClient';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import { FilterCategory } from '../Preferences/FilterModal/FilterModal';
import NewFilterModal from '../Preferences/SavedFilterModal/NewFilterModal';
import SavedFilterModal from '../Preferences/SavedFilterModal/SavedFilterModal';

export type SavedFilter = {
  name: string;
  filter: FilterCategory;
};

type Props = {
  id: string;
  filter: FilterCategory;
  onFilterApply: (filter: FilterCategory) => void;
};

const Filter: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  /* React Hooks */
  const [showSavedFilterModal, setShowSavedFilterModal] = useState(false);
  const [showNewFilterModal, setShowNewFilterModal] = useState(false);
  const [savedFilters, setSavedFilters] = useState(loadSavedFilters());

  const reloadFiltersFromStorage = () => {
    const filters = loadSavedFilters();
    setSavedFilters([...filters]);
  };

  /* Callbacks */
  const onFilterDelete = (name: string) => {
    if (deleteFilterByName(name)) {
      reloadFiltersFromStorage();
    }
  };

  const onFilterReset = () => {
    props.onFilterApply(getDefaultFilter());
    updateCurrentFilter(0);
  };

  const onFilterSave = (name: string) => {
    const newFilter: SavedFilter = {
      name: name,
      filter: props.filter,
    };

    const savedIndex = saveFilter(newFilter);
    updateCurrentFilter(savedIndex);
    reloadFiltersFromStorage();
  };

  const onFilterSaveAsDefault = () => {
    saveDefaultFilter({ name: 'Default', filter: props.filter });
    updateCurrentFilter(0);
  };

  const dropdownOptions = [
    {
      id: `${props.id}-reset`,
      label: t('string-list-page-filter-apply'),
      action: 'onFilterReset',
    },
    {
      label: t('string-list-page-filter-edit'),
      action: 'onFilterEdit',
    },
    {
      label: t('string-list-page-filter-saveas'),
      action: 'onFilterSaveAs',
    },
    {
      label: t('string-list-page-filter-savedefault'),
      action: 'onFilterSaveAsDefault',
    },
  ];

  const dropdownActionHandler = (e: KatDropdownButton.ActionEvent) => {
    const action: string = e.detail.action;
    switch (action) {
      case 'onFilterReset':
        onFilterReset();
        break;
      case 'onFilterEdit':
        setShowSavedFilterModal(true);
        break;
      case 'onFilterSaveAs':
        setShowNewFilterModal(true);
        break;
      case 'onFilterSaveAsDefault':
        onFilterSaveAsDefault();
        break;
    }
  };

  return (
    <div id={props.id}>
      <KatDropdownButton
        id={props.id + '-dropdown'}
        options={dropdownOptions}
        onAction={(e: KatDropdownButton.ActionEvent) =>
          dropdownActionHandler(e)
        }
        responsive={true}
      />

      <SavedFilterModal
        id="lui-saved-filter-modal"
        display={showSavedFilterModal}
        currentFilter={props.filter}
        savedFilters={savedFilters}
        setFilter={props.onFilterApply}
        deleteFilter={onFilterDelete}
        onCancel={() => setShowSavedFilterModal(false)}
      />

      <NewFilterModal
        id="lui-new-filter-modal"
        display={showNewFilterModal}
        onSaveCurrentFilter={onFilterSave}
        onCancel={() => setShowNewFilterModal(false)}
      />
    </div>
  );
};

export default Filter;
