import mocker from 'mocker-data-generator';
import { LocalizationsUpdateDocument } from 'src/generated/graphql';
import {
  MATCH_ANY_PARAMETERS,
  WildcardMockedResponse,
} from 'wildcard-mock-link';
import localization from 'src/api/mocks/types/localization';
import taskStatus from 'src/api/mocks/types/taskStatus';
import { MockObject } from '../mocks';

const localizationsUpdate = {
  localization: {
    hasOne: 'fakedLocalization',
  },
  status: {
    hasOne: 'fakedTaskStatus',
  },
};

export const localizationsUpdateMock = (async (): Promise<
  MockObject | MockObject[]
> => {
  const { fakedLocalizationsUpdate } = await mocker()
    .schema('fakedLocalization', localization, 2)
    .schema('fakedTaskStatus', taskStatus, 5)
    .schema('fakedLocalizationsUpdate', localizationsUpdate, 2)
    .build();

  return {
    request: {
      query: LocalizationsUpdateDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    result: {
      data: {
        localizationsUpdate: fakedLocalizationsUpdate,
      },
    },
  };
})();

export const singleLocalizationUpdateMock =
  (async (): Promise<WildcardMockedResponse> => {
    const { fakedLocalizationsUpdate } = await mocker()
      .schema('fakedLocalization', localization, 1)
      .schema('fakedTaskStatus', taskStatus, 1)
      .schema('fakedLocalizationsUpdate', localizationsUpdate, 1)
      .build();

    return {
      request: {
        query: LocalizationsUpdateDocument,
        variables: MATCH_ANY_PARAMETERS,
      },
      result: {
        data: {
          localizationsUpdate: fakedLocalizationsUpdate,
        },
      },
    };
  })();

export const bulkLocalizationUpdateMock =
  (async (): Promise<WildcardMockedResponse> => {
    const { fakedLocalizationsUpdate } = await mocker()
      .schema('fakedLocalization', localization, 2)
      .schema('fakedTaskStatus', taskStatus, 2)
      .schema('fakedLocalizationsUpdate', localizationsUpdate, 2)
      .build();

    return {
      request: {
        query: LocalizationsUpdateDocument,
        variables: MATCH_ANY_PARAMETERS,
      },
      result: {
        data: {
          localizationsUpdate: fakedLocalizationsUpdate,
        },
      },
    };
  })();

export const localizationUpdateErrorMock = ((): WildcardMockedResponse => {
  return {
    request: {
      query: LocalizationsUpdateDocument,
      variables: MATCH_ANY_PARAMETERS,
    },
    error: new Error('Mock error'),
  };
})();
