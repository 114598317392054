import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MixedTags } from '@yaireo/tagify/dist/react.tagify';
import Tagify, { TagData, TagifySettings } from '@yaireo/tagify';
import './TagifiedTextarea.scss';
import {
  generateTags,
  isTagRemovable,
  removeTags,
  TaggedXLIFF,
  XLIFFTag,
} from 'src/components/common/util/XLIFFHelpers';
import { checkForAddedPlaceholders } from 'src/components/common/util/MessageFormatHelpers';
import { unescape } from 'lodash';
import { useTranslation } from 'src/i18next-arb-shim/useTranslation';
import { KatLabel } from '@amzn/katal-react';
import InputState from './UserInputValidationStatusEnum';

type Props = {
  id: string;
  input: string; //a <source> or <target> xliff string
  dir?: string; //rtl | ltr
  currentTranslation: string;
  onChange?: (newInput: string, inputState: InputState) => void;
};

const settings: TagifySettings = {
  dropdown: {
    enabled: 0,
    position: 'manual',
    maxItems: 100,
    mapValueTo: 'display',
    classname: 'tags-look',
  },
  tagTextProp: 'display',
  enforceWhitelist: false,
  backspace: false,
  trim: false,
  hooks: {
    beforeRemoveTag: (tags) => {
      return new Promise<void>((resolve, reject) => {
        isTagRemovable(tags[0].data.type) ? resolve() : reject();
      });
    },
  },
};

const TagifiedTextarea: React.FC<Props> = (props: Props) => {
  const taggedXliff: TaggedXLIFF = generateTags(props.input);
  const value: string = taggedXliff.output;
  const whitelist: XLIFFTag[] = taggedXliff.tags;

  /* React Hooks */
  const { t } = useTranslation();
  const [removedTagIds, setRemovedTagIds] = useState<string[]>([]);
  const [inputState, setInputState] = useState<InputState>(InputState.Valid);
  const tagifyRef: any = useRef(null);

  /* Handlers */
  const validateInput = (input: string) => {
    if (!input || input.trim().length === 0) {
      setInputState(InputState.Error);
      return InputState.Error;
    }

    if (
      removedTagIds.length > 0 ||
      checkForAddedPlaceholders(input, props.currentTranslation) > 0
    ) {
      setInputState(InputState.Warning);
      return InputState.Warning;
    }

    setInputState(InputState.Valid);
    return InputState.Valid;
  };
  useEffect(() => {
    props.onChange?.(props.input, validateInput(props.input));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removedTagIds, props.input]);

  const onChange = useCallback(
    (e: CustomEvent<Tagify.ChangeEventData<TagData>>) => {
      //Untagify input
      const inputVal = removeTags(e.detail.value);
      props.onChange?.(inputVal, validateInput(inputVal));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onRemove = useCallback((e) => {
    setRemovedTagIds((prevState) => {
      return [...prevState, e.detail.data.value];
    });
  }, []);

  const removedTags =
    tagifyRef.current?.whitelist.filter((tag: TagData) => {
      return removedTagIds.includes(tag.value);
    }) || [];

  const addTagback = (tag: TagData) => {
    tagifyRef.current.addMixTags([tag]);
    //remove it from list after adding it back to input box
    setRemovedTagIds(
      removedTagIds.filter((removedTagId) => removedTagId !== tag.value)
    );
  };

  /* JSX */
  const getErrorLabelProps = (input: string) => {
    const state: KatLabel.State = 'error';
    const emphasis = t('edit-pane-warning-input-emphasis');
    let text = t('edit-pane-empty-input-label');
    if (inputState !== InputState.Error) {
      if (checkForAddedPlaceholders(input, props.currentTranslation) < 0) {
        text = t('edit-pane-removed-protected-content-label');
      } else {
        text = t('edit-pane-added-placeholder-content-label');
      }
    }
    return { state, emphasis, text };
  };
  return (
    <div id={props.id}>
      <MixedTags
        tagifyRef={tagifyRef}
        whitelist={whitelist}
        settings={settings}
        value={value}
        onChange={onChange}
        onRemove={onRemove}
        className={
          props.dir?.toLowerCase() === 'rtl' ? 'tagifyRTL' : 'tagifyLTR'
        }
      />
      {removedTags.length > 0 && (
        <div className="removed-tags-container">
          <div id={props.id + '-removed-label'} className="removed-tags-label">
            <h5>{t('tagify-removed-tag-label')}</h5>
            <p>{t('tagify-removed-tag-instruction-label')}</p>
          </div>

          <div
            id={props.id + '-removed-tags'}
            className="tagify__dropdown__wrapper"
          >
            {removedTags.map((tag: TagData) => {
              return (
                <div
                  className="tagify__dropdown__item"
                  key={tag.value}
                  onClick={() => addTagback(tag)}
                  dir="ltr"
                >
                  {unescape(tag.display)}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {inputState !== InputState.Valid && (
        <KatLabel {...getErrorLabelProps(props.input)} />
      )}
    </div>
  );
};

export default TagifiedTextarea;
