import { KatSpinner } from '@amzn/katal-react';
import React from 'react';

type Props = {
  isLoading: boolean;
};

const IsLoadingItem: React.FC<Props> = (props: Props) => {
  if (props.isLoading) return <KatSpinner size={'small'} />;
  else return null;
};

export default IsLoadingItem;
