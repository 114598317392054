const localeMock = [
  {
    name: 'All',
    value: '000',
  },
  {
    name: 'en_US',
    value: '001',
  },
  {
    name: 'en_GB',
    value: '002',
  },
  {
    name: 'es_ES',
    value: '003',
  },
  {
    name: 'pt_PT',
    value: '004',
  },
];

export default localeMock;
